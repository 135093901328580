
import { GET_REPORTLIST_FAIL, GET_REPORTLIST_PAGE, GET_REPORTLIST_REQUSET, GET_REPORTLIST_SUCCESS } from "../constants/ReportConstant";
  
  const initialState = {
    arInvoice: [],
    loading: false,
    error: null,
    page: 1,
    perPage: 10,
  };
  
  export const reportDataProcessingListReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_REPORTLIST_REQUSET:
        return { ...state, loading: true, error: null };
      case GET_REPORTLIST_SUCCESS:
        return {
          ...state,
          dataListLoading: false,
          dataProcessing: action.payload.data,
          page: action.payload.page,
          perPage: action.payload.perPage,
        };
      case GET_REPORTLIST_FAIL:
        return { ...state, loading: false, error: action.payload };
      case GET_REPORTLIST_PAGE:
        return { ...state, page: action.payload };
      default:
        return state;
    }
  };