import {
  RECURRINGINVOICE_CREATE_REQUEST,
  RECURRINGINVOICE_CREATE_SUCCESS,
  RECURRINGINVOICE_CREATE_FAIL,
  RECURRINGINVOICE_CREATE_RESET,
  RECURRINGINVOICE_LIST_REQUEST,
  RECURRINGINVOICE_LIST_SUCCESS,
  RECURRINGINVOICE_LIST_FAIL,
  RECURRINGINVOICE_EDIT_DETAIL_REQUEST,
  RECURRINGINVOICE_EDIT_DETAIL_SUCCESS,
  RECURRINGINVOICE_EDIT_DETAIL_FAIL,
  RECURRINGINVOICE_EDIT_REQUEST,
  RECURRINGINVOICE_EDIT_SUCCESS,
  RECURRINGINVOICE_EDIT_FAIL,
  RECURRINGINVOICE_EDIT_RESET,
  RECURRINGINVOICE_GET_INVOICELIST_PAGE,
} from "../constants/RecurringInvoiceConstant";

const initialState = {
  recurringList: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const recurringInvoiceAddReducer = (state = {}, action) => {
  switch (action.type) {
    case RECURRINGINVOICE_CREATE_REQUEST:
      return { loading: true };

    case RECURRINGINVOICE_CREATE_SUCCESS:
      return { loading: false, success: true };

    case RECURRINGINVOICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case RECURRINGINVOICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const recurringInvoiceListReducer= (state = initialState, action) => {
  switch (action.type) {
    case RECURRINGINVOICE_LIST_REQUEST:
      return { ...state, loading: true, error: null };

    case RECURRINGINVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        recurringList: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case RECURRINGINVOICE_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
      case RECURRINGINVOICE_GET_INVOICELIST_PAGE:
        return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const recurringInvoiceEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case RECURRINGINVOICE_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case RECURRINGINVOICE_EDIT_DETAIL_SUCCESS:
      return { loading: false, recurringEditDetail: action.payload };
    case RECURRINGINVOICE_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const recurringInvoiceEditReducer = (state = {}, action) => {
  switch (action.type) {
    case RECURRINGINVOICE_EDIT_REQUEST:
      return { loading: true };

    case RECURRINGINVOICE_EDIT_SUCCESS:
      return { loading: false,success: true };

    case RECURRINGINVOICE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case RECURRINGINVOICE_EDIT_RESET:
      return {};
    default:
      return state;
  }
};
