import React from "react";
import { useDispatch } from "react-redux";
import {
  bankCreation,
} from "../redux/actions/BankModuleAction";
import PropTypes from 'prop-types';
import BankAddEditModal from "./bankAddEditModal";
import { currencyTypes } from "./constant";

const AddBank = ({
  bankData,
  modalRef,
  show,
  handleSubmit,
  control,
  handleChange,
  errors,
  addError,
  selectRefs,
  handleSelectChange,
  handleModalClose,
  handleCheckBoxChange,
  handleOptionChange,
  loading,
}) => {
  const dispatch = useDispatch();

  const addBankSubmit = (data) => {
    const postData = {
      account_type: bankData?.account_type,
      account_name: data?.account_name,
      account_code: bankData?.account_code ? bankData?.account_code : "",
      currency: data?.currency?.value ? data?.currency?.value : "",
      account_number: bankData?.account_number ? bankData?.account_number : "",
      bank_name: data?.bank_name,
      ifsc_code: bankData?.ifsc_code ? bankData?.ifsc_code : "",
      description: bankData?.description ? bankData?.description : "",
      is_primary: bankData?.is_primary,
    };
    dispatch(bankCreation(postData));
  };

  const addCreditCard = (
    <BankAddEditModal
    id={"add_bank"}
    modalRef={modalRef}
    show={show}
    title={"Add Bank or Credit Card"}
    handleSubmit={handleSubmit}
    addBankSubmit={addBankSubmit}
    bankData={bankData}
    handleOptionChange={handleOptionChange}
    addError={addError}
    control={control}
    errors={errors}
    handleChange={handleChange}
    selectRefs={selectRefs}
    currencyTypes={currencyTypes}
    handleSelectChange={handleSelectChange}
    handleCheckBoxChange={handleCheckBoxChange}
    handleModalClose={handleModalClose}
    loading={loading}
    />
  );

  return <>{addCreditCard}</>;
};

AddBank.propTypes = {
  bankData : PropTypes.object.isRequired,
  modalRef : PropTypes.object.isRequired,
  show : PropTypes.bool.isRequired,
  handleSubmit : PropTypes.func.isRequired,
  control : PropTypes.object.isRequired,
  handleChange : PropTypes.func.isRequired,
  errors : PropTypes.object.isRequired,
  addError :  PropTypes.object,
  selectRefs : PropTypes.object.isRequired,
  currencyTypes : PropTypes.array,
  handleSelectChange : PropTypes.func.isRequired,
  handleModalClose : PropTypes.func.isRequired,
  handleCheckBoxChange : PropTypes.func.isRequired,
  handleOptionChange : PropTypes.func.isRequired,
  loading :  PropTypes.bool,
};

export default AddBank;
