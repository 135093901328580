/* eslint-disable */
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select, Space } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CustomSelect = ({
  id,
  value,
  onChange,
  options,
  // addLedgerData,
  setAddLedgerData,
  setIsModalVisible,
  // isModalVisible,
  // handleSubmit,
  // handleEditSubmit,
  // control,
  // errors,
  // handleModalClose,
  setApiError,
  // apiError,
}) => {

  const handleModalOpen = () => {
    setIsModalVisible(true);
    setApiError({})
    setAddLedgerData({ title: "", type: "", description: "" });
  }; 

  return (
    <>
      <div>
        <Select
          style={{
            width: 300,
          }}
          value={value}
          onChange={onChange}
          id={id}
          placeholder="Select.."
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Link
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  to="#add_ledger"
                  role="button"
                >
                  <span
                    onClick={handleModalOpen}
                  >  <i className="far fa-add" /> Add item
                  </span>
                </Link>
              </Space>
            </>
          )}
          options={options?.map((option) => ({
            value: option.id,
            label: option.title,
          }))}
        />
      </div>
      {/* {createLedger} */}
    </>
  );
};

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  addLedgerData: PropTypes.object,
  setAddLedgerData: PropTypes.array,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  control: PropTypes.object,
  errors: PropTypes.object,
  handleModalClose: PropTypes.func,
  setApiError: PropTypes.func,
  apiError: PropTypes.object,
};

export default CustomSelect;
