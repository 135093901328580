/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import { Table } from "antd";
import DatePicker from "react-datepicker";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { getInvoiceList, setPage } from "../../redux/actions/ARmoduleAction";
import { useSelector } from "react-redux";
import {
  clearButtonHoverStyle,
  clearButtonStyle,
  containerStyle,
  formatDateYYMMDD,
  invoiceDetails,
} from "./constant";

const InvoiceList = () => {
  const [menu, setMenu] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [filterData, setFilterData] = useState({
    invoice_number: "",
    invoice_date: "",
    po_num: "",
    nature_of_supply: "",
  });

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const dispatch = useDispatch();

  const invoiceList = useSelector((state) => state?.invoiceList);
  const { loading: invoiceListLoading, arInvoice, page, perPage } = invoiceList;

  const userData = useSelector((state) => state.userSignin);
  const { permissions } = userData;

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    dispatch(
      getInvoiceList(
        page,
        perPage,
        filterData?.invoice_number,
        filterData?.invoice_date
          ? formatDateYYMMDD(filterData?.invoice_date)
          : "",
        filterData?.po_num,
        filterData?.nature_of_supply
      )
    );;
  }, [page, perPage, filterData]);


  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      sorter: (a, b) => a.invoice_number.length - b.invoice_number.length,
    },
    {
      title: "Date",
      dataIndex: "invoice_date",
      sorter: (a, b) => new Date(a.invoice_date) - new Date(b.invoice_date),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      sorter: (a, b) => a?.customer?.length - b?.customer?.length,
    },
    {
      title: "Description/Period",
      dataIndex: "description",
      sorter: (a, b) => a?.description?.length - b?.description?.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      sorter: (a, b) => a.tax - b.tax,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {permissions?.arModule?.addEditTransactions && (
            <>
              <Link
                to={`/ar-module/arModule-edit-invoice/${record?.id}`}
                className="btn btn-primary me-2"
              >
                <i className="far fa-edit" />
              </Link>
              <Link className="btn btn-greys" to={"#"}>
                {" "}
                <i className="far fa-send" />{" "}
              </Link>
            </>
          )}
        </>
      ),
    },
  ];

  const invoiceDetailColumn = [
    {
      title: "View Suggested Journal Entry",
      colSpan: 4, // This will span 4 columns
    },
    {
      title: "Debit",
      colSpan: 4, // This will span 4 columns
    },
    {
      title: "Credit",
      colSpan: 2, // This will span 2 columns
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Ledger Name",
      dataIndex: "ledger_name",
      key: "ledger_name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "debit_date",
      key: "debit_date",
    },
    {
      title: "Ledger",
      dataIndex: "credit_ledger",
      key: "credit_ledger",
    },
    {
      title: "Amount",
      dataIndex: "credit_amount",
      key: "credit_amount",
    },
  ];

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  const handleExpand = (expanded, record) => {
    const keys = expanded
      ? [...expandedRowKeys, record.id]
      : expandedRowKeys.filter((key) => key !== record.id);
    setExpandedRowKeys(keys);
  };

  const expandedRowRender = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: 1 }}>
          <Table
            columns={invoiceDetailColumn}
            pagination={false}
            dataSource={invoiceDetails}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a>AR module</a>
                    </li>
                    <li className="breadcrumb-item active">Invoice List</li>
                  </ul>
                </div>
                {permissions?.arModule?.addEditTransactions && (
                  <div className="list-btn">
                    <ul className="filter-list filterbutton">
                      <li>
                        <Link
                          to={`/ar-module/arModule-add-invoice`}
                          className="btn btn-primary"
                          
                        >
                          <i
                            className="fa fa-plus-circle me-2"
                            aria-hidden="true"
                          />
                          Create Invoice
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <ul className="filter-list">
                <li>
                  <input
                    id="invoice_number"
                    type="text"
                    placeholder="Invoice Number"
                    value={filterData?.invoice_number}
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </li>

                <li>
                  <div
                    style={containerStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <DatePicker
                      className="datetimepicker form-control"
                      id="invoice_date"
                      selected={filterData?.invoice_date}
                      placeholderText="Invoice Date"
                      onChange={(selectedDate) =>
                        handleDateFilterChange(selectedDate, "invoice_date")
                      }
                    />
                    {filterData?.invoice_date && (
                      <button
                        type="button"
                        style={{
                          ...clearButtonStyle,
                          ...(isHovered ? clearButtonHoverStyle : {}),
                        }}
                        onClick={() =>
                          handleDateFilterChange(null, "invoice_date")
                        }
                        aria-label="Clear Date"
                      >
                        <i className="fas fa-times" />
                      </button>
                    )}
                  </div>
                </li>

                <li>
                  <input
                    id="po_num"
                    type="text"
                    placeholder="Purchase Order No"
                    value={filterData?.po_num}
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </li>
                <li>
                  <input
                    id="nature_of_supply"
                    type="text"
                    placeholder="Nature of Supply"
                    value={filterData?.nature_of_supply}
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body">
                    <div className="table-responsive table-hover cmb-55">
                      <Table
                        className="table armoduleList"
                        loading={invoiceListLoading}
                        pagination={{
                          total: arInvoice?.count,
                          current: page,
                          pageSize: perPage,
                          onChange: handleTableChange,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        expandable={{
                          expandedRowRender,
                          expandedRowKeys,
                          onExpand: handleExpand,
                        }}
                        columns={columns}
                        dataSource={arInvoice?.results}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceList;
