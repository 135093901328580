/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../_components/antd.css";
import { message, Spin, Table } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { getLedgerModuleList, postLedgerModuleList } from "../../redux/actions/LedgerModuleAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CustomInput from "../../_components/inputField/custominput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";

const ledgerSchema = yup.object({
  title: yup
    .string()
    .required("Title is required")
    .trim(),
  type: yup
    .string()
    .required("Type is required")
    .trim(),
});

const LedgerList = ({ activeTab }) => {
  const [page, setPage] = useState({ page: 1, perPage: 10 });

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading, data } = ledgerList;
  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const { loading:postLedgerLoadig, error,success:postLedgerSuccess } = postLedgerList;

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [apiError, setApiError] = useState({});

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver:yupResolver(ledgerSchema) ,
    defaultValues: addLedgerData,
  });

  useEffect(() => {
    const fieldOrder = [
      "title",
      "type",
      "description",
    ];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    if (firstErrorField) {
      let inputElement;

      inputElement = document.getElementById(firstErrorField);

      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      render: (text, record, index) =>
        page?.perPage * (page?.page - 1) + index + 1,
    },
    {
      title: "Ledger Name",
      dataIndex: "title",
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a.type.length - b.type.length,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: () => (
        <>
          <a className="btn btn-primary me-2">
            <i className="far fa-edit" />
          </a>
          <a className="btn btn-greys">
            <i className="far fa-send" />
          </a>
        </>
      ),
      sorter: (a, b) => a.Action.length - b.Action.length,
    },
  ];

  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setAddLedgerData({ title: "", type: "", description: "" });
      reset();
    });
  };

  useEffect(()=>{
    onModalClose()
  },[])

  useEffect(() => {
    if (activeTab?.includes("#ledgertab")) {
      dispatch(getLedgerModuleList(page?.page, page?.perPage, false, ""));
    }
  }, [dispatch, page?.page, page?.perPage, activeTab]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleEditSubmit = (datas) => {
      const postData = {
        title: datas?.title,
        type: datas?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
  };

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      handleModalClose("#add_ledger");
      setAddLedgerData({
        title: "",
        type: "",
        description: "",
      });
      setApiError({});
      dispatch(getLedgerModuleList(page?.page, page?.perPage, false, ""));
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
      });
    }
  }, [postLedgerSuccess]);



  const createLedger = (
    <div
      id="add_ledger"
      className="modal fade"
      // tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Ledger</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(handleEditSubmit)}>
              <div className="col-md-12">
                <div className="form-group-item border-0 mb-0">
                  <div className="row align-item-center">
                    <div className="col-lg-12 col-md-6 col-sm-12">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <CustomInput
                            id="title"
                            type="text"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeholder="Title"
                            label="Title"
                            errorMsg={errors?.title?.message}
                            error={errors?.title}
                            apiError={apiError?.title}
                            autoComplete="false"
                            required={true}
                          />
                        )}
                      />
                      <br/>
                      <Controller
                        name="type"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <CustomInput
                            id="type"
                            type="text"
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            placeholder="Type"
                            label="Type"
                            errorMsg={errors?.type?.message}
                            error={errors?.type}
                            apiError={apiError?.type}
                            autoComplete="false"
                            required={true}
                          />
                        )}
                      />
                      {/* <CustomInput
                        id="title"
                        type="text"
                        value={addLedgerData?.title}
                        onChange={handleChange}
                        placeholder="Title"
                        label="Title"
                        required={true}
                        apiError={apiError?.title}
                      />
                      <CustomInput
                        id="type"
                        type="text"
                        value={addLedgerData?.type}
                        onChange={handleChange}
                        placeholder="Type"
                        label="Type"
                        required={true}
                        apiError={apiError?.type}
                      /> */}
                         <br/>
                      <CustomInput
                        id="description"
                        type="text"
                        value={addLedgerData?.description}
                        onChange={handleChange}
                        placeholder="Description"
                        label="Description"
                        apiError={apiError?.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => handleModalClose("#add_ledger")}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-primary"
                    // onClick={handleEditSubmit}
                  >
                    {postLedgerLoadig ? <Spin /> : "Save"}
                  </button>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="list-btn">
        <ul className="filter-list">
          <li>
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Journal
          </li>
        </ul>
      </div> */}
      {/* Table */}
      {contextHolder}
      <div className="row">
        <div className="col-sm-12">
        <Link
            className="btn btn-white addGrpBtn"
            data-bs-toggle="modal"
            // to="#add_ledger"
            role="button"
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Group
          </Link>
        <Link
            className="btn btn-primary addBtn"
            data-bs-toggle="modal"
            to="#add_ledger"
            role="button"
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Ledger
          </Link>
          
          <div className=" card">
            <div className="card-body">
              
          <div className="table-responsive">
            <Table
              loading={loading}
              pagination={{
                total: data?.count,
                current: page?.page,
                pageSize: page?.perPage,
                onChange: handleTableChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              columns={columns}
              dataSource={data?.results}
              rowKey={(record) => record.Id}
              className="chartofAccTables"
            />
            </div>
            </div>
          </div>
        </div>
   
      </div>
      {createLedger}
    </>
  );
};

LedgerList.propTypes = {
  activeTab: PropTypes.string,
};

export default LedgerList;
