import React from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { uploadInvoice } from "../../redux/actions/APmoduleAction";
import { UPLOAD_AP_INVOICE_RESET } from "../../redux/constants/APmoduleConstant";
import CreateModal from "../../_components/modalbox/createmodal";
import { Spin } from "antd";
import PropTypes from "prop-types";

const UploadInvoiceModal = ({
  file,
  uploadData,
  setFile,
  fileInputRef,
  invoiceUploadError,
  setUploadData,
  data,
  inputValue,
  setInputValue,
  debouncedLedgerListFetch,
  invoiceUploadLoading,
}) => {
  const dispatch = useDispatch();

  const paymentmethod = [
    { id: "Cash", text: "Cash" },
    { id: "Credit", text: "Credit" },
    { id: "Netbanking", text: "Netbanking" },
  ];

  const handleOnSubmit = (event) => {
    event.preventDefault();
    const form_data = new FormData();
    // file.forEach((f) => form_data.append("invoices[]", f));
    file.forEach((f) => form_data.append("invoices", f));
    uploadData?.payment_method?.value &&
      form_data.append("payment_method", uploadData?.payment_method?.value);
    uploadData?.expense_head?.value &&
      form_data.append("expense_head", uploadData?.expense_head?.value);
    dispatch(uploadInvoice(form_data));
  };

  const handleFileChange = (event) => {
    setFile(Array.from(event.target.files));
    dispatch({ type: UPLOAD_AP_INVOICE_RESET });
  };

  const handleLedgerInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, ""); // Example to clean input
    setInputValue(inputValue);
    if (inputValue) {
      debouncedLedgerListFetch(inputValue);
    }
  };

  const uploadInvoicemodal = (
    <CreateModal
      id="upload_invoice"
      show={false}
      title="Upload Invoice"
      modalbody={
        <>
          <form onSubmit={handleOnSubmit}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label">File Input</label>
                    <input
                      className="form-control"
                      type="file"
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileChange}
                      required
                    />
                    {file?.map((el, index) => (
                      <blockquote key={index}>
                        <p className="mb-0">{el?.name}</p>
                      </blockquote>
                    ))}
                    {invoiceUploadError?.detail && (
                      <p className="text-danger">
                        {invoiceUploadError?.detail}
                      </p>
                    )}
                    {invoiceUploadError?.errors && (
                      <p className="text-danger">
                        {invoiceUploadError?.errors}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Payment Method</label>
                    <Select
                      value={uploadData?.payment_method}
                      options={paymentmethod?.map((option) => ({
                        value: option.id,
                        label: option.text,
                      }))}
                      onChange={(selectedOption) => {
                        setUploadData((prevState) => ({
                          ...prevState,
                          payment_method: selectedOption,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label>Expense Head</label>
                    <Select
                      value={uploadData?.expense_head}
                      options={
                        data?.length > 0
                          ? data?.map((option) => ({
                              value: option.id,
                              label: option.title,
                            }))
                          : []
                      }
                      onChange={(selectedOption) => {
                        setUploadData((prevState) => ({
                          ...prevState,
                          expense_head: selectedOption,
                        }));
                      }}
                      onInputChange={handleLedgerInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12">
                      <FileUpload
                        setFiles={setFile}
                        files={file}
                        reset={"UPLOAD_AP_INVOICE_RESET"}
                      />
                      
                    </div> */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                disabled={invoiceUploadLoading}
              >
                {invoiceUploadLoading ? <Spin /> : "Submit"}
              </button>
            </div>
          </form>
        </>
      }
    />
  );
  return <>{uploadInvoicemodal}</>;
};

UploadInvoiceModal.propTypes = {
  file: PropTypes.array.isRequired,
  uploadData: PropTypes.object,
  setFile: PropTypes.func.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  invoiceUploadError: PropTypes.object,
  setUploadData: PropTypes.func.isRequired,
  data: PropTypes.array,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  debouncedLedgerListFetch: PropTypes.func.isRequired,
  invoiceUploadLoading: PropTypes.bool,
};

export default UploadInvoiceModal;
