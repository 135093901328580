/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

const ConfirmationModal = ({
  isVisible,
  onConfirm,
  currentStatus,
  newStatus,
  invoiceApprovalLoading,
  invoicePostApprovalLoading,
  onClose,
  // handleModalClose,
  rejectionReason,
  setRejectionReason,
}) => {
  if (!isVisible) return null;

  const handleConfirm = () => {
    onConfirm(rejectionReason);
    onClose();
  };

  return (
    <div
      id="status-change-confirmation"
      className="modal fade show"
      tabIndex={-1}
      role="dialog"
      aria-hidden={!isVisible}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Change Status Confirmation</h4>
            <button
              type="button"
              className="btn-close"
              // onClick={()=>handleModalClose("#status-change-confirmation")}
              onClick={()=>onClose()}
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {newStatus === "Reject" ? (
              <>
                <p>
                  Are you sure you want to switch status from{" "}
                  <strong>{currentStatus}</strong> to{" "}
                  <strong>{newStatus}</strong>?
                </p>
                <div className="form-group">
                  <label>
                    Reason for Rejection
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <textarea
                    id="rejectionReason"
                    className="form-control"
                    rows="3"
                    value={rejectionReason}
                    onChange={(e) => setRejectionReason(e.target.value)}
                  />
                </div>
              </>
            ) : (
              <p>
                Are you sure you want to switch status from{" "}
                <strong>{currentStatus}</strong> to <strong>{newStatus}</strong>
                ?
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              // onClick={()=>handleModalClose("#status-change-confirmation")}
              onClick={()=>onClose()}

            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleConfirm}
              disabled={invoiceApprovalLoading || invoicePostApprovalLoading}
            >
              {invoiceApprovalLoading || invoicePostApprovalLoading ? <Spin /> : "Yes"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentStatus: PropTypes.string.isRequired,
  newStatus: PropTypes.string.isRequired,
};

export default ConfirmationModal;
