import { useMemo } from 'react';

const useCalculateTotals = (data) => {
  const totalBaseAmount = useMemo(() => {
    if (!Array.isArray(data)) return 0;
    const sum = data.reduce((accumulator, el) => {
      if (el && typeof el.amount === "string") {
        const amount = parseFloat(el.amount);
        if (!isNaN(amount)) return accumulator + amount;
      }
      return accumulator;
    }, 0);
    return Number(sum);
  }, [data]);

  const totalAmount = useMemo(() => {
    if (!Array.isArray(data)) return 0;
    const sum = data.reduce((accumulator, el) => {
      if (el && typeof el.total === "string") {
        const total = parseFloat(el.total);
        if (!isNaN(total)) return accumulator + total;
      }
      return accumulator;
    }, 0);
    return Number(sum);
  }, [data]);

  const totalTdsAmount = useMemo(() => {
    if (!Array.isArray(data)) return 0;
    const sum = data.reduce((accumulator, el) => {
      if (
        (el && typeof el.tds_amount === "string") ||
        typeof el.tds_amount === "number"
      ) {
        const tds_amount = parseFloat(el.tds_amount);
        if (!isNaN(tds_amount)) return accumulator + tds_amount;
      }
      return accumulator;
    }, 0);
    return Number(sum);
  }, [data]);

  const totalGstAmount = useMemo(() => {
    if (!Array.isArray(data)) return 0;
    const sum = data.reduce((accumulator, el) => {
      if (
        (el && typeof el.gst_amount === "string") ||
        typeof el.gst_amount === "number"
      ) {
        const gst_amount = parseFloat(el.gst_amount);
        if (!isNaN(gst_amount)) return accumulator + gst_amount;
      }
      return accumulator;
    }, 0);
    return Number(sum);
  }, [data]);

  return { totalBaseAmount, totalAmount, totalTdsAmount, totalGstAmount };
};

export default useCalculateTotals;
