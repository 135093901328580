import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_TDS_LIST_REQUEST,
  GET_TDS_LIST_SUCCESS,
  GET_TDS_LIST_FAIL,
  GET_ROLES_LIST_REQUEST,
  GET_ROLES_LIST_SUCCESS,
  GET_ROLES_LIST_FAIL,
  GET_EXPENSE_MANAGER_LIST_REQUEST,
  GET_EXPENSE_MANAGER_LIST_SUCCESS,
  GET_EXPENSE_MANAGER_LIST_FAIL,
} from "../constants/CommonApiConstant";

export const customerListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_SUCCESS:
      return { loading: false, customer: action.payload };
    case GET_CUSTOMER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const companyListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return { loading: true };
    case GET_COMPANY_SUCCESS:
      return { loading: false, company: action.payload };
    case GET_COMPANY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { loading: true };
    case GET_USER_SUCCESS:
      return { loading: false, userListData: action.payload };
    case GET_USER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tdsSectionListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TDS_LIST_REQUEST:
      return { loading: true };
    case GET_TDS_LIST_SUCCESS:
      return { loading: false, tdsSectionData: action.payload };
    case GET_TDS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rolesListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ROLES_LIST_REQUEST:
      return { loading: true };
    case GET_ROLES_LIST_SUCCESS:
      return { loading: false, rolesData: action.payload };
    case GET_ROLES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const expenseManagerListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EXPENSE_MANAGER_LIST_REQUEST:
      return { loading: true };
    case GET_EXPENSE_MANAGER_LIST_SUCCESS:
      return { loading: false, managerData: action.payload };
    case GET_EXPENSE_MANAGER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
