import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_TDS_LIST_REQUEST,
  GET_TDS_LIST_SUCCESS,
  GET_TDS_LIST_FAIL,
  GET_ROLES_LIST_REQUEST,
  GET_ROLES_LIST_SUCCESS,
  GET_ROLES_LIST_FAIL,
  GET_EXPENSE_MANAGER_LIST_REQUEST,
  GET_EXPENSE_MANAGER_LIST_SUCCESS,
  GET_EXPENSE_MANAGER_LIST_FAIL,
  SET_ERROR,
  CLEAR_ERROR,
} from "../constants/CommonApiConstant";
import { HTTP } from "../api";

export const getCustomerList = () => async (dispatch, getState) => {
  dispatch({ type: GET_CUSTOMER_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/customer/customer-all/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_CUSTOMER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCompanyList = () => async (dispatch, getState) => {
  dispatch({ type: GET_COMPANY_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/company/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_COMPANY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_COMPANY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserList = () => async (dispatch, getState) => {
  dispatch({ type: GET_USER_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/users/list", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getTdsSectionList = () => async (dispatch, getState) => {
  dispatch({ type: GET_TDS_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/tds/tds-section/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_TDS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_TDS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRolesListApi = () => async (dispatch, getState) => {
  dispatch({ type: GET_ROLES_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get("/users/roles/", {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: GET_ROLES_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ROLES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getExpenseManagerListApi = () => async (dispatch, getState) => {
  dispatch({ type: GET_EXPENSE_MANAGER_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      "/users/manage-team/?role=expense_manager",
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: GET_EXPENSE_MANAGER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_EXPENSE_MANAGER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setError = (message) => ({
  type: SET_ERROR,
  payload: message,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
