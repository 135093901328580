import {
  ZOHOAUTH_LIST_FAIL,
  ZOHOAUTH_LIST_REQUEST,
  ZOHOAUTH_LIST_SUCCESS,
  ZOHOCRM_CREATE_FAIL,
  ZOHOCRM_CREATE_REQUEST,
  ZOHOCRM_CREATE_SUCCESS,
  ZOHOCRM_LIST_FAIL,
  ZOHOCRM_LIST_REQUEST,
  ZOHOCRM_LIST_SUCCESS,
} from "./CrmandErpConstant";
import axios from "axios";

export const zohoCrmCreate = (payload) => async (dispatch, getState) => {
  dispatch({ type: ZOHOCRM_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.patch(
      "https://api.tideiq.com/api/users/zoho-organization-id/",
      payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: ZOHOCRM_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ZOHOCRM_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const CheckZohoOrgId = () => async (dispatch, getState) => {
  dispatch({ type: ZOHOCRM_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      "https://api.tideiq.com/api/users/zoho-organization-id/check/",
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: ZOHOCRM_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: ZOHOCRM_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ZohoAuth = () => async (dispatch, getState) => {
  dispatch({ type: ZOHOAUTH_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      "https://api.tideiq.com/api/users/zoho-auth/",
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: ZOHOAUTH_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    dispatch({
      type: ZOHOAUTH_LIST_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
