/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Spin, message } from "antd";
import { companyData } from "../../redux/actions/UserAction";
import { useHistory } from "react-router-dom";
import { USER_COMPANY_RESET } from "../../redux/constants/UserConstant";

const schema = yup.object().shape({
  name: yup.string().required("Company name is required"),
  gstin_number: yup.string().required("GSTIN number is required"),
  pan_number: yup.string().required("PAN number is required"),
  plan_id: yup.string().required("payment plan is required"),
});

const passwordOptions = [
  {
    price: "INR 799.00",
    name: "Seed",
    description: "Monthly plan for professionals",
    period: "yearly",
    plan_id: "plan_OhuwmT98G7HfkI",
  },
  {
    price: "INR 1999.00",
    name: "Germinate",
    description: "Professional Pricing",
    period: "monthly",
    plan_id: "plan_OhjvyWyKZAdIcP",
  },
  {
    price: "INR 2499.00",
    name: "Germinate",
    description: "Corporate Pricing",
    period: "monthly",
    plan_id: "plan_OhjvKc0L9kdFHh",
  },
  {
    price: "INR 1499.00",
    name: "Yearly Plan",
    description: null,
    period: "yearly",
    plan_id: "plan_OcR8N0tPc2q2DV",
  },
];

const Step2Form = ({ setFormData, formData, setCurrentStep }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const userCompanyDetail = useSelector((state) => state.userCompanyInfo);
  const { payload, success, loading, error } = userCompanyDetail;
  const [apiError, setApiError] = useState();

  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();

  const onSubmit = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    dispatch(companyData(data));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setApiError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  useEffect(() => {
    setApiError(error);
  }, [error]);

  useEffect(() => {
    if (success) {
      // history.push("/index");
      window.location.href=payload.payment_url
      dispatch({ type: USER_COMPANY_RESET });
    }
  }, [success]);

  return (
    <>
      {contextHolder}
      <h5>Enter company details</h5>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="text"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  id="name"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  placeholder="Company Name"
                />
              )}
            />
            {errors.name?.message && (
              <p className="text-danger">{errors.name?.message}</p>
            )}
            {apiError?.name && <p className="text-danger">{apiError?.name}</p>}
          </div>
        </div>

        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="gstin_number"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="text"
                  className={`form-control ${
                    errors.gstin_number ? "is-invalid" : ""
                  }`}
                  id="gstin_number"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  placeholder="GSTIN Number"
                />
              )}
            />
            {errors.gstin_number?.message && (
              <p className="text-danger">{errors.gstin_number?.message}</p>
            )}
            {apiError?.gstin_number && (
              <p className="text-danger">{apiError?.gstin_number}</p>
            )}
          </div>
        </div>

        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="pan_number"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="text"
                  className={`form-control ${
                    errors.pan_number ? "is-invalid" : ""
                  }`}
                  id="pan_number"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  placeholder="PAN Number"
                />
              )}
            />
            {errors.pan_number?.message && (
              <p className="text-danger">{errors.pan_number?.message}</p>
            )}
            {apiError?.pan_number && (
              <p className="text-danger">{apiError?.pan_number}</p>
            )}
          </div>
        </div>
        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="plan_id"
              control={control}
              render={({ field: { value, onChange } }) => (
                <select
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Payment Plan"
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  value={value}
                  id="plan_id"
                >
                  <option value="">Select Payment Plan</option>
                  {passwordOptions.map((option) => (
                    <option key={option.value} value={option.plan_id}>
                      {option.name} - {option.price} - {option.period}
                    </option>
                  ))}
                </select>
              )}
            />
            {errors.password?.message && (
              <p className="text-danger">{errors.password?.message}</p>
            )}
            {apiError?.password && (
              <p className="text-danger">{apiError?.password}</p>
            )}
          </div>
        </div>

        <div className="text-center">
          <button
            className="btn btn-lg btn-block w-100 btn-primary"
            type="submit"
          >
            {loading ? <Spin /> : "Continue"}
          </button>
        </div>
      </form>
    </>
  );
};

Step2Form.propTypes = {
  setFormData: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gstin_number: PropTypes.string.isRequired,
    pan_number: PropTypes.string.isRequired,
  }).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default Step2Form;
