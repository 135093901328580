import React,{useState} from "react";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { Spin } from 'antd';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const FallBackUi=()=>{
    const [menu,setMenu]=useState(false)
    const location = useLocation();

    const toggleMobileMenu = () => {
        setMenu(!menu);
      };

      const pathname=[
        '/',
        '/register',
      ]
    return(
       <>
       <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {!pathname.includes(location.pathname) && (
          <>          <Header onMenuClick={() => toggleMobileMenu()}/>
        <Sidebar />
        </>
        )}
        
        <div className="main-wrapper" style={{marginTop:'60px'}}>
          <div className="error-box">
           {/* Loading */}
            <Spin/>
          </div>
        </div>
      </div>

       </>
    );
};

export default FallBackUi