/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import "../../../_components/antd.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  recurringEditInvoiceDetail,
  updateRecurringInvoice,
} from "../../../redux/actions/RecurringAction";
import { message, Spin } from "antd";
import { getCustomerList } from "../../../redux/actions/commonApiAction";
import { RECURRINGINVOICE_EDIT_RESET } from "../../../redux/constants/RecurringInvoiceConstant";
import {
  dayList,
  formatDateYYMMDD,
  initialLedgerData,
  intervalList,
  scheduleTypes,
} from "../constant";
import AddEditRecurringModal from "./addEditRecurringModal";
import {
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../../redux/actions/LedgerModuleAction";
import { POST_LEDGERLIST_RESET } from "../../../redux/constants/ledgerListModuleConstant";
import useUpdateFooterData from "../../../customers/updateFooterData";
import useCalculateTotals from "../../../helpers/calculation";

const schema = yup.object({
  // invoice_number: yup
  //   .string()
  //   .required("Invoice number is required")
  //   .trim(),
  // interval: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required("Interval is required"),
  //   })
  //   .nullable()
  //   .required("Interval is required"),
  // day: yup
  //   .object()
  //   .shape({
  //     value: yup.string().required("Day is required"),
  //   })
  //   .nullable()
  //   .required("Day is required"),
  customer: yup
    .object()
    .shape({
      value: yup.number().required("Customer is required"),
    })
    .nullable()
    .required("Company is required"),
  start_date: yup
    .date()
    .required("Start date is required")
    .nullable()
    .required("State date is required"),
  end_date: yup
    .date()
    .required("End date is required")
    .nullable()
    .required("End date is required"),
  // invoice_date: yup
  //   .date()
  //   .required("Invoice date is required")
  //   .nullable()
  //   .required("Invoice date is required"),
  // billing_period: yup
  //   .string()
  //   .required("Billing Period is required")
  //   .trim(),
});

const EditRecurringInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const [recurringInvoiceData, setRecurringInvoiceData] = useState({});
  const [editError, setEditError] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [data, setData] = useState(initialLedgerData);
  const [calculationData, setCalculationData] = useState({});
  const [footerData, setFooterData] = useState({});
  const [gstSection, setGstSection] = useState([]);
  const [itemsError, setItemsError] = useState(initialLedgerData);
  const [customerDetail, setCustomerDetail] = useState({});
  const [editable, setEditable] = useState({});
  const [scheduleData, setScheduleData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });
  const [editData, setEditData] = useState({
    schedule_type: null,
    quarterly: {
      quarter_month: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every month
    },
  });

  const recurringEditDetailInvoice = useSelector(
    (state) => state.editRecurringDetailInvoice
  );
  const {
    recurringEditDetail,
    loading: recurringEditDetailLoading,
  } = recurringEditDetailInvoice;
  const recurringEditInvoice = useSelector(
    (state) => state.editRecurringInvoice
  );
  const { loading, success, error } = recurringEditInvoice;

  const [messageApi, contextHolder] = message.useMessage();

  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(footerData, setFooterData, totals, gstSection);

  const onModalOpen = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(true);
      setAddLedgerData({ title: "", type: "", description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", type: "", description: "" });
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
  } = postLedgerList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  const datePickerRef = {
    start_date: useRef(null),
    end_date: useRef(null),
    invoice_date: useRef(null),
  };
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setIsChanged(true);
  };

  const handleCheckBox = (e, selectedOption) => {
    const { checked } = e.target;
    setIsChanged(true);
    if (checked) {
      const updatedData = { ...scheduleData };
      updatedData["weekly"] &&
        updatedData["weekly"]["days_of_week"]?.push(selectedOption);
      setScheduleData(updatedData);
      const edit_data = { ...editData };
      edit_data["weekly"] &&
        edit_data["weekly"]["days_of_week"]?.push(selectedOption);
      setEditData(edit_data);
    } else {
      setScheduleData((prevState) => ({
        ...prevState,
        weekly: {
          ...scheduleData?.weekly,
          days_of_week: scheduleData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
      setEditData((prevState) => ({
        ...prevState,
        weekly: {
          ...editData?.weekly,
          days_of_week: editData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
    }
  };

  const handleRepeatSelectChange = (selectedOption, scheduleType, id) => {
    setScheduleData((prevState) => ({
      ...prevState,
      [scheduleType]: { ...scheduleData[scheduleType], [id]: selectedOption },
    }));
    setEditData((prevState) => ({
      ...prevState,
      [scheduleType]: {
        ...editData[scheduleType],
        [id]: selectedOption?.value,
      },
    }));
    setIsChanged(true);
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    setIsChanged(true);
    const filterData = [...data];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setData(filterData);
  };

  const handleSelectChange = (id, selectedOption) => {
    setIsChanged(true);
    setEditData((prevState) => ({ ...prevState, [id]: selectedOption }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleDateChange = (id, selectedDate) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setEditData((prevState) => ({ ...prevState, [id]: selectedDate }));
    setIsChanged(true);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: recurringInvoiceData,
  });

  const editSubmit = (datas) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger?.value,
      taxes: [],
    }));
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        type: addLedgerData?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    } else {
      const postData = {
        customer: datas?.customer?.value,
        start_date: datas?.start_date
          ? formatDateYYMMDD(datas?.start_date)
          : "",
        end_date: datas?.end_date ? formatDateYYMMDD(datas?.end_date) : "",
        nature_of_invoice: editData?.nature_of_invoice,
        schedule: {
          schedule_type: scheduleData?.schedule_type
            ? scheduleData?.schedule_type?.value
            : "",
        },
        items: ledger,
      };

      if (editData?.schedule_type) {
        if (editData?.schedule_type === "monthly") {
          if (editData?.dayOfMonth) {
            postData["schedule"]["monthly"] = {
              day_of_month: editData["monthly"]["day_of_month"],
              repeat_every: editData["monthly"]["repeat_every"],
            };
          } else if (editData?.nthWeekday) {
            postData["schedule"]["monthly"] = {
              nth_day_of_week: editData["monthly"]["nth_day_of_week"],
              repeat_every: editData["monthly"]["repeat_every"],
              day_of_week: editData["monthly"]["day_of_week"],
            };
          }
        } else {
          postData["schedule"][editData?.schedule_type] =
            editData[editData?.schedule_type];
        }
      }

      dispatch(updateRecurringInvoice(postData, id));
      setIsChanged(false);
    }
  };

  useEffect(() => {
    if (recurringInvoiceData) {
      Object.keys(recurringInvoiceData).forEach((field) => {
        setValue(field, recurringInvoiceData[field]);
      });
      if (recurringInvoiceData?.invoice_date) {
        clearErrors("invoice_date");
      }
      if (recurringInvoiceData?.start_date) {
        clearErrors("start_date");
      }
      if (recurringInvoiceData?.end_date) {
        clearErrors("end_date");
      }
    }
  }, [recurringInvoiceData, setValue]);

  useEffect(() => {
    if (error?.error) {
      setItemsError(
        error?.error?.items ? error?.error?.items : initialLedgerData
      );
    } else {
      setEditError(error);
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "interval",
      "day",
      "start_date",
      "end_date",
      "invoice_date",
      "billing_period",
      "customer",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (
        firstErrorField === "interval" ||
        firstErrorField === "day" ||
        firstErrorField === "customer"
      ) {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "interval",
      "day",
      "start_date",
      "end_date",
      "invoice_date",
      "tax_ledger",
      "billing_period",
      "customer",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (
        firstErrorField === "interval" ||
        firstErrorField === "day" ||
        firstErrorField === "customer"
      ) {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    let elements = Array.from(
      document.getElementsByClassName("react-datepicker-wrapper")
    );
    elements.map((element) => element.classList.add("w-100"));
  }, []);

  useEffect(() => {
    dispatch(recurringEditInvoiceDetail(id));
  }, [id]);

  useEffect(() => {
    if (recurringEditDetail && customer?.results?.length !== 0) {
      const filteredCustomerList = customer?.filter(
        (el) => el?.id === recurringEditDetail?.customer?.id
      );

      const filteredScheduleType = scheduleTypes?.filter(
        (el) => el?.value === recurringEditDetail?.schedule_type
      );
      setRecurringInvoiceData({
        ...recurringEditDetail,
        start_date: parseDate(recurringEditDetail?.start_date)
          ? parseDate(recurringEditDetail?.start_date)
          : "",
        end_date: parseDate(recurringEditDetail?.end_date)
          ? parseDate(recurringEditDetail?.end_date)
          : "",
        customer:
          filteredCustomerList?.length !== 0 && filteredCustomerList
            ? {
                label: filteredCustomerList[0]?.name,
                value: filteredCustomerList[0]?.id,
              }
            : "",
      });
      setFooterData(recurringEditDetail);
      setGstSection(
        recurringEditDetail?.gst_section
          ? Object.keys(recurringEditDetail?.gst_section)
          : []
      );
      setEditData((prevState) => ({
        ...prevState,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? filteredScheduleType[0]?.value
            : null,
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? recurringEditDetail?.schedule?.day_of_month
            : null,
          repeat_every: recurringEditDetail?.schedule?.repeat_every
            ? recurringEditDetail?.schedule?.repeat_every
            : null,
          nth_day_of_week: recurringEditDetail?.schedule?.nth_day_of_week
            ? recurringEditDetail?.schedule?.nth_day_of_week
            : null,
          day_of_week: recurringEditDetail?.schedule?.day_of_week
            ? recurringEditDetail?.schedule?.day_of_week
            : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? recurringEditDetail?.schedule?.month_of_quarter
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? recurringEditDetail?.schedule?.month_of_year
            : null,
        },
      }));
      setScheduleData((prevState) => ({
        ...prevState,
        schedule_type:
          filteredScheduleType?.length !== 0
            ? {
                label: filteredScheduleType[0]?.label,
                value: filteredScheduleType[0]?.value,
              }
            : null,
        dayOfMonth:
          recurringEditDetail?.schedule?.day_of_month &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        nthWeekday:
          recurringEditDetail?.schedule &&
          recurringEditDetail?.schedule?.nth_day_of_week &&
          recurringEditDetail?.schedule_type === "monthly"
            ? true
            : false,
        [recurringEditDetail?.schedule_type]: {
          ...recurringEditDetail?.schedule,
          day_of_month: recurringEditDetail?.schedule?.day_of_month
            ? {
                label: recurringEditDetail?.schedule?.day_of_month,
                value: recurringEditDetail?.schedule?.day_of_month,
              }
            : null,
          repeat_every: recurringEditDetail?.schedule?.repeat_every
            ? {
                label: recurringEditDetail?.schedule?.repeat_every,
                value: recurringEditDetail?.schedule?.repeat_every,
              }
            : null,
          nth_day_of_week: recurringEditDetail?.schedule?.nth_day_of_week
            ? {
                label: recurringEditDetail?.schedule?.nth_day_of_week,
                value: recurringEditDetail?.schedule?.nth_day_of_week,
              }
            : null,
          day_of_week: recurringEditDetail?.schedule?.day_of_week
            ? {
                label: recurringEditDetail?.schedule?.day_of_week,
                value: recurringEditDetail?.schedule?.day_of_week,
              }
            : null,
          month_of_quarter: recurringEditDetail?.schedule?.month_of_quarter
            ? {
                label: recurringEditDetail?.schedule?.month_of_quarter,
                value: recurringEditDetail?.schedule?.month_of_quarter,
              }
            : null,
          month_of_year: recurringEditDetail?.schedule?.month_of_year
            ? {
                label: recurringEditDetail?.schedule?.month_of_year,
                value: recurringEditDetail?.schedule?.month_of_year,
              }
            : null,
        },
      }));
    }
    const ledger =
      recurringEditDetail?.items?.length &&
      recurringEditDetail?.items?.map((ele) => ({
        ...ele,
        ledger: { value: ele?.ledger?.id, label: ele?.ledger?.title },
      }));
    setData(recurringEditDetail?.items?.length ? ledger : initialLedgerData);
  }, [recurringEditDetail, company, customer, dayList]);

  useEffect(() => {
    dispatch(getCustomerList());
    dispatch(getLedgerModuleList("", "", true, ""));
  }, []);

  useEffect(() => {
    if (editData?.customer?.value) {
      const customerDetails = customer?.find(
        (el) => el?.id === editData?.customer?.value
      );
      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, editData?.customer]);

  useEffect(() => {
    if (success) {
      dispatch({ type: RECURRINGINVOICE_EDIT_RESET });
      dispatch(recurringEditInvoiceDetail(id));
      messageApi.open({
        type: "success",
        content: "Recurring Invoice updated successfully",
      });
    }
  }, [success]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleSelectCellChange = (selectedOption, id, rowKey, index) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setIsChanged(true);
    setData(updatedData);
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/recurring-invoices">
                          Recurring Invoice List
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                        Edit Recurring invoice
                      </li>
                    </ul>
                  </div>
                </div>
                {recurringEditDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <AddEditRecurringModal
                      // id={""}
                      handleSubmit={handleSubmit}
                      recurringInvoiceSubmit={editSubmit}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      addError={editError}
                      handleDateChange={handleDateChange}
                      datePickerRef={datePickerRef}
                      recurringInvoiceData={recurringInvoiceData}
                      error={error}
                      selectRefs={selectRefs}
                      customer={customer}
                      handleSelectChange={handleSelectChange}
                      customerDetail={customerDetail}
                      scheduleData={scheduleData}
                      setScheduleData={setScheduleData}
                      handleCheckBox={handleCheckBox}
                      formattedOptions={formattedOptions}
                      handleItemChange={handleItemChange}
                      data={data}
                      setData={setData}
                      itemsError={itemsError}
                      setIsChanged={setIsChanged}
                      isChanged={isChanged}
                      loading={loading}
                      handleRepeatSelectChange={handleRepeatSelectChange}
                      setEditData={setEditData}
                      editModal={true}
                      editable={editable}
                      setEditable={setEditable}
                      handleDoubleClick={handleDoubleClick}
                      handleCellChange={handleCellChange}
                      expenseList={expenseList}
                      handleSelectCellChange={handleSelectCellChange}
                      setAddLedgerData={setAddLedgerData}
                      addLedgerData={addLedgerData}
                      apiError={apiError}
                      setApiError={setApiError}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      postLedgerLoading={postLedgerLoading}
                      handleModalClose={handleModalClose}
                      setFooterData={setFooterData}
                      footerData={footerData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRecurringInvoice;
