/* eslint-disable */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import { InvoiceLogo1 } from "../../_components/Imagepath";
import Step1Form from "./step1";
import Step2Form from "./step2";
import Step3PlanSelection from "./step3";
import Step4PaymentForm from "./step4";
import PropTypes from "prop-types";

const steps = [
  {
    title: "User Details",
    number: "1",
  },
  {
    title: "Additional Info",
    number: "2",
  },
  // {
  //   title: "Plan Selection",
  //   number: "3",
  // },
  // {
  //   title: "Payment",
  //   number: "4",
  // },
];

const Register = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    password: "",
    name: "",
    gstin_number: "",
    pan_number: "",
    plan_id:""
  });

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Step1Form
            formData={formData}
            setFormData={setFormData}
            setCurrentStep={setCurrentStep}
          />
        );
      case 1:
        return (
          <Step2Form
            formData={formData}
            setFormData={setFormData}
            setCurrentStep={setCurrentStep}
          />
        );
      case 2:
        return <Step3PlanSelection />;
      case 3:
        return <Step4PaymentForm formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className="main-wrapper  login-body">
      <div className="login-wrapper">
        <div className="container">
          <img
            className="img-fluid logo-dark mb-2"
            src={InvoiceLogo1}
            alt="Logo"
          />
          <div className="loginbox">
            <div className="login-right">
              <div className="login-right-wrap">
                <h1>Register</h1>
                <p className="account-subtitle">Access to our dashboard</p>
                <Steps
                  current={currentStep}
                  direction="horizontal"
                  labelPlacement="vertical"
                >
                  {steps.map((step) => (
                    <Steps.Step key={step.title} />
                  ))}
                </Steps>
                <br />
                <div>{renderStepContent()}</div>
                {/* <div>
                  {currentStep < 3 && currentStep !== 0 && (
                    <div className="text-center">
                      <button className="btn btn-primary" onClick={handleNext}>
                        Continuess
                      </button>
                    </div>
                  )}
                  {currentStep === 3 && (
                    <div className="text-center">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </button>
                    </div>
                  )}
                </div> */}
                <div className="login-or">
                  <span className="or-line"></span>
                  <span className="span-or">or</span>
                </div>
                <div className="text-center dont-have">
                  Already have an account? <Link to="/">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Register.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Register;
