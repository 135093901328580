import React from "react";
import PropTypes from "prop-types";

const Step4PaymentForm = ({ formData, handleChange }) => {
  return (
    <div className="card-body">
      <h5>Enter payment information</h5>
      <br />
      <form action="#">
        <div className="input-block mb-3 row">
          <div className="col-lg-12">
            <input
              type="text"
              className="form-control"
              placeholder="Name on Card"
              value={formData.cardName}
              onChange={(e) => handleChange("cardName", e.target.value)}
            />
          </div>
        </div>

        <div className="input-block mb-3 row">
          <div className="col-lg-12">
            <input
              type="text"
              className="form-control"
              placeholder="Card Number"
              value={formData.cardNumber}
              onChange={(e) => handleChange("cardNumber", e.target.value)}
            />
          </div>
        </div>
        <div className="input-block mb-3 row">
          <div className="col-lg-12">
            <input
              type="text"
              className="form-control"
              placeholder="MM/YY"
              value={formData.validity}
              onChange={(e) => handleChange("validity", e.target.value)}
            />
          </div>
        </div>
        <div className="input-block mb-3 row">
          <div className="col-lg-12">
            <input
              type="text"
              placeholder="CCV"
              className="form-control"
              value={formData.ccv}
              onChange={(e) => handleChange("ccv", e.target.value)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

Step4PaymentForm.propTypes = {
  formData: PropTypes.shape({
    cardName: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    validity: PropTypes.string.isRequired,
    ccv: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Step4PaymentForm;
