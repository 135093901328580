/* eslint-disable */

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { img2 } from "../../_components/Imagepath";
import CreateModal from "../../_components/modalbox/createmodal";
import { userProfileUpdateApi } from "../../redux/actions/UserAction";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

const ProfileUpdate = ({
  profileData,
  setIsModalVisible,
  isModalVisible,
  setSelectedFile,
  selectedFile,
  setProfileUpdateError,
  profileUpdateError,
  isChange,
  setIsChange,
  userProfileUpdateLoading,
  fileRef,
}) => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setIsChange(true);
    setProfileUpdateError({});
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleSave = (event) => {
    event.preventDefault();
    const form_data = new FormData();
    if (selectedFile) {
      form_data.append("profile_pic_image", selectedFile);
    }
    if (profileData?.username) {
      form_data.append("username", profileData.username);
    }
    dispatch(userProfileUpdateApi(form_data));
  };
  const modalBody = (
    <>
      <div>
        <input
          type="file"
          id="avatar_upload"
          ref={fileRef}
          onChange={handleFileChange}
        />
        {profileData?.profile_pic_url && <p>{profileData?.profile_pic_url}</p>}

        <p className="text-danger">{profileUpdateError?.profile_pic_image}</p>
      </div>
      <button
        type="button"
        className="btn btn-primary mt-3"
        onClick={handleSave}
        disabled={!isChange || userProfileUpdateLoading}
      >
        {userProfileUpdateLoading ? <Spin /> : "Submit"}
      </button>
    </>
  );

  return (
    <>
      <div className="text-center mb-5">
        <label
          className="avatar avatar-xxl profile-cover-avatar"
          htmlFor="avatar_upload"
        >
          <img
            className="avatar-img"
            src={
              profileData?.profile_pic_url ? profileData?.profile_pic_url : img2
            }
            alt="Profile Image"
            id="blah"
          />
          <span className="avatar-edit">
            <Link
              className="btn btn-primary"
              data-bs-toggle="modal"
              to="#profile_update_modal"
              role="button"
              onClick={handleOpenModal}
            >
              <FeatherIcon
                icon="edit"
                className="avatar-uploader-icon shadow-soft"
              />
            </Link>
          </span>
        </label>
        <h2>
          {profileData?.username}
          <i
            className="fas fa-certificate text-primary small"
            data-toggle="tooltip"
            data-placement="top"
            title=""
            data-original-title="Verified"
          />
        </h2>
      </div>
      <CreateModal
        id="profile_update_modal"
        modalRef={modalRef}
        show={isModalVisible}
        title="Update Image"
        modalbody={modalBody}
      />
    </>
  );
};
ProfileUpdate.propTypes = {
  profileData: PropTypes.shape({
    profile_pic_url: PropTypes.string,
    fileRef: PropTypes.object.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};
export default ProfileUpdate;
