import { img2, img3, img4, img5, img6, img7, img8, img9 } from "../../_components/Imagepath";

export default {
    Data: [
        {
          Id: 1,
          key:1,
          Name: "Glancewise",
          Email:"john@example.com",
          Img:img2,
          Requisition: "28 Sep 2022 16:43PM",
          Delete: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 2,
          key:2,
          Name: "Johnny",
          Email:"johnny@example.com",
          Img: img3,
          Requisition: "12 Dec 2022, 06:12 PM",
          Delete: "15 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 3,
          key:3,
          Name: "Robert",
          Email:"robert@example.com",
          Img: img4,
          Requisition: "04 Dec 2022, 12:38 PM",
          Delete: "08 Dec 2022, 12:38 PM",
          Actions: ""
        },
        {
          Id: 4,
          key:4,
          Name: "Sharonda",
          Email:"sharon@example.com",
          Img: img5,
          Requisition: "14 Dec 2022, 12:38 PM",
          Delete: "24 Dec 2022, 12:38 PM",
          Actions: ""
        },
        {
          Id: 5,
          key:5,
          Name: "Pricilla",
          Email:"pricilla@example.com",
          Img: img7,
          Requisition: "12 Dec 2022, 12:38 PM",
          Delete: "13 Dec 2022, 12:38 PM",
          Actions: ""
        },
        {
          Id: 6,
          key:6,
          Name: "Randall",
          Email:"randall@example.com",
          Img: img6,
          Requisition: "04 Dec 2022, 12:38 PM",
          Delete: "09 Dec 2022, 12:38 PM",
          Actions: ""
        },
        {
          Id: 7,
          key:7,
          Name: "Glancewise",
          Email:"john@example.com",
          Img: img7,
          Requisition: "28 Sep 2022 16:43PM",
          Delete: "19 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 8,
          key:8,
          Name: "Johnny",
          Email:"johnny@example.com",
          Img: img8,
          Requisition: "12 Dec 2022, 06:12 PM",
          Delete: "15 Dec 2022, 06:12 PM",
          Actions: ""
        },
        {
          Id: 9,
          key:9,
          Name: "Robert",
          Email:"robert@example.com",
          Img: img9,
          Requisition: "04 Dec 2022, 12:38 PM",
          Delete: "08 Dec 2022, 12:38 PM",
          Actions: ""
        }
    ]
}