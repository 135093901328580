import React, { Suspense, useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StickySidebar from "./layouts/StickySidebar";
import PrivateRoute from "./components/PrivateRoute";
import Customers from "./customers/Index";
import Register from "./authentication/register/Register";
import Login from "./authentication/Login";
import UserLogs from "./reports-component/reports/purchaseReport";
import ErrorBoundary from "./components/ErrorBoundary";
import FallBackUi from "./components/FallBackUi";
import Dashboard from "./main/dashboard/Index";
import RecurringInvoice from "./sales/invoices/recurring";
import AddCustomer from "./customers/add-customer";
import editInvoiceAP from "./customers/edit-invoice";
import editInvoiceAR from "./sales/invoices/edit-invoice";
import editRecurringInvoiceAR from "./sales/invoices/recurring/edit-recurring";
import DataProcessing from "./reports-component/reports/expenseReport";
import RolesPermission from "./user-management/rolePermission";
import Permission from "./user-management/rolePermission/permission";
import ChartofAccount from "./settings/chartsOfAccount/chartofaccount";
import CrmandErpConnect from "./settings/CrmandErpConnect/CrmndErp";
import CustomerList from "./settings/chartsOfAccount/customerlist";
import VendorList from "./settings/chartsOfAccount/vendorlist";
import MembershipPlan from "./membership";
import MembershipAddons from "./membership/membershipAddons";
import Subscribers from "./membership/subscribers";
import Users from "./user-management/manageUser/user";
import Transaction from "./membership/transaction";
import InvoiceList from "./sales/invoices/invoice-list";
import AddMembership from "./membership/addMembership";
import EmailTemplates from "./settings/email-templates";
import SeoSettings from "./settings/seosettings";
import SaasSettings from "./settings/saassettings";
import PaymentMethod from "./settings/PaymentMethod";
import BankSetting from "./settings/BankSetting";
import TaxRates from "./settings/tax-rates";
import PlanBilling from "./settings/plan-billing";
import TwoFactor from "./settings/two-factor";
import CustomField from "./settings/custom-field";
import Bank from "./Banking/bank";
import DeleteAccount from "./user-management/deleteAccount";
import Settings from "./settings/index";
import userManagement from "./settings/userManagement";
import SubscriptionSetting from "./settings/subscription";
import CompanySettings from "./settings/CompanySettings";
import InvoiceSetting from "./settings/InvoiveSetting";
import EmailSettings from "./settings/EmailSettings";
import Preferences from "./settings/Preferences";
import InvoiceTemplateSettings from "./settings/InvoiceTemplateSettings";
import ForgotPassword from "./authentication/forgot-password";
import Profile from "./pages/profile/Index";
import BankDetail from "./Banking/bankdetail";
import EditCustomer from "./settings/chartsOfAccount/customeredit";
import EditVendor from "./settings/chartsOfAccount/vendoredit";
import TransactionView from "./Banking/transactionView";
import addInvoice from "./sales/invoices/addApInvoice";
import AddInvoice from "./sales/invoices/AddInvoice";
import AddRecurring from "./sales/invoices/recurring/addrecurring";

const AppContainer = () => {
  const [showStickySidebar, setShowStickySidebar] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setShowStickySidebar(
      location.pathname !== "/invoice-one-a" &&
        location.pathname !== "/invoice-two" &&
        location.pathname !== "/invoice-three" &&
        location.pathname !== "/invoice-four-a" &&
        location.pathname !== "/invoice-five" &&
        location.pathname !== "/cashreceipt-1" &&
        location.pathname !== "/cashreceipt-2" &&
        location.pathname !== "/cashreceipt-3" &&
        location.pathname !== "/cashreceipt-4"
    );
  }, [location.pathname]);

  return (
    <Router basename="/">
      {showStickySidebar && <StickySidebar />}
      <Suspense fallback={<FallBackUi />}>
        <ErrorBoundary>
          <Switch>
            <PrivateRoute
              path="/index"
              component={Dashboard}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/apmodule"
              component={Customers}
              defaultPermissions={true}
            />
            <Route path="/" exact component={Login} defaultPermissions={true} />
            <Route
              path="/register"
              component={Register}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/add-customer"
              component={AddCustomer}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ap-module/add-invoice/"
              component={addInvoice}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ap-module/edit-invoice/:id"
              component={editInvoiceAP}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ap-module/transaction-view/:id"
              component={TransactionView}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ar-module/arModule-edit-invoice/:id"
              component={editInvoiceAR}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ar-module/arModule-add-invoice"
              component={AddInvoice}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/ar-module/recurring-edit-invoice/:id"
              component={editRecurringInvoiceAR}
              defaultPermissions={true}
            />
             <PrivateRoute
              path="/ar-module/recurring-add-invoice"
              component={AddRecurring}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/custom-field"
              component={CustomField}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/transactions"
              component={Transaction}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/users"
              component={Users}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/dataprocessing"
              component={DataProcessing}
              moduleName={"reports"}
            />
            <PrivateRoute
              path="/userlogs"
              component={UserLogs}
              moduleName={"reports"}
            />
            <PrivateRoute
              path="/add-membership"
              component={AddMembership}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/emailtemplates"
              component={EmailTemplates}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/recurring-invoices"
              component={RecurringInvoice}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/paymentmethod"
              component={PaymentMethod}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/banksetting"
              component={BankSetting}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/tax-rates"
              component={TaxRates}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/roles-permission"
              component={RolesPermission}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/permission"
              component={Permission}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/membership-plans"
              component={MembershipPlan}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/membership-addons"
              component={MembershipAddons}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/subscribers"
              component={Subscribers}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/invoice-list"
              component={InvoiceList}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/userlogs"
              component={UserLogs}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/seosettings"
              component={SeoSettings}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/sasssettings"
              component={SaasSettings}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/plan-pilling"
              component={PlanBilling}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/two-factor"
              component={TwoFactor}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/banking"
              component={Bank}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/delete-account-request"
              component={DeleteAccount}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/settings"
              component={Settings}
              moduleName={"settings"}
            />
            <PrivateRoute
              path="/company-settings"
              component={CompanySettings}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/invoice-settings"
              component={InvoiceSetting}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/email-settings"
              component={EmailSettings}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/preferences"
              component={Preferences}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/invoice-templatesettings"
              component={InvoiceTemplateSettings}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/bankdetail/:id"
              component={BankDetail}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/forgot-password"
              component={ForgotPassword}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/chartofaccount"
              component={ChartofAccount}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/crm-erp"
              component={CrmandErpConnect}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/customerlist"
              component={CustomerList}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/customeredit/:id"
              component={EditCustomer}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/vendoredit/:id"
              component={EditVendor}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/vendorlist"
              component={VendorList}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/user-management"
              component={userManagement}
              moduleName={"userModule"}
            />
            <PrivateRoute
              path="/subscription-setting"
              component={SubscriptionSetting}
              defaultPermissions={true}
            />
            <PrivateRoute
              path="/profile"
              component={Profile}
              defaultPermissions={true}
            />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </Router>
  );
};

export default AppContainer;