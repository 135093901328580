import { HTTP } from "../api";
import {
  CUSTOMERLISTSET_PAGE,
  CUSTOMER_ADD_FAIL,
  CUSTOMER_ADD_REQUEST,
  CUSTOMER_ADD_SUCCESS,
  CUSTOMER_EDIT_DETAIL_FAIL,
  CUSTOMER_EDIT_DETAIL_REQUEST,
  CUSTOMER_EDIT_DETAIL_SUCCESS,
  CUSTOMER_EDIT_FAIL,
  CUSTOMER_EDIT_REQUEST,
  CUSTOMER_EDIT_SUCCESS,
  GET_CUSTOMERLIST_FAIL,
  GET_CUSTOMERLIST_REQUSET,
  GET_CUSTOMERLIST_SUCCESS,
} from "../constants/CustomerModuleCnstant";

export const setPage = (page, perPage) => ({
  type: CUSTOMERLISTSET_PAGE,
  payload: page,
  perPage,
});

export const customerCreation = (payload) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMER_ADD_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.post("/customer/customer/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: CUSTOMER_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOMER_ADD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const getCustomerModuleList = (page = 1, perPage = 10) => async (
  dispatch,
  getState
) => {
  dispatch({ type: GET_CUSTOMERLIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(
      `/customer/customer/?page=${page}&per_page=${perPage}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_CUSTOMERLIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMERLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const customerEditDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: CUSTOMER_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.get(`/customer/customer/${id}`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: CUSTOMER_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOMER_EDIT_DETAIL_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};

export const updateCustomerDetail = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: CUSTOMER_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await HTTP.put(`/customer/customer/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: CUSTOMER_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CUSTOMER_EDIT_FAIL,
      payload:
        error.response && error?.response?.data
          ? error?.response?.data
          : error.message,
    });
  }
};
