import {
  GET_INVOICELIST_FAIL,
  GET_INVOICELIST_REQUSET,
  GET_INVOICELIST_SUCCESS,
  INVOICE_CREATE_REQUEST,
  INVOICE_CREATE_SUCCESS,
  INVOICE_CREATE_FAIL,
  INVOICE_EDIT_DETAIL_REQUEST,
  INVOICE_EDIT_DETAIL_SUCCESS,
  INVOICE_EDIT_DETAIL_FAIL,
  INVOICE_EDIT_REQUEST,
  INVOICE_EDIT_SUCCESS,
  INVOICE_EDIT_FAIL,
  GET_INVOICELIST_PAGE,
} from "../constants/ARmoduleConstant";
// import { HTTP } from "../api";
import axios from "axios";

export const setPage = (page, perPage) => ({
  type: GET_INVOICELIST_PAGE,
  payload: page,
  perPage,
});

export const getInvoiceList = (
  page = 1,
  perPage = 10,
  invoice_number,
  invoice_date,
  po_num,
  nature_of_supply
) => async (dispatch, getState) => {
  dispatch({ type: GET_INVOICELIST_REQUSET });
  const {
    userSignin: { userInfo },
  } = getState();

  try {
    const { data } = await axios.get(
      `https://api.tideiq.com/ar/invoices/?page=${page}&per_page=${perPage}&invoice_number=${invoice_number}&invoice_date=${invoice_date}&po_num=${po_num}&nature_of_supply=${nature_of_supply}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: GET_INVOICELIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: GET_INVOICELIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getInvoiceEditDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: INVOICE_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(`https://api.tideiq.com/ar/invoices/${id}/`, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: INVOICE_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: INVOICE_EDIT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addInvoice = (payload) => async (dispatch, getState) => {
  dispatch({ type: INVOICE_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post("https://api.tideiq.com/ar/invoices/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: INVOICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: INVOICE_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const updateInvoice = (payload, id) => async (dispatch, getState) => {
  dispatch({ type: INVOICE_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.patch(`https://api.tideiq.com/ar/invoices/${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: INVOICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: INVOICE_EDIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
