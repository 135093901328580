import { Spin } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import CustomInput from "../_components/inputField/custominput";
import CreateModal from "../_components/modalbox/createmodal";
import PropTypes from "prop-types";
import Select from "react-select";

const BankAddEditModal = ({
  id,
  modalRef,
  show,
  title,
  handleSubmit,
  addBankSubmit,
  bankData,
  handleOptionChange,
  addError,
  control,
  errors,
  handleChange,
  selectRefs,
  currencyTypes,
  handleSelectChange,
  handleCheckBoxChange,
  handleModalClose,
  loading,
}) => {
  const bankAddEdit = (
    <CreateModal
      id={id}
      modalRef={modalRef}
      show={show}
      title={title}
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <form onSubmit={handleSubmit(addBankSubmit)}>
                <div className="col-md-12">
                  <div className="form-group-item border-0 mb-0">
                    <div className="row align-item-center">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <label>
                            <input
                              type="radio"
                              name="payment"
                              value="Bank"
                              checked={bankData?.account_type === "Bank"}
                              onChange={(e) =>
                                id === "add_bank"
                                  ? handleOptionChange(e, "")
                                  : handleOptionChange(e, "edit")
                              }
                            />
                            &nbsp; Bank
                          </label>
                          &nbsp; &nbsp; &nbsp; &nbsp;
                          <label>
                            <input
                              type="radio"
                              name="payment"
                              value="CreditCard"
                              checked={bankData?.account_type === "CreditCard"}
                              onChange={(e) =>
                                id === "add_bank"
                                  ? handleOptionChange(e, "")
                                  : handleOptionChange(e, "edit")
                              }
                            />
                            &nbsp; Credit
                          </label>
                          {addError?.account_type && (
                            <p className="text-danger">
                              {addError?.account_type}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row align-item-center">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="account_name"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={
                                    id === "add_bank"
                                      ? "account_name"
                                      : "edit-account_name"
                                  }
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="Account Name"
                                  label="Account Name"
                                  errorMsg={errors?.account_name?.message}
                                  error={errors?.account_name}
                                  apiError={addError?.account_name}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="account_code"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={
                                    id === "add_bank"
                                      ? "account_code"
                                      : "edit-account_code"
                                  }
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="Account Code"
                                  label="Account Code"
                                  errorMsg={errors?.account_code?.message}
                                  error={errors?.account_code}
                                  apiError={addError?.account_code}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <label>
                            Currency <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name={"currency"}
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                ref={(el) =>
                                  id === "add_bank"
                                    ? (selectRefs.current["currency"] = el)
                                    : (selectRefs.current["edit-currency"] = el)
                                }
                                options={currencyTypes?.map((option) => ({
                                  value: option.id,
                                  label: option.name,
                                }))}
                                onChange={(selectedOption) =>
                                  handleSelectChange(
                                    "currency",
                                    field.onChange(selectedOption)
                                  )
                                }
                              />
                            )}
                          />

                          {errors?.currency?.message && (
                            <p className="text-danger">
                              {errors?.currency?.message}
                            </p>
                          )}
                          {addError?.currency && (
                            <p className="text-danger">{addError?.currency}</p>
                          )}
                        </div>
                      </div>
                      {bankData?.account_type !== "CreditCard" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <CustomInput
                              id={
                                id === "add_bank"
                                  ? "account_number"
                                  : "edit-account_number"
                              }
                              type="type"
                              value={bankData?.account_number}
                              onChange={handleChange}
                              placeholder="Account Number"
                              label="Account Number"
                              apiError={addError?.account_number}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <Controller
                            name="bank_name"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <CustomInput
                                  id={
                                    id === "add_bank"
                                      ? "bank_name"
                                      : "edit-bank_name"
                                  }
                                  type="text"
                                  value={value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    onChange(e);
                                  }}
                                  placeholder="Bank Name"
                                  label="Bank Name"
                                  errorMsg={errors?.bank_name?.message}
                                  error={errors?.bank_name}
                                  apiError={addError?.bank_name}
                                  autoComplete="false"
                                  required={true}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {bankData?.account_type !== "CreditCard" && (
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="input-block mb-3">
                            <CustomInput
                              id={
                                id === "add_bank"
                                  ? "ifsc_code"
                                  : "edit-ifsc_code"
                              }
                              type="type"
                              value={bankData?.ifsc_code}
                              onChange={handleChange}
                              placeholder="IFSC"
                              label="IFSC"
                              apiError={addError?.ifsc_code}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="input-block mb-3">
                          <CustomInput
                            id={
                              id === "add_bank"
                                ? "description"
                                : "edit-description"
                            }
                            type="type"
                            value={bankData?.description}
                            onChange={handleChange}
                            placeholder="Description"
                            label="Description"
                            apiError={addError?.description}
                          />
                        </div>
                      </div>
                      <div className="input-block mb-3">
                        <div className="col-md-10">
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                name="checkbox"
                                onChange={(e) =>
                                  id === "add_bank"
                                    ? handleCheckBoxChange(e, "")
                                    : handleCheckBoxChange(e, "edit")
                                }
                                checked={bankData?.is_primary}
                              />{" "}
                              Make this Primary
                            </label>
                            {addError?.is_primary && (
                              <p className="text-danger">
                                {addError?.is_primary}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    type="reset"
                    className="btn btn-primary cancel me-2"
                    onClick={() => {
                      id === "add_bank"
                        ? handleModalClose("#add_bank")
                        : handleModalClose("#edit_bank");
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? <Spin /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      }
    />
  );

  return <>{bankAddEdit}</>;
};

BankAddEditModal.propTypes = {
  id: PropTypes.string.isRequired,
  modalRef: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  addBankSubmit: PropTypes.func.isRequired,
  bankData: PropTypes.object.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  addError: PropTypes.object,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectRefs: PropTypes.object.isRequired,
  currencyTypes: PropTypes.array.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleCheckBoxChange: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default BankAddEditModal;
