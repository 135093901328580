/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import DeleteModal from "../_components/modalbox/deletemodal";

const UserManageDeleteModal = ({
  show,
  deleteData,
  handleDeleteSubmit,
  teamMemberDeleteLoading,
  teamMemberDeleteError,
}) => {
  const deleteUser=(
    <DeleteModal
    id="delete_user"
    show={show}
    title="Delete User"
    deleteData={`${deleteData?.info?.first_name} ${deleteData?.info?.last_name}` }
    errorMsg={teamMemberDeleteError}
    handleSubmit={handleDeleteSubmit}
    deleteLoading={teamMemberDeleteLoading}
    />
  )
  return (
    <>{deleteUser}</>
  );
};

UserManageDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  deleteData: PropTypes.object.isRequired,
  handleDeleteSubmit: PropTypes.func.isRequired,
  teamMemberDeleteLoading: PropTypes.bool,
  teamMemberDeleteError: PropTypes.string.isRequired,
};

export default UserManageDeleteModal;
