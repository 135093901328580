/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import AllTransaction from "./alltransaction";
import BankStatement from "./bankstatement";
import Uncategorized from "./uncategorized";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BankDetail = () => {
  const [menu, setMenu] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [page, setPage] = useState({ page: 1, perPage: 10 });

  const location = useLocation();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  $(document).ready(function() {
    $('a[data-bs-toggle="tab"]').on("shown.bs.tab", function(e) {
      var targetTab = $(e.target).attr("href");
      localStorage.setItem("currentTransactionTab", targetTab);
      setActiveTab(targetTab);
    });

    var lastTab = localStorage.getItem("currentTransactionTab");
    if (lastTab) {
      $('a[href="' + lastTab + '"]').tab("show");
      setActiveTab(lastTab);
    }
  });

  useEffect(() => {
    let previouslyStoredTab = localStorage.getItem("currentTransactionTab");
    if (location?.state && location?.state?.tabName) {
      setActiveTab(`#${location?.state?.tabName}`);
      localStorage.setItem(
        "currentTransactionTab",
        `#${location?.state?.tabName}`
      );
      history?.replace({ state: null });
    } else {
      previouslyStoredTab
        ? setActiveTab(previouslyStoredTab)
        : setActiveTab("#alltransanction");
    }
  }, [location?.state]);

  useEffect(() => {
    setPage({ page: 1, perPage: 10 });
  }, [activeTab]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="content-page-header">
              <div className="col">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/banking">Banking</Link>
                </li>
                <li className="breadcrumb-item active">Account Detaill</li>
              </ul>
            </div>
            </div>

            <div className="invoices-tabs-card">
              <div className="invoices-main-tabs">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="invoices-tabs">
                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <Link
                            className="nav-link active"
                            to="#alltransanction"
                            data-bs-toggle="tab"
                          >
                            All Transaction
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#uncategorized"
                            data-bs-toggle="tab"
                          >
                            Uncategorized Transaction
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="#bankstatement"
                            data-bs-toggle="tab"
                          >
                            Bank Statement
                          </Link>
                        </li>
                      </ul>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="alltransanction"
                        >
                          <AllTransaction
                            activeTab={activeTab}
                            page={page?.page}
                            perPage={page?.perPage}
                            setPage={setPage}
                          />
                        </div>
                        <div className="tab-pane" id="uncategorized">
                          <Uncategorized
                            activeTab={activeTab}
                            page={page?.page}
                            perPage={page?.perPage}
                            setPage={setPage}
                          />
                        </div>
                        <div className="tab-pane" id="bankstatement">
                          <BankStatement
                            activeTab={activeTab}
                            page={page?.page}
                            perPage={page?.perPage}
                            setPage={setPage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetail;
