/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import { VENDOR_EDIT_RESET } from "../../redux/constants/VendorConstant";
import {
  updateVendorDetail,
  vendorEditDetail,
} from "../../redux/actions/VendorAction";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { commonVendorFieldOrder, vendorFieldOrder } from ".";

import AddEditVendorModal from "./AddEditVendor";
import { formatDateYYMMDD } from "../../sales/invoices/constant";

const schema = yup.object({
  name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  state_code: yup
    .string()
    .required("Please fill this field")
    .trim(),
  email: yup
    .string()
    .required("Please fill this field")
    .trim(),
  state: yup
    .string()
    .required("Please fill this field")
    .trim(),
  address: yup
    .string()
    .required("Please fill this field")
    .trim(),
  pan_number: yup
    .string()
    .required("Please fill this field")
    .trim(),

  contact_name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  gstin_number: yup
    .string()
    .required("Please fill this field")
    .trim(),

  phone: yup
    .string()
    .required("Please fill this field")
    .trim(),
  zip_code: yup
    .string()
    .required("Please fill this field")
    .trim(),

  branch_name: yup
    .string()
    .required("Please fill this field")
    .trim(),
});

const EditVendor = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const vendorEditData = useSelector((state) => state.vendorEditDetail);
  const {
    vendorEditDetail: vendorDetail,
    loading: vendorDetailLoading,
  } = vendorEditData;
  const vendorDetailUpdate = useSelector((state) => state.vendorEdit);
  const { loading, success, error } = vendorDetailUpdate;

  const [menu, setMenu] = useState(false);
  const [apiError, setApiError] = useState({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setApiError(error);
  }, [error]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const handleDateChange = (id, selectedDate) => {
    setEditData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
    setIsChanged(true);
    setApiError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: editData,
  });

  useEffect(() => {
    if (editData) {
      Object.keys(editData).forEach((field) => {
        setValue(field, editData[field]);
      });
    }
  }, [editData, setValue]);

  useEffect(() => {
    const firstErrorField = vendorFieldOrder.find(
      (field) => errors && errors[field]
    );
    if (firstErrorField) {
      const inputElement = document.getElementById(firstErrorField);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const commonErrorField = commonVendorFieldOrder.find(
      (field) => apiError && apiError[field]
    );
    if (commonErrorField) {
      const commonInputElement = document.getElementById(commonErrorField);
      if (commonInputElement) {
        commonInputElement.focus();
        setIsChanged(false);
      }
    }
  }, [apiError]);

  const handleFileChange = (event) => {
    setFile(event.target.files);
    setApiError((prevState) => ({ ...prevState, file: "" }));
  };

  const handleEditSubmit = (data) => {
    const form_data = new FormData();
    if (file) {
      form_data.append("tds_certificate", file[0]);
    }
    const postData = {
      name: data?.name ? data?.name : "",
      vendor_code: data?.vendor_code ? data?.vendor_code : null,
      address: data?.address ? data?.address : "",
      zip_code: data?.zip_code ? data?.zip_code : "",
      pan_number: data?.pan_number ? data?.pan_number : null,
      gstin_number: data?.gstin_number ? data?.gstin_number : "",
      state: data?.state ? data?.state : "",
      state_code: data?.state_code ? data?.state_code : "",
      phone: data?.phone ? data?.phone : null,
      contact_name: data?.contact_name ? data?.contact_name : null,
      email: data?.email ? data?.email : null,
      msme: data?.msme ? data?.msme : null,
      bank_name: data?.bank_name ? data?.bank_name : null,
      branch_name: data?.branch_name ? data?.branch_name : null,
      account_number: data?.account_number ? data?.account_number : "",
      ifsc_code: data?.ifsc_code ? data?.ifsc_code : "",
      swift: data?.swift ? data?.swift : "",
      approved_amount: data?.approved_amount ? data?.approved_amount : "",
      tds_certificate_number: data?.tds_certificate_number
        ? data?.tds_certificate_number
        : "",
      is_tds_available: true,
    };
    if (data?.start_date) {
      postData.start_date = formatDateYYMMDD(data.start_date);
    }
    if (data?.end_date) {
      postData.end_date = formatDateYYMMDD(data.end_date);
    }
    // Append postData properties to form_data
    for (const key in postData) {
      if (postData.hasOwnProperty(key)) {
        form_data.append(key, postData[key]);
      }
    }
    // Dispatch the addVendor action with the form_data
    dispatch(updateVendorDetail(form_data, id));
    setIsChanged(false);
  };

  useEffect(() => {
    dispatch(vendorEditDetail(id));
  }, [id]);

  useEffect(() => {
    if (success) {
      dispatch({ type: VENDOR_EDIT_RESET });
      dispatch(vendorEditDetail(id));
      messageApi.open({
        type: "success",
        content: "Vendor Detail updated successfully",
      });
    }
  }, [success]);

  useEffect(() => {
    if (vendorDetail) {
      setEditData({
        ...vendorDetail,
        start_date: vendorDetail?.start_date
          ? parseDate(vendorDetail?.start_date)
          : "",
        end_date: vendorDetail?.end_date
          ? parseDate(vendorDetail?.end_date)
          : "",
      });
    }
  }, [vendorDetail]);
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <div className="col-md-6">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/chartofaccount">Chart of Accounts</Link>
                        </li>
                        <li className="breadcrumb-item active">
                          <a>Edit Vendor</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Page Header */}
                {vendorDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <AddEditVendorModal
                        handleSubmit={handleSubmit}
                        handleEditSubmit={handleEditSubmit}
                        control={control}
                        handleChange={handleChange}
                        errors={errors}
                        apiError={apiError}
                        setApiError={setApiError}
                        editData={editData}
                        handleDateChange={handleDateChange}
                        handleFileChange={handleFileChange}
                        isChanged={isChanged}
                        loading={loading}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditVendor;
