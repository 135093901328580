/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

import "react-phone-input-2/lib/style.css";
import {
  apEditInvoiceDetail,
  getTdsRateList,
  updateApInvoice,
} from "../redux/actions/APmoduleAction";
import { useSelector } from "react-redux";
import { AP_INVOICE_EDIT_RESET } from "../redux/constants/APmoduleConstant";
import { message, Spin } from "antd";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Controller, useForm } from "react-hook-form";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  getLedgerModuleList,
  postLedgerModuleList,
} from "../redux/actions/LedgerModuleAction";
import { getTdsSectionList } from "../redux/actions/commonApiAction";
import CustomInput from "../_components/inputField/custominput";
import { formatDateYYMMDD } from "../sales/invoices/constant";
import TableEditInvoice from "./tableEditInvoice";
import useUpdateFooterData from "./updateFooterData";
import useCalculateTotals from "../helpers/calculation";

const schema = yup.object({
  invoice_number: yup
    .string()
    .required("Invoice number is required")
    .trim(),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const ledgerSchema = yup.object({
  title: yup
    .string()
    .required("Title is required")
    .trim(),
  type: yup
    .string()
    .required("Type is required")
    .trim(),
});

const editInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const invoiceEditDetailInvoice = useSelector(
    (state) => state.apInvoiceEditDetail
  );
  const {
    apInvoiceEditDetail,
    loading: editDetailLoading,
  } = invoiceEditDetailInvoice;

  const apModuleInvoiceEdit = useSelector((state) => state.apInvoiceEdit);
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const tdsSectionList = useSelector((state) => state?.tdsSectionList);
  const { loading: tdsSectionListLoading, tdsSectionData } = tdsSectionList;

  const { success, loading, error } = apModuleInvoiceEdit;

  const tdsCalculation = useSelector((state) => state.tdsCalculation);
  const {
    tdsAmount,
    loading: tdsCalculationLoading,
    error: tdsCalculationError,
  } = tdsCalculation;

  const [menu, setMenu] = useState();
  const [editData, setEditData] = useState({});
  const [editError, setEditError] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const [itemId, setItemId] = useState("");

  const [data, setData] = useState([]);
  const [footerData, setFooterData] = useState();
  const [gstSection, setGstSection] = useState([]);
  const [tdsData, setTdsData] = useState({});
  const [editable, setEditable] = useState({});
  const [editTdstable, setEditTdsTable] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const [isChanged, setIsChanged] = useState(false);

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(footerData, setFooterData, totals, gstSection);

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const handleFooterChange = (field, value) => {
    setFooterData((prevFooterData) => ({
      ...prevFooterData,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const renderFooterCell = (field, value) => {
    if (editable.rowId === "footer" && editable.field === field) {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => handleFooterChange(field, e.target.value)}
          onBlur={() => setEditable({})}
          autoFocus
        />
      );
    }
    return (
      <input
        type="text"
        value={value}
        readOnly
        onDoubleClick={() => setEditable({ rowId: "footer", field })}
      />
    );
  };

  const renderJournalCell = (field, value) => {
    // Check if the field is editable
    if (editable.field === field) {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => {
            handleJournalChange(field, e.target.value);
          }}
          onBlur={() => {
            setEditable({ field: null });
          }}
          autoFocus
        />
      );
    }

    if (field === "expense_ledger.title") {
      const expenseLedgerTitle = value || "";

      return (
        <input
          type="text"
          value={expenseLedgerTitle}
          readOnly
          onDoubleClick={() => {
            handleJournalDoubleClick(field);
          }}
          onClick={() => {
            setEditable({ field }); // Set the field to editable on click
          }}
        />
      );
    }

    // Default case for non-editable fields

    return (
      <input
        type="text"
        value={value || ""}
        readOnly
        onDoubleClick={() => {
          handleJournalDoubleClick(field);
        }}
        onClick={() => {
          setEditable({ field }); // Set the field to editable on click
        }}
      />
    );
  };

  const handleJournalChange = (field, value) => {
    setInvoiceData((prevData) => {
      // Clone the previous state
      const newInvoiceData = { ...prevData };

      // Split the field by dots to handle nested properties
      const fieldParts = field.split(".");

      // Navigate through the nested structure and update the target field
      let nestedObject = newInvoiceData;
      for (let i = 0; i < fieldParts.length; i++) {
        const key = fieldParts[i];
        if (i === fieldParts.length - 1) {
          // Update the value of the nested field
          nestedObject[key] = value;
        } else {
          // Clone the nested object to maintain immutability
          nestedObject[key] = { ...nestedObject[key] };
          // Move deeper into the nested structure
          nestedObject = nestedObject[key];
        }
      }

      return newInvoiceData;
    });

    // Optionally, set a flag indicating that the data has been changed
    setIsChanged(true);
  };

  const handleJournalDoubleClick = (field) => {
    setEditable({ field });
  };

  const handleSelectChange = (selectedOption, id) => {
    setEditData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
    if (id === "tds_rate") {
      setEditError((prevState) => ({ ...prevState, detail: "" }));
    }
    setIsChanged(true);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  useEffect(() => {
    dispatch(apEditInvoiceDetail(id));
    dispatch(getTdsRateList());
  }, [id]);

  useEffect(() => {
    if (apInvoiceEditDetail?.items?.length) {
      const updatedItemsList = apInvoiceEditDetail?.items?.map((each) => ({
        ...each,
        posting_ledger: each?.posting_ledger?.id,
        amount:
          each?.quantity && each?.rate
            ? (parseFloat(each?.quantity) * parseFloat(each?.rate)).toFixed(2)
            : "",

        gst_amount:
          each?.amount && each?.gst_rate
            ? (
                (parseFloat(each?.amount) * parseFloat(each?.gst_rate)) /
                100
              ).toFixed(2)
            : "",

        total:
          each?.gst_amount && each?.amount
            ? (parseFloat(each?.gst_amount) + parseFloat(each?.amount)).toFixed(
                2
              )
            : "",
        tds_ledger: {
          value: each?.tds_ledger?.id,
          label: each?.tds_ledger?.section_name,
        },
      }));
      setData(updatedItemsList || []);
    }
    setGstSection(
      apInvoiceEditDetail?.gst_section
        ? Object?.keys(apInvoiceEditDetail?.gst_section)
        : []
    );
    setFooterData(apInvoiceEditDetail);
    setInvoiceData(apInvoiceEditDetail || {});
    setTdsData(apInvoiceEditDetail?.tds_section || {});
  }, [apInvoiceEditDetail]);

  useEffect(() => {
    setEditError(error);
  }, [error]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: isModalVisible ? yupResolver(ledgerSchema) : yupResolver(schema),
    defaultValues: isModalVisible ? addLedgerData : editData,
  });

  useEffect(() => {
    const fieldOrder = [
      "vendor_name",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
    ];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    if (firstErrorField) {
      let inputElement;

      inputElement = document.getElementById(firstErrorField);

      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    if (editData) {
      Object.keys(editData).forEach((field) => {
        setValue(field, editData[field]);
      });
    }
  }, [editData, setValue]);

  useEffect(() => {
    const fieldOrder = [
      "vendor_name",
      "vendor_code",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
      "detail",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "detail") {
        inputElement = selectRefs.current["tds_rate"];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const handleEditSubmit = (datas) => {
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        type: addLedgerData?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    } else {
      const ledger = data?.map((ele) => ({
        ...ele,
        posting_ledger: ele?.posting_ledger,
        tds_ledger: ele?.tds_ledger?.value,
        // tds_amount:ele?.tds_amount?parseFloat(ele?.tds_amount):"",
        gst_amount: ele?.gst_amount ? parseFloat(ele?.gst_amount) : "0.00",
        quantity: ele?.quantity ? ele?.quantity : null,
        rate: ele?.rate ? ele?.rate : null,
        amount: ele?.amount ? ele?.amount : null,
        gst_rate: ele?.gst_rate ? ele?.gst_rate : null,
        total: ele?.total ? ele?.total : null,
        tds_amount: ele?.tds_amount ? ele?.tds_amount : null,
      }));

      const postData = {
        vendor_name: datas?.vendor_name,
        vendor_code: datas?.vendor_code,
        vendor_gstin: datas?.vendor_gstin,
        invoice_number: datas?.invoice_number ? datas?.invoice_number : null,
        invoice_date: datas?.invoice_date
          ? formatDateYYMMDD(datas?.invoice_date)
          : null,
        expense_ledger: datas?.expense_ledger?.value,
        nature_of_supply: datas?.nature_of_supply
          ? datas?.nature_of_supply
          : "",
        items: ledger,
        tds_rate: editData?.tds_rate ? editData?.tds_rate?.value : "",
      };
      dispatch(updateApInvoice(postData, id));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: AP_INVOICE_EDIT_RESET });
      setItemId("");
      dispatch(apEditInvoiceDetail(id));
      messageApi.open({
        type: "success",
        content: "invoice updated successfully",
      });
    }
  }, [success]);

  useEffect(() => {
    if (tdsAmount) {
      const updatedData = [...data];
      if (updatedData[itemId]) {
        updatedData[itemId]["tds_amount"] = tdsAmount?.tds_amount;
        setData(updatedData);
      }
    }
  }, [itemId, tdsAmount]);

  useEffect(() => {
    if (apInvoiceEditDetail) {
      setEditData({
        ...apInvoiceEditDetail,
        invoice_date: parseDate(apInvoiceEditDetail?.invoice_date)
          ? parseDate(apInvoiceEditDetail?.invoice_date)
          : "",
        expense_ledger: {
          value: apInvoiceEditDetail?.expense_ledger?.id,
          label: apInvoiceEditDetail?.expense_ledger?.title,
        },
        vendor_name: apInvoiceEditDetail?.vendor?.name,
        vendor_code: apInvoiceEditDetail?.vendor?.vendor_code,
        vendor_gstin: apInvoiceEditDetail?.vendor?.gstin_number,
        tds_rate:
          apInvoiceEditDetail?.tds_rate?.section_id &&
          apInvoiceEditDetail?.tds_rate?.rate
            ? {
                label: `${apInvoiceEditDetail?.tds_rate?.section_id} - ${apInvoiceEditDetail?.tds_rate?.rate} `,
                value: apInvoiceEditDetail?.tds_rate?.id,
              }
            : null,
      });
    }
  }, [invoiceEditDetailInvoice?.apInvoiceEditDetail]);

  useEffect(() => {
    dispatch(getLedgerModuleList("", "", true, ""));
    dispatch(getTdsSectionList());
  }, [dispatch]);

  const handleDateChange = (id) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const renderTdsCell = (field, value) => {
    if (editTdstable === field) {
      return (
        <input
          style={{ cursor: "pointer" }}
          type="text"
          value={value}
          onChange={(e) => handleTdsCellChange(field, e.target.value)}
          onBlur={() => setEditTdsTable(null)}
          autoFocus
        />
      );
    }
    return (
      <input
        type="text"
        value={value}
        readOnly
        onDoubleClick={() => handleTdsDoubleClick(field)}
      />
    );
  };

  const handleTdsCellChange = (field, value) => {
    setTdsData((prevTdsData) => ({
      ...prevTdsData,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const handleTdsDoubleClick = (field) => {
    setEditTdsTable(field);
  };
  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setAddLedgerData({
        title: "",
        type: "",
        description: "",
      });
      setApiError({});
    });
  };
  useEffect(() => {
    onModalClose();
  }, []);
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/apmodule">AP module</Link>
                      </li>
                      <li className="breadcrumb-item active">Invoice Edit</li>
                    </ul>
                  </div>
                </div>
                {/* /Page Header */}
                {editDetailLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit(handleEditSubmit)}>
                        <div className="">
                          <div className="">
                            <div className="row">
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="vendor_name"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <>
                                        <CustomInput
                                          id="vendor_name"
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            onChange(e);
                                          }}
                                          placeholder="Vendor Name"
                                          label="Vendor Name"
                                          errorMsg={
                                            errors?.vendor_name?.message
                                          }
                                          error={errors?.vendor_name}
                                          apiError={editError?.vendor_name}
                                          autoComplete="false"
                                          disabled={true}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <CustomInput
                                    id="vendor_code"
                                    type="text"
                                    value={editData?.vendor_code}
                                    onChange={handleCommonChange}
                                    placeholder="Vendor Code"
                                    label="Vendor Code"
                                    apiError={editError?.vendor_code}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="vendor_gstin"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <>
                                        <CustomInput
                                          id="vendor_gstin"
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            onChange(e);
                                          }}
                                          placeholder="Vendor GSTIN"
                                          label="Vendor GSTIN"
                                          errorMsg={
                                            errors?.vendor_gstin?.message
                                          }
                                          error={errors?.vendor_gstin}
                                          apiError={editError?.vendor_gstin}
                                          autoComplete="false"
                                          disabled={true}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="invoice_number"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <>
                                        <CustomInput
                                          id="invoice_number"
                                          type="text"
                                          value={value}
                                          onChange={(e) => {
                                            handleChange(e);
                                            onChange(e);
                                          }}
                                          placeholder="Invoice Number"
                                          label="Invoice Number"
                                          errorMsg={
                                            errors?.invoice_number?.message
                                          }
                                          error={errors?.invoice_number}
                                          apiError={editError?.invoice_number}
                                          autoComplete="false"
                                          required={true}
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <label>
                                    Invoice Date
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="cal-icon cal-icon-info">
                                    <Controller
                                      name="invoice_date"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="cal-icon cal-icon-info">
                                          <DatePicker
                                            {...field}
                                            className="datetimepicker form-control"
                                            selected={field?.value}
                                            onChange={(date) =>
                                              handleDateChange(
                                                "invoice_date",
                                                date,
                                                field?.onChange(date)
                                              )
                                            }
                                            id="invoice_date"
                                            ref={datePickerRef}
                                          />
                                        </div>
                                      )}
                                    />
                                    {errors?.invoice_date?.message && (
                                      <p className="text-danger">
                                        {errors?.invoice_date?.message}
                                      </p>
                                    )}
                                    {editError?.invoice_date && (
                                      <p className="text-danger">
                                        {editError?.invoice_date}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="input-block mb-3">
                                  <Controller
                                    name="nature_of_supply"
                                    control={control}
                                    render={({
                                      field: { value, onChange },
                                    }) => (
                                      <CustomInput
                                        id="nature_of_supply"
                                        type="text"
                                        value={value}
                                        onChange={(e) => {
                                          handleChange(e);
                                          onChange(e);
                                        }}
                                        placeholder="Nature of Supply"
                                        label="Nature of Supply"
                                        errorMsg={
                                          errors?.nature_of_supply?.message
                                        }
                                        error={errors?.nature_of_supply}
                                        apiError={editError?.nature_of_supply}
                                        autoComplete="false"
                                        required={true}
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="complex-invoice-table">
                          <div
                            className="ag-theme-alpine"
                            style={{ width: "100%" }}
                          >
                            <h6>Invoice Intent</h6>
                            <TableEditInvoice
                              setData={setData}
                              data={data}
                              expenseList={expenseList}
                              setItemId={setItemId}
                              setIsChanged={setIsChanged}
                              setEditable={setEditable}
                              editable={editable}
                              footerData={footerData}
                              tdsSectionData={tdsSectionData}
                              addLedgerData={addLedgerData}
                              setAddLedgerData={setAddLedgerData}
                              handleSubmit={handleSubmit}
                              handleEditSubmit={handleEditSubmit}
                              isModalVisible={isModalVisible}
                              setIsModalVisible={setIsModalVisible}
                              control={control}
                              errors={errors}
                              handleModalClose={handleModalClose}
                              setApiError={setApiError}
                              apiError={apiError}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!isChanged || loading}
                            >
                              {loading ? <Spin /> : "Save"}
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-primary cancel me-2"
                              onClick={() => history.push("/apmodule")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default editInvoice;
