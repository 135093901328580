/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getRecurringInvoiceList, recurringInvoiceCreate } from "../../../redux/actions/RecurringAction";
import { formatDateYYMMDD } from "../constant";
import AddEditRecurringModal from "./addEditRecurringModal";
import { getLedgerModuleList, postLedgerModuleList } from "../../../redux/actions/LedgerModuleAction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RECURRINGINVOICE_CREATE_RESET } from "../../../redux/constants/RecurringInvoiceConstant";
import { message } from "antd";
import { POST_LEDGERLIST_RESET } from "../../../redux/constants/ledgerListModuleConstant";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { useSelector } from "react-redux";
import { getCustomerList } from "../../../redux/actions/commonApiAction";

const schema = yup.object({
  start_date: yup
    .date()
    .required("Start date is required")
    .nullable()
    .required("State date is required"),
  end_date: yup
    .date()
    .required("End date is required")
    .nullable()
    .required("End date is required"),

  customer: yup
    .object()
    .shape({
      value: yup.number().required("Customer is required"),
    })
    .nullable()
    .required("Company is required"),
});


const AddRecurring = () => {
  const [menu, setMenu] = useState(false);
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const selectRefs = useRef({});
  const datePickerRef = {
    start_date: useRef(null),
    end_date: useRef(null),
    invoice_date: useRef(null),
  };
  const history = useHistory();

  const [recurringInvoiceData, setRecurringInvoiceData] = useState({
    start_date: null,
    end_date: null,
    interval: null,
    invoice_number: "",
    invoice_date: null,
    day: null,
    billing_period: "",
    tax_ledger: "",
    customer: null,
  });
  const [ledgerData, setLedgerData] = useState([
    {
      description: "",
      quantity: "",
      rate: "",
      gst_rate: "",
      gst_amount: "",
      amount: "",
      total: "",
      taxes: [],
    },
  ]);
  const [addError, setAddError] = useState({});
  const [itemsError, setItemsError] = useState([
    {
      description: "",
      quantity: "",
      rate: "",
      tax_rate: "",
      amount: "",
      product_code: "",
    },
  ]);
  const [customerDetail, setCustomerDetail] = useState({});
  const [editable, setEditable] = useState({});
  const [data, setData] = useState([
    {
      ledger: null,
      description: "",
      quantity: "",
      rate: "",
      amount: "",
      gst_rate: "",
      gst_amount: "",
      total: "",
    },
  ]);
  const [footerData, setFooterData] = useState({});
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [preventApiCall, setPreventApiCall] = useState(false);
  const [calculationData, setCalculationData] = useState({});
  const [show, setShow] = useState(false);

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
  } = postLedgerList;
  const createRecurringInvoice = useSelector(
    (state) => state.addRecurringInvoice
  );
  const { success, loading, error } = createRecurringInvoice;
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: !isModalVisible && yupResolver(schema),
    defaultValues: recurringInvoiceData,
  });

  useEffect(() => {
    const fieldOrder = [
      "start_date",
      "end_date",
      "customer",
      // "company",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "start_date",
      "end_date",
      "customer",
      // "company",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "customer") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      dispatch({ type: RECURRINGINVOICE_CREATE_RESET });
      reset();
      setRecurringInvoiceData({
        start_date: "",
        end_date: "",
        interval: "",
        invoice_number: "",
        invoice_date: "",
        day: "",
        billing_period: "",
        tax_ledger: "",
        customer: "",
      });
      setCustomerDetail({});
      setLedgerData([
        {
          description: "",
          quantity: "",
          rate: "",
          gst_rate: "",
          gst_amount: "",
          amount: "",
          total: "",
          taxes: [],
        },
      ]);
      // dispatch(
      //   getRecurringInvoiceList(page, perPage, "", "", "", "", "", "", "")
      // );
      history.push("/recurring-invoices");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Recurring Invoice created successfully",
      });
    }
  }, [success]);

  useEffect(() => {
    dispatch(getLedgerModuleList("", "", true, ""));
  }, [dispatch]);
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  useEffect(()=>{
    dispatch(getCustomerList());
  },[dispatch])

  useEffect(() => {
    if (recurringInvoiceData?.customer?.value) {
      const customerDetails = customer?.find(
        (el) => el?.id === recurringInvoiceData?.customer?.value
      );
      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, recurringInvoiceData?.customer])

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const [scheduleData, setScheduleData] = useState({
    schedule_type: null,
    quarterly: {
      month_of_quarter: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      repeat_every: "", // Every month
    },
    dayOfMonth: true,
  });

  const [editData, setEditData] = useState({
    schedule_type: null,
    quarterly: {
      month_of_quarter: "", // 1st month of the quarter

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every quarter
    },
    weekly: {
      days_of_week: [], // 1 = Monday, 5 = Friday

      repeat_every: "", // Every week
    },
    yearly: {
      month_of_year: "", // January

      day_of_month: "", // 15th day of the month

      repeat_every: "", // Every year
    },
    monthly: {
      repeat_every: "", // Every month
    },
    dayOfMonth: true,
  });

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleSelectCellChange = (
    selectedOption,
    id,
    rowKey,
    index
  ) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;
    if (id === "tds_ledger") {
      setData(updatedData);
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setData(updatedData);
  };

  const onModalOpen = () => {
    $("#addrecurring_invoice").on("shown.bs.modal", () => {
      setPreventApiCall(true);
    });
    $("#add_ledger").on("hide.bs.modal", () => {
      setApiError({})
      setIsModalVisible(true);
      setAddLedgerData({ title: "", type: "", description: "" });
    });
  };

  const onModalClose = () => {
    $("#addrecurring_invoice").on("hide.bs.modal", () => {
      setRecurringInvoiceData({
        start_date: null,
        end_date: null,
        interval: null,
        invoice_number: "",
        invoice_date: null,
        day: null,
        billing_period: "",
        tax_ledger: "",
        customer: null,
        nature_of_invoice: "",
      });
      setData([
        {
          ledger: null,
          description: "",
          quantity: "",
          rate: "",
          amount: "",
          gst_rate: "",
          gst_amount: "",
          total: "",
        },
      ]);
      setScheduleData({
        schedule_type: null,
        quarterly: {
          month_of_quarter: "", // 1st month of the quarter

          day_of_month: "", // 15th day of the month

          repeat_every: "", // Every quarter
        },
        weekly: {
          days_of_week: [], // 1 = Monday, 5 = Friday

          repeat_every: "", // Every week
        },
        yearly: {
          month_of_year: "", // January

          day_of_month: "", // 15th day of the month

          repeat_every: "", // Every year
        },
        monthly: {
          repeat_every: "", // Every month
        },
        dayOfMonth: true,
      });
      setEditData({
        schedule_type: null,
        quarterly: {
          month_of_quarter: "", // 1st month of the quarter

          day_of_month: "", // 15th day of the month

          repeat_every: "", // Every quarter
        },
        weekly: {
          days_of_week: [], // 1 = Monday, 5 = Friday
          repeat_every: "", // Every week
        },
        yearly: {
          month_of_year: "", // January

          day_of_month: "", // 15th day of the month

          repeat_every: "", // Every year
        },
        monthly: {
          repeat_every: "", // Every month
        },
        dayOfMonth: true,
      });
      setLedgerData([
        {
          description: "",
          quantity: "",
          rate: "",
          gst_rate: "",
          gst_amount: "",
          amount: "",
          total: "",
          taxes: [],
        },
      ]);
      reset();
      setCustomerDetail({});
      dispatch({ type: RECURRINGINVOICE_CREATE_RESET });
    });
    $("#add_ledger").on("hide.bs.modal", () => {
      setApiError({})
      setIsModalVisible(false);
      setAddLedgerData({ title: "", type: "", description: "" });
    });
  };

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      handleModalClose("#add_ledger");
      setAddLedgerData({});
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
      });
    }
  }, [postLedgerSuccess]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setPreventApiCall(false);
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
  };

  useEffect(()=>{
    onModalClose()
    onModalOpen()
  },[])

  const handleCheckBox = (e, selectedOption) => {
    const { checked } = e.target;
    if (checked) {
      const updatedData = { ...scheduleData };
      updatedData["weekly"] &&
        updatedData["weekly"]["days_of_week"]?.push(selectedOption);
      setScheduleData(updatedData);
      const edit_data = { ...editData };
      edit_data["weekly"] &&
        edit_data["weekly"]["days_of_week"]?.push(selectedOption);
      setEditData(edit_data);
    } else {
      setScheduleData((prevState) => ({
        ...prevState,
        weekly: {
          ...scheduleData?.weekly,
          days_of_week: scheduleData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
      setEditData((prevState) => ({
        ...prevState,
        weekly: {
          ...editData?.weekly,
          days_of_week: editData?.weekly?.days_of_week?.filter(
            (el) => el !== selectedOption
          ),
        },
      }));
    }
  };

  const handleRepeatSelectChange = (selectedOption, scheduleType, id) => {
    setScheduleData((prevState) => ({
      ...prevState,
      [scheduleType]: { ...scheduleData[scheduleType], [id]: selectedOption },
    }));
    setEditData((prevState) => ({
      ...prevState,
      [scheduleType]: {
        ...editData[scheduleType],
        [id]: selectedOption?.value,
      },
    }));
  };

  useEffect(() => {
    if (error?.error) {
      setItemsError(
        error?.error?.items
          ? error?.error?.items
          : [
              {
                description: "",
                quantity: "",
                rate: "",
                tax_rate: "",
                amount: "",
                product_code: "",
              },
            ]
      );
    } else {
      setAddError(error?.error ? error?.error : {});
    }
  }, [error]);

  const recurringInvoiceSubmit = (postData) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger,
      taxes: [],
    }));
    if (isModalVisible) {
      const postLedgerData = {
        title: addLedgerData?.title,
        type: addLedgerData?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLedgerData));
    } else {
      const payload = {
        invoice: {
          customer: postData?.customer?.value,
          start_date: postData?.start_date
            ? formatDateYYMMDD(postData?.start_date)
            : "",
          end_date: postData?.end_date
            ? formatDateYYMMDD(postData?.end_date)
            : "",
          nature_of_invoice: recurringInvoiceData?.nature_of_invoice,
          items: ledger,
        },
        schedule: {
          schedule_type: scheduleData?.schedule_type?.value,
        },
      };

      if (editData?.schedule_type) {
        if (editData?.schedule_type === "monthly") {
          if (editData?.dayOfMonth && payload["schedule"]) {
            payload["schedule"]["monthly"] = {
              day_of_month: editData["monthly"]["day_of_month"],
              repeat_every: editData["monthly"]["repeat_every"],
            };
          } else if (editData?.nthWeekday && payload["schedule"]) {
            payload["schedule"]["monthly"] = {
              nth_day_of_week: editData["monthly"]["nth_day_of_week"],
              repeat_every: editData["monthly"]["repeat_every"],
              day_of_week: editData["monthly"]["day_of_week"],
            };
          }
        } else {
          payload["schedule"][editData?.schedule_type] =
            editData[editData?.schedule_type];
        }
      }

      dispatch(recurringInvoiceCreate(payload));
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: value }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleSelectChange = (id, selectedOption) => {
    setAddError((prevState) => ({ ...prevState, [id]: "" }));

    setRecurringInvoiceData((prevState) => ({
      ...prevState,
      [id]: selectedOption,
    }));
  };

  const handleDateChange = (id, date) => {
    setRecurringInvoiceData((prevState) => ({ ...prevState, [id]: date }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    const filterData = [...ledgerData];
    if (filterData[itemIndex]) {
      filterData[itemIndex][id] = value;
    }
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
    setLedgerData(filterData);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return(
    <>
          <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/recurring-invoices">
                          Recurring Invoice List
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">
                         Schedule Recurring invoice
                      </li>
                    </ul>
                  </div>
                </div>
                  <div className="row">
              <AddEditRecurringModal
                id={"addrecurring_invoice"}
                handleSubmit={handleSubmit}
                recurringInvoiceSubmit={recurringInvoiceSubmit}
                control={control}
                handleChange={handleChange}
                errors={errors}
                addError={addError}
                handleDateChange={handleDateChange}
                datePickerRef={datePickerRef}
                recurringInvoiceData={recurringInvoiceData}
                setRecurringInvoiceData={setRecurringInvoiceData}
                error={error}
                selectRefs={selectRefs}
                customer={customer}
                handleSelectChange={handleSelectChange}
                customerDetail={customerDetail}
                scheduleData={scheduleData}
                setScheduleData={setScheduleData}
                // day={day}
                handleCheckBox={handleCheckBox}
                handleItemChange={handleItemChange}
                ledgerData={ledgerData}
                setLedgerData={setLedgerData}
                itemsError={itemsError}
                handleModalClose={handleModalClose}
                loading={loading}
                handleRepeatSelectChange={handleRepeatSelectChange}
                setEditData={setEditData}
                editable={editable}
                setEditable={setEditable}
                expenseList={expenseList}
                ledgerListLoading={ledgerListLoading}
                data={data}
                setData={setData}
                handleCellChange={handleCellChange}
                handleSelectCellChange={handleSelectCellChange}
                handleDoubleClick={handleDoubleClick}
                footerData={footerData}
                setFooterData={setFooterData}
                // editModal={editModal}
                setAddLedgerData={setAddLedgerData}
                addLedgerData={addLedgerData}
                apiError={apiError}
                setApiError={setApiError}
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                postLedgerLoading={postLedgerLoading}
              />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default AddRecurring;
