/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import "../../_components/antd.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Spin, message } from "antd";
import { useDispatch } from "react-redux";
import {
  getCompanyList,
  getCustomerList,
} from "../../redux/actions/commonApiAction";
import { INVOICE_EDIT_RESET } from "../../redux/constants/ARmoduleConstant";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getInvoiceEditDetail,
  updateInvoice,
} from "../../redux/actions/ARmoduleAction";
import { formatDateYYMMDD } from "./constant";
import AddEditInvoiceModal from "./addEditInvoiceModal";
import {
  getLedgerModuleList,
  postLedgerModuleList,
} from "../../redux/actions/LedgerModuleAction";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";

import useUpdateFooterData from "../../customers/updateFooterData";
import useCalculateTotals from "../../helpers/calculation";

const schema = yup.object({
  customer: yup
    .object()
    .shape({
      value: yup.number().required("Customer is required"),
    })
    .nullable()
    .required("Company is required"),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const EditInvoice = () => {
  const [menu, setMenu] = useState(false);
  const [invoiceEditData, setInvoiceEditData] = useState({});
  const [ledgerData, setLedgerData] = useState([]);
  const [itemsError, setItemsError] = useState([
    { description: "", quantity: "", rate: "", total: "" },
  ]);
  const [editError, setEditError] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [file, setFile] = useState(null);
  const [customerDetail, setCustomerDetail] = useState({});
  const [footerData, setFooterData] = useState({});
  const [data, setData] = useState([]);
  const [editable, setEditable] = useState({});
  const [calculationData, setCalculationData] = useState({});
  const [gstSection, setGstSection] = useState([]);
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state?.companyList);
  const { company } = companyList;
  const editInvoiceDetail = useSelector((state) => state.editInvoiceDetail);
  const { invoiceEditDetail, loading: editInvoiceLoading } = editInvoiceDetail;
  const editInvoice = useSelector((state) => state.editInvoice);
  const { success, loading, error } = editInvoice;

  const [messageApi, contextHolder] = message.useMessage();
  const customerList = useSelector((state) => state?.customerList);
  const { customer } = customerList;

  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const totals = useCalculateTotals(data);

  useUpdateFooterData(footerData, setFooterData, totals, gstSection);

  const onModalOpen = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(true);
      setAddLedgerData({ title: "", type: "", description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", type: "", description: "" });
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
  } = postLedgerList;

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      dispatch(getLedgerModuleList("", "", true, ""));
      handleModalClose("#add_ledger");
      setAddLedgerData({});
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
      });
    }
  }, [postLedgerSuccess]);

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const { id } = useParams();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    dispatch(getCustomerList());
  }, []);

  useEffect(() => {
    if (invoiceEditData?.customer?.value) {
      const customerDetails = customer?.find(
        (el) => el?.id === invoiceEditData?.customer?.value
      );

      setCustomerDetail({
        customer_code: customerDetails?.customer_code,
        gstin_number: customerDetails?.gstin_number,
        location: customerDetails?.location,
      });
    }
  }, [customer, invoiceEditData?.customer]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: value }));
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCellChange = (rowId, field, value, index) => {
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleSelectCellChange = (
    selectedOption,
    id,
    rowKey
    // index
  ) => {
    if (id === "tds_ledger") {
      setData((prevData) =>
        prevData.map((row) =>
          row.id === rowKey ? { ...row, [id]: selectedOption } : row
        )
      );
      // setItemId(index);
      const data = {
        tds_section_id: selectedOption?.value,
        item_id: rowKey,
      };

      dispatch(tdsCalculationApi(data));
    }
    setIsChanged(true);
    setData((prevData) =>
      prevData.map((row) =>
        row.id === rowKey ? { ...row, [id]: selectedOption } : row
      )
    );
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonDateChange = (id, date) => {
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: date }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleSelectChange = (id, selectedOption) => {
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: selectedOption }));
  };

  const handleDateChange = (id, date) => {
    setIsChanged(true);
    setInvoiceEditData((prevState) => ({ ...prevState, [id]: date }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleItemChange = (e, itemIndex) => {
    const { id, value } = e.target;
    setIsChanged(true);
    const filterData = [...ledgerData];
    filterData[itemIndex][id] = value;
    setLedgerData(filterData);
    const newErrors = [...itemsError];
    if (newErrors[itemIndex]) {
      newErrors[itemIndex][id] = "";
    }
    setItemsError(newErrors);
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setLedgerData([
      ...ledgerData,
      { description: "", quantity: "", rate: "", total: "" },
    ]);
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    setIsChanged(true);
    const filterData = ledgerData?.filter((el, index) => index !== tabIndex);
    setLedgerData(filterData);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setIsChanged(true);
    setEditError((prevState) => ({ ...prevState, photo: "" }));
  };

  const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : null;
  };

  const initialValues = {
    invoice_number: invoiceEditData?.invoice_number,
    nature_of_supply: invoiceEditData?.nature_of_supply,
    invoice_date: invoiceEditData?.invoice_date,
    company: invoiceEditData?.company,
  };

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const handleEditInvoice = (postData) => {
    const ledger = data?.map((ele) => ({
      ...ele,
      ledger: ele?.ledger,
    }));
    if (isModalVisible) {
      const postLegerData = {
        title: addLedgerData?.title,
        type: addLedgerData?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postLegerData));
    } else {
      const payload = {
        nature_of_supply: postData?.nature_of_supply,
        invoice_date: postData?.invoice_date
          ? formatDateYYMMDD(postData?.invoice_date)
          : "",
        items: ledger,
        customer: postData?.customer?.value,
      };
      dispatch(updateInvoice(payload, id));
      setIsChanged(false);
    }
  };

  useEffect(() => {
    if (error?.error) {
      setItemsError(
        error?.error?.items
          ? error?.error?.items
          : [
              {
                description: "",
                quantity: "",
                rate: "",
                tax_rate: "",
                amount: "",
                product_code: "",
              },
            ]
      );
    } else {
      setEditError(error?.error ? error?.error : {});
    }
  }, [error]);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "nature_of_supply",
      "invoice_date",
      "company",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "company") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "invoice_number",
      "invoice_date",
      "remark",
      "payment_term",
      "nature_of_supply",
      "invoice_date",
      "company",
      "po_num",
      "po_date",
      "photo",
    ];
    const firstErrorField = fieldOrder.find((field) => error && error[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "company") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [error]);

  useEffect(() => {
    setEditError(error);
  }, [error]);

  useEffect(() => {
    if (invoiceEditData) {
      Object.keys(invoiceEditData).forEach((field) => {
        setValue(field, invoiceEditData[field]);
      });
      if (invoiceEditData?.invoice_date) {
        clearErrors("invoice_date");
      }
    }
  }, [invoiceEditData, setValue]);

  useEffect(() => {
    if (success) {
      dispatch({ type: INVOICE_EDIT_RESET });
      dispatch(getInvoiceEditDetail(id));
      reset();
      messageApi.open({
        type: "success",
        content: "invoice updated successfully",
      });
    }
  }, [success]);

  useEffect(() => {
    dispatch(getInvoiceEditDetail(id));
  }, [id]);

  useEffect(() => {
    if (invoiceEditDetail && customer?.length !== 0) {
      const filteredCustomerList = customer?.filter(
        (el) => el?.name === invoiceEditDetail?.customer
      );
      setInvoiceEditData({
        ...invoiceEditDetail,
        invoice_date: parseDate(invoiceEditDetail?.invoice_date)
          ? parseDate(invoiceEditDetail?.invoice_date)
          : "",
        po_date: parseDate(invoiceEditDetail?.po_date),

        customer:
          filteredCustomerList?.length !== 0 && filteredCustomerList
            ? {
                label: filteredCustomerList[0]?.name,
                value: filteredCustomerList[0]?.id,
              }
            : "",
      });
      setFile({
        ...file,
        name: invoiceEditDetail?.photo ? invoiceEditDetail?.photo : "",
      });
      setFooterData(invoiceEditDetail);
      setGstSection(
        invoiceEditDetail?.gst_section
          ? Object?.keys(invoiceEditDetail?.gst_section)
          : []
      );
      const ledger =
        invoiceEditDetail?.items?.length &&
        invoiceEditDetail?.items?.map((ele) => ({
          ...ele,
          ledger: ele?.ledger?.id,
        }));
      setData(
        invoiceEditDetail?.items?.length
          ? ledger
          : [
              {
                description: "",
                quantity: "",
                rate: "",
                total: "",
              },
            ]
      );
    }
  }, [invoiceEditDetail, customer]);

  useEffect(() => {
    dispatch(getLedgerModuleList("", "", true, ""));
  }, [dispatch]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        {/* <!-- Page Wrapper --> */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a>AR module</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to="/invoice-list">Invoice List</Link>
                      </li>
                      <li className="breadcrumb-item active">Edit Invoice</li>
                    </ul>
                  </div>
                </div>
                {editInvoiceLoading ? (
                  <Spin />
                ) : (
                  <div className="row">
                    <AddEditInvoiceModal
                      id={""}
                      handleSubmit={handleSubmit}
                      addInvoiceSubmit={handleEditInvoice}
                      control={control}
                      handleChange={handleChange}
                      errors={errors}
                      addError={editError}
                      handleDateChange={handleDateChange}
                      invoiceData={invoiceEditData}
                      handleCommonChange={handleCommonChange}
                      handleCommonDateChange={handleCommonDateChange}
                      datePickerRef={datePickerRef}
                      selectRefs={selectRefs}
                      customer={customer}
                      handleSelectChange={handleSelectChange}
                      customerDetail={customerDetail}
                      handleFileChange={handleFileChange}
                      file={file}
                      ledgerData={ledgerData}
                      handleItemChange={handleItemChange}
                      itemsError={itemsError}
                      handleAddClick={handleAddClick}
                      handleRemove={handleRemove}
                      loading={loading}
                      isChanged={isChanged}
                      data={data}
                      setData={setData}
                      footerData={footerData}
                      setFooterData={setFooterData}
                      setEditable={setEditable}
                      editable={editable}
                      handleDoubleClick={handleDoubleClick}
                      handleSelectCellChange={handleSelectCellChange}
                      handleCellChange={handleCellChange}
                      editModal={true}
                      expenseList={expenseList}
                      ledgerListLoading={ledgerListLoading}
                      handleModalClose={handleModalClose}
                      setAddLedgerData={setAddLedgerData}
                      addLedgerData={addLedgerData}
                      apiError={apiError}
                      setApiError={setApiError}
                      setIsModalVisible={setIsModalVisible}
                      isModalVisible={isModalVisible}
                      postLedgerLoading={postLedgerLoading}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInvoice;
