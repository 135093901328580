import {
  VENDOR_ADD_REQUEST,
  VENDOR_ADD_SUCCESS,
  VENDOR_ADD_FAIL,
  VENDOR_ADD_RESET,
  GET_VENDORLIST_FAIL,
  GET_VENDORLIST_REQUSET,
  GET_VENDORLIST_SUCCESS,
  VENDORLISTSET_PAGE,
  VENDOR_EDIT_DETAIL_REQUEST,
  VENDOR_EDIT_DETAIL_SUCCESS,
  VENDOR_EDIT_DETAIL_FAIL,
  VENDOR_EDIT_REQUEST,
  VENDOR_EDIT_SUCCESS,
  VENDOR_EDIT_FAIL,
  VENDOR_EDIT_RESET,
  VENDOR_SEARCH_REQUEST,
  VENDOR_SEARCH_SUCCESS,
  VENDOR_SEARCH_FAIL,
  VENDOR_SEARCH_RESET,
  VENDOR_EXISTING_ADD_REQUEST,
  VENDOR_EXISTING_ADD_SUCCESS,
  VENDOR_EXISTING_ADD_FAIL,
  VENDOR_EXISTING_ADD_RESET,
} from "../constants/VendorConstant";

export const vendorAddReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_ADD_REQUEST:
      return { loading: true };

    case VENDOR_ADD_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_ADD_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_ADD_RESET:
      return {};
    default:
      return state;
  }
};

const initialState = {
  vendor: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const vendorListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDORLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case GET_VENDORLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        vendor: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_VENDORLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case VENDORLISTSET_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const vendorEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case VENDOR_EDIT_DETAIL_SUCCESS:
      return { loading: false, vendorEditDetail: action.payload };
    case VENDOR_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vendorEditReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_EDIT_REQUEST:
      return { loading: true };

    case VENDOR_EDIT_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const vendorSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_SEARCH_REQUEST:
      return { loading: true, error: null };
    case VENDOR_SEARCH_SUCCESS:
      return {
        loading: false,
        vendorSearchList: action.payload,
      };
    case VENDOR_SEARCH_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_SEARCH_RESET:
      return {};
    default:
      return state;
  }
};

export const existingVendorAddReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_EXISTING_ADD_REQUEST:
      return { loading: true };

    case VENDOR_EXISTING_ADD_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_EXISTING_ADD_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_EXISTING_ADD_RESET:
      return {};
    default:
      return state;
  }
};
