import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Link } from "react-router-dom";
import { Plan1, Plan2, Plan3 } from "../../_components/Imagepath";

const Step3PlanSelection = () => {
  return (
    <div className="carousel-container">
      <Carousel
        showArrows={true}
        showIndicators={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                position: "absolute",
                zIndex: 2,
                left: 15,
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent", // Transparent background
                border: "none", // No border
                cursor: "pointer", // Show pointer cursor
              }}
            >
              {/* Empty span for the left arrow */}
              <span
                style={{
                  border: "solid red",
                  borderWidth: "0 2px 2px 0",
                  display: "inline-block",
                  padding: "5px",
                  transform: "rotate(135deg)",
                }}
              />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                position: "absolute",
                zIndex: 2,
                right: 15,
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: "transparent", // Transparent background
                border: "none", // No border
                cursor: "pointer", // Show pointer cursor
              }}
            >
              {/* Empty span for the right arrow */}
              <span
                style={{
                  border: "solid red",
                  borderWidth: "0 2px 2px 0",
                  display: "inline-block",
                  padding: "5px",
                  transform: "rotate(-45deg)",
                }}
              />
            </button>
          )
        }
      >
        {/* Your carousel items here */}
        <div>
          <div className="card">
            <div className="card-body">
              <div className="plan-header">
                <span className="plan-widget-icon">
                  <img src={Plan1} alt="" />
                </span>
                <div className="plan-title">
                  <h6>For individuals</h6>
                  <h4 className="plan-name">Basic</h4>
                </div>
              </div>
              <div className="description-content">
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur
                  adipiscing elit.{" "}
                </p>
              </div>
              <div className="price-dollar">
                <h1 className="d-flex align-items-center">
                  $99<span className="ms-1">/monthly</span>
                </h1>
              </div>
              <div className="plan-description">
                <h6>What’s included</h6>
                <ul>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    All analytics features
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Up to 250,000 tracked visits
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Normal support
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Up to 3 team members
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <Link
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  to="#"
                >
                  Get Started
                  <span className="ms-2">
                    <i className="fe fe-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="card price-selected">
            <div className="card-body plan-header-selected">
              <div className="d-flex">
                <div className="plan-header">
                  <span className="plan-widget-icon">
                    <img src={Plan2} alt="" />
                  </span>
                  <div className="plan-title">
                    <h6 className="text-white">For Startups</h6>
                    <h4 className="plan-name text-white">Pro</h4>
                  </div>
                </div>
                <span className="price-popular">Popular</span>
              </div>
              <div className="description-content">
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur
                  adipiscing elit.{" "}
                </p>
              </div>
              <div className="price-dollar">
                <h1 className="d-flex align-items-center text-white">
                  $199<span className="text-white ms-1">/monthly</span>
                </h1>
              </div>
              <div className="plan-description">
                <h6 className="text-white">What’s included</h6>
                <ul>
                  <li>
                    <span className="rounded-circle bg-white me-2">
                      <i className="text-primary fe fe-check" />
                    </span>
                    All analytics features
                  </li>
                  <li>
                    <span className="rounded-circle bg-white me-2">
                      <i className="text-primary fe fe-check" />
                    </span>
                    Up to 1,000,000 tracked visits
                  </li>
                  <li>
                    <span className="rounded-circle bg-white me-2">
                      <i className="text-primary fe fe-check" />
                    </span>
                    Premium support
                  </li>
                  <li>
                    <span className="rounded-circle bg-white me-2">
                      <i className="text-primary fe fe-check" />
                    </span>
                    Up to 10 team members
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <Link
                  className="btn btn-white d-flex align-items-center justify-content-center"
                  to="#"
                >
                  Get Started
                  <span className="ms-2">
                    <i className="fe fe-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="card">
            <div className="card-body">
              <div className="plan-header">
                <span className="plan-widget-icon">
                  <img src={Plan3} alt=""/>
                </span>
                <div className="plan-title">
                  <h6>For individuals</h6>
                  <h4 className="plan-name">Basic</h4>
                </div>
              </div>
              <div className="description-content">
                <p>
                  Lorem ipsum dolor sit amet doloroli sitiol conse ctetur
                  adipiscing elit.{" "}
                </p>
              </div>
              <div className="price-dollar">
                <h1 className="d-flex align-items-center">
                  $399<span className="ms-1">/monthly</span>
                </h1>
              </div>
              <div className="plan-description">
                <h6>What’s included</h6>
                <ul>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    All analytics features
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Up to 5,000,000 tracked visits
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Dedicated support
                  </li>
                  <li>
                    <span className="rounded-circle me-2">
                      <i className="fe fe-check" />
                    </span>
                    Up to 50 team members
                  </li>
                </ul>
              </div>
              <div className="plan-button">
                <Link
                  className="btn btn-primary d-flex align-items-center justify-content-center"
                  to="#"
                >
                  Get Started
                  <span className="ms-2">
                    <i className="fe fe-arrow-right" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Step3PlanSelection;
