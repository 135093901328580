/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../_components/antd.css";
import { Table } from "antd";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import { transactionViewApi } from "../redux/actions/BankModuleAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { clearButtonHoverStyle, clearButtonStyle, containerStyle, formatDateYYMMDD } from "../sales/invoices/constant";

const Uncategorized = ({ activeTab, page, perPage, setPage }) => {
  const [filterData, setFilterData] = useState({
    account_name: "",
    date: "",
    party_name: "",
  });

  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const transactionDetail = useSelector((state) => state?.transactionDetail);
  const {
    transactionViewDetail,
    loading: transactionViewLoading,
  } = transactionDetail;

  const { id } = useParams();
  const dispatch = useDispatch();
  const handleTableChange = (page, perPage) => {
    setPage({ page: page, perPage: perPage });
  };

  const handleFilterChange = (e) => {
    const { id, value } = e.target;
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleDateFilterChange = (selectedDate, id) => {
    setPage({ page: 1, perPage: 10 });
    setFilterData((prevState) => ({
      ...prevState,
      [id]: selectedDate,
    }));
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Bank Narration",
      align: "center",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Transaction Type",
      dataIndex: "category",
      sorter: (a, b) => a?.category?.length - b?.category?.length,
    },
    {
      title: "Ref No.",
      dataIndex: "ref_no",
      sorter: (a, b) => a?.ref_no?.length - b?.ref_no?.length,
    },
    {
      title: "Dr",
      dataIndex: "dr",
      sorter: (a, b) => a?.dr?.length - b?.dr?.length,
    },
    {
      title: "Cr",
      dataIndex: "cr",
      sorter: (a, b) => a?.cr?.length - b?.cr?.length,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      sorter: (a, b) => a?.balance?.length - b?.balance?.length,
    },
    {
      title: "Party/Ledger",
      dataIndex: "party_name",
      sorter: (a, b) => a?.party_name?.length - b?.party_name?.length,
    },
    {
      title: "Posting",
      dataIndex: "posting",
      sorter: (a, b) => a?.posting?.length - b?.posting?.length,
    },
  ];

  useEffect(() => {
    if (id && activeTab?.includes("#uncategorized")) {
      dispatch(
        transactionViewApi(
          page,
          perPage,
          id,
          "true",
          filterData?.party_name,
          filterData?.date ? formatDateYYMMDD(filterData?.date) : "",
          filterData?.account_name
        )
      );
    }
  }, [id, page, perPage, activeTab, filterData]);

  return (
    <>
      <div
        className="list-btn"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ul
          className="filter-list"
          style={{
            display: "flex",
            flex: 1,
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <li>
            <input
              id="party_name"
              type="text"
              value={filterData?.party_name}
              onChange={handleFilterChange}
              placeholder="Party Name"
              className={"form-control"}
            />
          </li>
          <div
            style={containerStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
          <li>
            <DatePicker
              className="datetimepicker form-control"
              id="date"
              placeholderText="Date"
              selected={filterData?.date}
              onChange={(selectedDate) =>
                handleDateFilterChange(selectedDate, "date")
              }
            />
            {filterData?.date && (
                <button
                  type="button"
                  style={{
                    ...clearButtonStyle,
                    ...(isHovered ? clearButtonHoverStyle : {}),
                  }}
                  onClick={() => handleDateFilterChange(null, "date")}
                  aria-label="Clear Date"
                >
                  <i className="fas fa-times" />
                </button>
              )}
          </li>
          </div>
          <li>
            <input
              id="account_name"
              type="text"
              value={filterData?.account_name}
              onChange={handleFilterChange}
              placeholder="Account Name"
              className={"form-control"}
            />
          </li>
          {/* <li>
            <Select
              // options={formattedOptions(intervalList)}
              placeholder={"Bank"}
            />
          </li> */}
          {/* <li>
          <Select
            // options={formattedOptions(dayList)}
            placeholder={"Transaction"}
          />
        </li> */}
        </ul>
        {/* <li style={{ listStyle: 'none', marginLeft: 'auto' }}>
        <Select
          // options={formattedOptions(dayList)}
          placeholder={"Post to ERP"}
        />
      </li> */}
      </div>

      <div className="row">
        <div className="col-sm-12">
        <div className="card">
            <div className="card-body payments">
          <div className="table-responsive">
            <Table
              loading={transactionViewLoading}
              pagination={{
                total: transactionViewDetail?.count,
                current: page,
                pageSize: perPage,
                onChange: handleTableChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                // showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              columns={columns}
              dataSource={transactionViewDetail?.results}
              rowKey={(record) => record.Id}
            />
          </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Uncategorized;
