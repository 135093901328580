/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import { updateUserInfo, verifyOtp } from "../../redux/actions/UserAction";
import { Modal } from "bootstrap";

const OTP = ({ show, onClose, phone, setCurrentStep }) => {
  const [otpValue, setOtpValue] = useState("");
  const dispatch = useDispatch();
  const userOTP = useSelector((state) => state.userOTP);
  const { payload, success, loading, error } = userOTP;
  const [messageApi, contextHolder] = message.useMessage();

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (otpValue.length === 5) {
      const data = { otp: otpValue, phone: phone };
      dispatch(verifyOtp(data));
    } else {
      messageApi.open({
        type: "error",
        content: "Please enter a valid 5-digit OTP",
      });
    }
  };

  useEffect(() => {
    if (success) {
      const otpModal = Modal.getInstance(document.getElementById("otp-modal"));
      if (otpModal) {
        otpModal.hide();
      }
      onClose();
      dispatch(updateUserInfo(payload));
      setCurrentStep(1);
    }
    if (error) {
      messageApi.open({
        type: "error",
        content: error,
      });
    }
  }, [success, error, payload, messageApi]);

  return (
    <>
      {contextHolder}
      <div
        id="otp-modal"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleVerifyOtp} className="px-3">
                <div className="mb-3">
                  <label className="form-label">Mobile Verification</label>
                  <input
                    className="form-control"
                    type="number"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    maxLength={6}
                  />
                </div>

                <div className="mb-2 text-center">
                  <button
                    className="btn rounded-pill btn-primary me-1"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <Spin /> : "Verify"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OTP.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default OTP;
