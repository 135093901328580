import { Spin } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import CustomInput from "../../_components/inputField/custominput";
import PropTypes from "prop-types";

const CustomerAddEditModal = ({
  id,
  handleSubmit,
  handleEditSubmit,
  control,
  handleChange,
  errors,
  apiError,
  isDisable,
  createCustomerLoading,
  isChanged,
  loading,
  editData,
  addCustomer,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit(handleEditSubmit)}>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="name"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Name"
                      label="Name"
                      errorMsg={errors?.name?.message}
                      error={errors?.name}
                      apiError={apiError?.name}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="contact_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="contact_name"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Contact Name"
                      label="Contact Name"
                      errorMsg={errors?.contact_name?.message}
                      error={errors?.contact_name}
                      apiError={apiError?.contact_name}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="location"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="location"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Location"
                      label="Location"
                      errorMsg={errors?.location?.message}
                      error={errors?.location}
                      apiError={apiError?.location}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <CustomInput
                id="customer_code"
                type="text"
                value={
                  id === "add_customer"
                    ? editData?.customer_code
                    : addCustomer?.customer_code
                }
                onChange={handleChange}
                placeholder="Customer Code"
                label="Customer Code"
                apiError={apiError?.customer_code}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="email"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="email"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Email"
                      label="Email"
                      errorMsg={errors?.email?.message}
                      error={errors?.email}
                      apiError={apiError?.email}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="gstin_number"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="gstin_number"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter GST number"
                      label="GST no"
                      errorMsg={errors?.gstin_number?.message}
                      error={errors?.gstin_number}
                      apiError={apiError?.gstin_number}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="pan_number"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="pan_number"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter PAN number"
                      label="PAN no"
                      errorMsg={errors?.pan_number?.message}
                      error={errors?.pan_number}
                      apiError={apiError?.pan_number}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="state"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="state"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter State"
                      label="State"
                      errorMsg={errors?.state?.message}
                      error={errors?.state}
                      apiError={apiError?.state}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="phone"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="phone"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter phone number"
                      label="Phone no"
                      errorMsg={errors?.phone?.message}
                      error={errors?.phone}
                      apiError={apiError?.phone}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="address"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="address"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter address"
                      label="Address"
                      errorMsg={errors?.address?.message}
                      error={errors?.address}
                      apiError={apiError?.address}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="input-block mb-3">
              <Controller
                name="zip_code"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <CustomInput
                      id="zip_code"
                      type="text"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        handleChange(e);
                      }}
                      placeholder="Enter zipcode number"
                      label="Zipcode"
                      errorMsg={errors?.zip_code?.message}
                      error={errors?.zip_code}
                      apiError={apiError?.zip_code}
                      autoComplete="false"
                      required={true}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>

        {id === "add_customer" ? (
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!isDisable}
          >
            {createCustomerLoading ? <Spin /> : "Save"}
          </button>
        ) : (
          <div className="add-customer-btns text-end">
            <Link to="/chartofaccount">
              <button type="reset" className="btn btn-primary cancel me-2">
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!isChanged}
            >
              {loading ? <Spin /> : "Save"}
            </button>
          </div>
        )}
      </form>
    </>
  );
};

CustomerAddEditModal.propTypes = {
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleEditSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  apiError: PropTypes.object,
  isDisable: PropTypes.bool,
  createCustomerLoading: PropTypes.bool,
  isChanged: PropTypes.bool,
  loading: PropTypes.bool,
  editData: PropTypes.object,
  addCustomer : PropTypes.object,
};

export default CustomerAddEditModal;
