export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_TDS_LIST_REQUEST = "GET_TDS_LIST_REQUEST";
export const GET_TDS_LIST_SUCCESS = "GET_TDS_LIST_SUCCESS";
export const GET_TDS_LIST_FAIL = "GET_TDS_LIST_FAIL";
export const GET_ROLES_LIST_REQUEST = "GET_ROLES_LIST_REQUEST";
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";
export const GET_ROLES_LIST_FAIL = "GET_ROLES_LIST_FAIL";
export const GET_EXPENSE_MANAGER_LIST_REQUEST = "GET_EXPENSE_MANAGER_LIST_REQUEST";
export const GET_EXPENSE_MANAGER_LIST_SUCCESS = "GET_EXPENSE_MANAGER_LIST_SUCCESS";
export const GET_EXPENSE_MANAGER_LIST_FAIL = "GET_EXPENSE_MANAGER_LIST_FAIL";


export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';