export const GET_AP_INVOICELIST_REQUEST = "GET_AP_INVOICELIST_REQUEST";
export const GET_AP_INVOICELIST_SUCCESS = "GET_AP_INVOICELIST_SUCCESS";
export const GET_AP_INVOICELIST_FAIL = "GET_AP_INVOICELIST_FAIL";
export const GET_AP_INVOICELIST_PAGE = " GET_AP_INVOICELIST_PAGE";

export const UPLOAD_AP_INVOICE_REQUEST = "UPLOAD_AP_INVOICE_REQUEST";
export const UPLOAD_AP_INVOICE_SUCCESS = "UPLOAD_AP_INVOICE_SUCCESS";
export const UPLOAD_AP_INVOICE_FAIL = "UPLOAD_AP_INVOICE_FAIL";
export const UPLOAD_AP_INVOICE_RESET = "UPLOAD_AP_INVOICE_RESET";

export const AP_INVOICE_EDIT_DETAIL_REQUEST = "AP_INVOICE_EDIT_DETAIL_REQUEST";
export const AP_INVOICE_EDIT_DETAIL_SUCCESS = "AP_INVOICE_EDIT_DETAIL_SUCCESS";
export const AP_INVOICE_EDIT_DETAIL_FAIL = "AP_INVOICE_EDIT_DETAIL_FAIL";
export const AP_INVOICE_EDIT_REQUEST = "AP_INVOICE_EDIT_REQUEST";
export const AP_INVOICE_EDIT_SUCCESS = "AP_INVOICE_EDIT_SUCCESS";
export const AP_INVOICE_EDIT_FAIL = "AP_INVOICE_EDIT_FAIL";
export const AP_INVOICE_EDIT_RESET = "AP_INVOICE_EDIT_RESET";

export const GET_TDS_RATE_LIST_REQUEST = "GET_TDS_RATE_LIST_REQUEST";
export const GET_TDS_RATE_LIST_SUCCESS = "GET_TDS_RATE_LIST_SUCCESS";
export const GET_TDS_RATE_LIST_FAIL = "GET_TDS_RATE_LIST_FAIL";

//manager List

export const GET_MANAGER_LIST_REQUEST = "GET_MANAGER_LIST_REQUEST";
export const GET_MANAGER_LIST_SUCCESS = "GET_MANAGER_LIST_SUCCESS";
export const GET_MANAGER_LIST_FAIL = "GET_MANAGER_LIST_FAIL";

//send approval
export const UPLOAD_SEND_REQUEST = "UPLOAD_SEND_APPROVAL_REQUEST";
export const UPLOAD_SEND_SUCCESS = "UPLOAD_SEND_SUCCESS";
export const UPLOAD_SEND_FAIL = "UPLOAD_SEND_FAIL";
export const UPLOAD_SEND_RESET = "UPLOAD_SEND_RESET";

//invoice approval
export const AP_INVOICE_APPROVAL_REQUEST = "AP_INVOICE_APPROVAL_REQUEST";
export const AP_INVOICE_APPROVAL_SUCCESS = "AP_INVOICE_APPROVAL_SUCCESS";
export const AP_INVOICE_APPROVAL_FAIL = "AP_INVOICE_APPROVAL_FAIL";
export const AP_INVOICE_APPROVAL_RESET = "AP_INVOICE_APPROVAL_RESET";

//invoice approval if null
export const AP_INVOICE_POST_APPROVAL_REQUEST = "AP_INVOICE_POST_APPROVAL_REQUEST";
export const AP_INVOICE_POST_APPROVAL_SUCCESS = "AP_INVOICE_POST_APPROVAL_SUCCESS";
export const AP_INVOICE_POST_APPROVAL_FAIL = "AP_INVOICE_POST_APPROVAL_FAIL";
export const AP_INVOICE_POST_APPROVAL_RESET = "AP_INVOICE_POST_APPROVAL_RESET";

//submit zoho approval
export const UPLOAD_SUBMIT_ZOHO_REQUEST = "UPLOAD_SUBMIT_ZOHO_REQUEST";
export const UPLOAD_SUBMIT_ZOHO_SUCCESS = "UPLOAD_SUBMIT_ZOHO_SUCCESS";
export const UPLOAD_SUBMIT_ZOHO_FAIL = "UPLOAD_SUBMIT_ZOHO_FAIL";
export const UPLOAD_SUBMIT_ZOHO_RESET = "UPLOAD_SUBMIT_ZOHO_RESET";

export const GET_TDS_CALCULATION_REQUEST = "GET_TDS_CALCULATION_REQUEST";
export const GET_TDS_CALCULATION_SUCCESS = "GET_TDS_CALCULATION_SUCCESS";
export const GET_TDS_CALCULATION_FAIL = "GET_TDS_CALCULATION_FAIL";
export const GET_TDS_CALCULATION_RESET = "GET_TDS_CALCULATION_RESET";

//create AP-Invoive
export const AP_INVOICE_CREATE_REQUEST = "AP_INVOICE_EDIT_REQUEST";
export const AP_INVOICE_CREATE_SUCCESS = "AP_INVOICE_EDIT_SUCCESS";
export const AP_INVOICE_CREATE_FAIL = "AP_INVOICE_EDIT_FAIL";
export const AP_INVOICE_CREATE_RESET = "AP_INVOICE_EDIT_RESET";