import React from "react";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { Spin } from "antd";
import PropTypes from "prop-types";

import CustomInput from "../../_components/inputField/custominput";
import { Link } from "react-router-dom";
import AddLedgerModal from "../../customers/addLedgerModal";
import CustomSelect from "../../customers/customSelect";

const AddEditInvoiceModal = ({
  id,
  handleSubmit,
  addInvoiceSubmit,
  control,
  handleChange,
  errors,
  addError,
  handleDateChange,
  selectRefs,
  datePickerRef,
  customer,
  handleSelectChange,
  customerDetail,
  // handleFileChange,
  // file,
  // ledgerData,
  // handleItemChange,
  // itemsError,
  // handleAddClick,
  // handleRemove,
  // ledgerData,
  // handleItemChange,
  // itemsError,
  // handleAddClick,
  // handleRemove,
  handleModalClose,
  loading,
  isChanged,
  handleCellChange,
  handleSelectCellChange,
  data,
  setData,
  editable,
  setEditable,
  handleDoubleClick,
  expenseList,
  footerData,
  // setFooterData,
  editModal,
  setAddLedgerData,
  addLedgerData,
  apiError,
  setApiError,
  setIsModalVisible,
  isModalVisible,
  postLedgerLoading,
}) => {
  const handleLedgerChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const renderCell = (rowId, field, value, index) => {
    if (editable.rowId === rowId && editable.field === field) {
      if (field === "ledger") {
        return (
          <>
              <CustomSelect
                id={"ledger"}
                value={value}
                onChange={(selectedOption) => {
                  handleSelectCellChange(
                    selectedOption,
                    "ledger",
                    rowId,
                    index
                  );
                }}
                options={expenseList?.length ? expenseList : []}
                addLedgerData={addLedgerData}
                setAddLedgerData={setAddLedgerData}
                handleSubmit={handleSubmit}
                handleEditSubmit={addInvoiceSubmit}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                control={control}
                errors={errors}
                handleModalClose={handleModalClose}
                setApiError={setApiError}
                apiError={apiError}
              />
          </>
        );
      }
      //  else if (field === "tds_amount") {
      //   return <input type={"text"} value={value} readOnly />;
      // }
      else {
        return (
          <input
            style={{ cursor: "pointer" }}
            type={field !== "quantity" ? "text" : "number"}
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index)
            }
            onBlur={() => setEditable({})}
            // autoFocus
          />
        );
      }
    }
    if (field === "ledger") {
      return (
        <>
            <CustomSelect
              id={"ledger"}
              value={value}
              onChange={(selectedOption) => {
                handleSelectCellChange(selectedOption, "ledger", rowId, index);
              }}
              options={expenseList?.length ? expenseList : []}
              addLedgerData={addLedgerData}
              setAddLedgerData={setAddLedgerData}
              handleSubmit={handleSubmit}
              handleEditSubmit={addInvoiceSubmit}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              control={control}
              errors={errors}
              handleModalClose={handleModalClose}
              setApiError={setApiError}
              apiError={apiError}
            />
        </>
      );
    }
    // else if (field === "tds_amount") {
    //   return <input type={"text"} value={value} readOnly />;
    // }
    else if (editModal) {
      return (
        <input
          type={field !== "quantity" ? "text" : "number"}
          value={value}
          disabled={
            field === "amount" || field === "gst_amount" || field === "total"
          }
          readOnly
          onDoubleClick={() => handleDoubleClick(rowId, field, index)}
        />
      );
    } else {
      return (
        <input
          type={field !== "quantity" ? "text" : "number"}
          value={value}
          disabled={
            field === "amount" || field === "gst_amount" || field === "total"
          }
          // readOnly
          // onDoubleClick={() => handleDoubleClick(rowId, field)}
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index)
          }
        />
      );
    }
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        tds_ledger: null,
        tds_amount: "",
      },
    ]);
    // setAddLedgerData({ title: "", type: "", description: "" });
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    // setIsChanged(true);
    setData(filterData);
  };

  return (
    <>
      <form onSubmit={handleSubmit(addInvoiceSubmit)}>
        <div className="col-md-12">
          <div className="form-group-item border-0 mb-0">
            <div className="row align-item-center">
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="invoice_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id="invoice_number"
                          type="text"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Invoice Number"
                          label="Invoice Number"
                          errorMsg={errors?.invoice_number?.message}
                          error={errors?.invoice_number}
                          apiError={addError?.invoice_number}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="nature_of_supply"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id="nature_of_supply"
                        type="text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="Nature of Supply"
                        label="Nature of Supply"
                        errorMsg={errors?.nature_of_supply?.message}
                        error={errors?.nature_of_supply}
                        apiError={addError?.nature_of_supply}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Invoice Date
                    <span className="text-danger">*</span>
                  </label>

                  <Controller
                    name="invoice_date"
                    control={control}
                    render={({ field }) => (
                      <div className="cal-icon cal-icon-info">
                        <DatePicker
                          {...field}
                          className="datetimepicker form-control"
                          selected={field?.value}
                          onChange={(date) =>
                            handleDateChange(
                              "invoice_date",
                              date,
                              field?.onChange(date)
                            )
                          }
                          id="invoice_date"
                          ref={datePickerRef}
                        />
                      </div>
                    )}
                  />
                  {errors?.invoice_date?.message && (
                    <p className="text-danger">
                      {errors?.invoice_date?.message}
                    </p>
                  )}
                  {addError?.invoice_date && (
                    <p className="text-danger">{addError?.invoice_date}</p>
                  )}
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Billing Period <span className="text-danger">*</span>
                  </label>

                  <Controller
                    name="billing_period"
                    control={control}
                    render={({ field }) => (
                      <div className="cal-icon cal-icon-info">
                        <DatePicker
                          {...field}
                          className="datetimepicker form-control"
                          selected={field?.value}
                          onChange={(date) =>
                            handleDateChange(
                              "billing_period",
                              date,
                              field?.onChange(date)
                            )
                          }
                          id="billing_period"
                          ref={datePickerRef}
                        />
                      </div>
                    )}
                  />
                  {errors?.billing_period?.message && (
                    <p className="text-danger">
                      {errors?.billing_period?.message}
                    </p>
                  )}
                  {addError?.billing_period && (
                    <p className="text-danger">{addError?.billing_period}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="remark"
                    type="text"
                    value={invoiceData?.remark}
                    onChange={handleCommonChange}
                    placeholder="Remark"
                    label="Remark"
                    apiError={addError?.remark}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="payment_term"
                    type="text"
                    value={invoiceData?.payment_term}
                    onChange={handleCommonChange}
                    placeholder="Payment Term"
                    label="Payment Term"
                    apiError={addError?.payment_term}
                  />
                </div>
              </div> */}

              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id="po_num"
                    type="text"
                    value={invoiceData?.po_num}
                    onChange={handleCommonChange}
                    placeholder="Purchase Order No"
                    label="Purchase Order No"
                    apiError={addError?.po_num}
                  />
                </div>
              </div> */}

              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>Purchase Order Date</label>
                  <div className="cal-icon cal-icon-info">
                    <DatePicker
                      id="po_date"
                      className="datetimepicker form-control"
                      selected={invoiceData?.po_date}
                      onChange={(date) =>
                        handleCommonDateChange("po_date", date)
                      }
                    ></DatePicker>
                    {addError?.po_date && (
                      <p className="text-danger">{addError?.po_date}</p>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Customer<span className="text-danger">*</span>
                  </label>
                  <Controller
                    name={"customer"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        ref={(el) => (selectRefs.current["customer"] = el)}
                        options={
                          customer &&
                          customer?.map((option) => ({
                            value: option.id,
                            label: option.name,
                          }))
                        }
                        onChange={(selectedOption) =>
                          handleSelectChange(
                            "customer",
                            selectedOption,
                            field.onChange(selectedOption)
                          )
                        }
                      />
                    )}
                  />
                  {errors?.customer?.message && (
                    <p className="text-danger">{errors?.customer?.message}</p>
                  )}
                  {addError?.customer && (
                    <p className="text-danger">{addError?.customer}</p>
                  )}
                </div>
              </div>
              {customerDetail?.customer_code && (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <CustomInput
                      id="tax_ledger"
                      type="text"
                      value={customerDetail?.customer_code}
                      // onChange={handleCommonChange}
                      placeholder="Customer Code"
                      label="Customer Code"
                      // apiError={addError?.customer_code}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {customerDetail?.gstin_number && (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <CustomInput
                      id="tax_ledger"
                      type="text"
                      value={customerDetail?.gstin_number}
                      // onChange={handleCommonChange}
                      placeholder="GSTIN"
                      label="GSTIN"
                      // apiError={addError?.customer_code}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {customerDetail?.location && (
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="input-block mb-3">
                    <CustomInput
                      id="location"
                      type="text"
                      value={customerDetail?.location}
                      // onChange={handleCommonChange}
                      placeholder="Customer Location"
                      label="Customer Location"
                      // apiError={addError?.customer_code}
                      disabled={true}
                    />
                  </div>
                </div>
              )}

              {/* <div className="col-lg-12 col-md-6 col-sm-12">
                <label className="col-form-label col-md-2">Photo</label>
                <div className="col-md-4">
                  <input
                    className="form-control"
                    type="file"
                    onChange={handleFileChange}
                  />
                  {file && <p>{file?.name}</p>}
                  {addError?.photo && (
                    <p className="text-danger">{addError?.photo}</p>
                  )}
                </div>
              </div> */}
            </div>
          </div>
          <div className="complex-invoice-table">
            <div className="ag-theme-alpine" style={{ width: "100%" }}>
              <h6>Invoice Intent</h6>
              <div
                className="tableHscroll"
                style={{ minHeight: "300px", overflowY: "visible" }}
              >
                <table className="apedittable">
                  <thead>
                    <tr>
                      <>
                        <td align="center">Sl No.</td>
                        <td>Ledger Name</td>
                        <td>Description</td>
                        <td>Unit</td>
                        <td>Unit Price</td>
                        <td>Base Amount</td>
                        <td>GST Rate</td>
                        <td>GST Amount</td>
                        <td>Total</td>
                        <td style={{ width: "auto" }}>&nbsp;</td>
                      </>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((row, index) => (
                      <tr key={row.id}>
                        <>
                          <td align="center"> {index + 1}</td>
                          <td className="customtableSelect">
                            {renderCell(row.id, "ledger", row?.ledger, index)}
                          </td>
                          <td>
                            {renderCell(
                              row.id,
                              "description",
                              row.description,
                              index
                            )}
                          </td>
                          <td>
                            {renderCell(
                              row.id,
                              "quantity",
                              row.quantity,
                              index
                            )}
                          </td>
                          <td>{renderCell(row.id, "rate", row.rate, index)}</td>
                          <td>
                            {renderCell(row.id, "amount", row.amount, index)}
                          </td>
                          <td>
                            {renderCell(
                              row.id,
                              "gst_rate",
                              row.gst_rate,
                              index
                            )}
                          </td>
                          <td>
                            {renderCell(
                              row.id,
                              "gst_amount",
                              row.gst_amount,
                              index
                            )}
                          </td>
                          <td>
                            {renderCell(row.id, "total", row.total, index)}
                          </td>
                          <td className="tableEditBtns">
                            <button
                              className="btn btn-default btn-xs delete"
                              onClick={(event) => handleRemove(event, index)}
                            >
                              <i className="fas fa-trash" />
                            </button>
                          </td>
                        </>
                      </tr>
                    ))}
                    {editModal && (
                      <tr>
                        <td
                          colSpan={10}
                          style={{
                            textAlign: "right",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            className="btn btn-greys btn-xs add"
                            onClick={handleAddClick}
                          >
                            <i className="far fa-add" />
                          </button>
                        </td>
                      </tr>
                    )}
                    {editModal && (
                      <>
                        <tr>
                          <td colSpan={10}></td>
                        </tr>
                        <tr className="rowStickty">
                          <>
                            <td colSpan={8}></td>
                            <td align="left">
                              <strong>Base Amount</strong>
                            </td>
                            <td align="right">
                              {footerData?.total_base_amount
                                ? footerData?.total_base_amount
                                : 0}
                            </td>
                          </>
                        </tr>
                        {footerData?.gst_section &&
                          Object.keys(footerData?.gst_section)?.map(
                            (el, index) => {
                              return (
                                <tr key={index} className="rowStickty">
                                  <>
                                    <td colSpan={8}></td>
                                    <td align="left">
                                      <strong>{el}</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.gst_section
                                        ? footerData["gst_section"][el]
                                        : ""}
                                    </td>
                                  </>
                                </tr>
                              );
                            }
                          )}
                        <tr className="rowStickty">
                          <>
                            <td colSpan={8}></td>
                            <td align="left">
                              <strong>Total Tax</strong>
                            </td>
                            <td align="right">
                              {footerData?.total_tax
                                ? footerData?.total_tax
                                : 0}
                            </td>
                          </>
                        </tr>
                        <tr className="rowStickty">
                          <>
                            <td colSpan={8}></td>
                            <td align="left">
                              <strong>Total Amount</strong>
                            </td>
                            <td align="right">{footerData?.total_amount}</td>
                          </>
                        </tr>
                        <tr className="rowStickty">
                          <>
                            <td colSpan={8}></td>
                            <td align="left">
                              <strong>Amount Payable</strong>
                            </td>
                            <td align="right">{footerData?.amount_payable}</td>
                          </>
                        </tr>
                      </>
                    )}
                    {!editModal && (
                      <tr>
                      <td colSpan={10}>
                  <div className="stickyAddBtnPopup">
                    <button
                      className="btn btn-greys btn-xs add"
                      onClick={handleAddClick}
                    >
                      <i className="far fa-add" />
                    </button>
                  </div>
                  </td>
                        </tr>
                )}
                       
                  </tbody>
                </table>
                
                <AddLedgerModal
                  addLedgerData={addLedgerData}
                  apiError={apiError}
                  handleChange={handleLedgerChange}
                  handleModalClose={handleModalClose}
                  handleEditSubmit={addInvoiceSubmit}
                  loading={postLedgerLoading}
                />
              </div>
              {editModal && (
                <div className="tableHscroll">
                  <table className="apedittableInvoice">
                    <thead>
                      <tr>
                        <>
                          <td colSpan="3">Debit</td>
                          <td colSpan={3}>Credit</td>
                        </>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <>
                          <td colSpan={3}>
                            <table>
                              <thead>
                                <tr>
                                  <>
                                    <td>Date</td>
                                    <td>Ledger Name</td>
                                    <td>Amount</td>
                                  </>
                                </tr>
                              </thead>
                              <tbody>
                                {footerData?.suggested_entry?.credit &&
                                  footerData?.suggested_entry?.credit?.ledgers?.map(
                                    (el, index) => {
                                      return (
                                        <tr key={index}>
                                          <>
                                            <td>
                                              {index === 0
                                                ? footerData?.suggested_entry
                                                    ?.credit?.date
                                                : ""}
                                            </td>
                                            <td>{el?.ledger}</td>
                                            <td>{el?.amount}</td>
                                          </>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <>
                                    <td></td>
                                    <td>
                                      <strong>Rates & Taxes</strong>
                                    </td>
                                    <td>
                                      <strong>Tax Amount</strong>
                                    </td>
                                  </>
                                </tr>
                                {footerData?.suggested_entry?.credit?.gst &&
                                  Object.keys(
                                    footerData?.suggested_entry?.credit?.gst
                                  )?.map((el, index) => {
                                    return (
                                      <tr key={index}>
                                        <>
                                          <td></td>
                                          <td>{el}</td>
                                          <td>
                                            {footerData?.suggested_entry?.credit
                                              ?.gst
                                              ? footerData["suggested_entry"][
                                                  "credit"
                                                ]["gst"][el]
                                              : ""}
                                          </td>
                                        </>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                          <td colSpan={3}>
                            <table>
                              <thead>
                                <tr>
                                  <>
                                    <td>Date</td>
                                    <td>Ledger Name</td>
                                    <td>Amount</td>
                                  </>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <>
                                    <td>
                                      {footerData?.suggested_entry?.debit?.date
                                        ? footerData?.suggested_entry?.debit
                                            ?.date
                                        : ""}
                                    </td>
                                    <td>
                                      {footerData?.suggested_entry?.debit
                                        ?.ledger
                                        ? footerData?.suggested_entry?.debit
                                            ?.ledger
                                        : ""}
                                    </td>
                                    <td>
                                      {footerData?.suggested_entry?.debit
                                        ?.amount
                                        ? footerData?.suggested_entry?.debit
                                            ?.amount
                                        : ""}
                                    </td>
                                  </>
                                </tr>
                                {/* <tr>
                                  <>
                                    <td></td>
                                    <td>
                                      <b>TDS Under Section</b>
                                    </td>
                                    <td>
                                      <b>TDS Amount</b>
                                    </td>
                                  </>
                                </tr> */}
                                {footerData?.suggested_entry?.credit?.tds &&
                                  Object.keys(
                                    footerData?.suggested_entry?.credit?.tds
                                  )?.map((el, index) => {
                                    return (
                                      <tr key={index}>
                                        <>
                                          <td></td>
                                          <td>{el}</td>
                                          <td>
                                            {footerData?.suggested_entry?.credit
                                              ?.tds
                                              ? footerData["suggested_entry"][
                                                  "credit"
                                                ]["tds"][el]
                                              : ""}
                                          </td>
                                        </>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </td>
                        </>
                      </tr>
                      <tr>
                        <>
                          <td></td>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <b>
                              {footerData?.suggested_entry?.debit
                                ? footerData?.suggested_entry?.debit?.total
                                : ""}
                            </b>
                          </td>
                          <td style={{ width: "16.66%" }}></td>
                          <td style={{ width: "16.66%" }}>
                            <strong>Total</strong>
                          </td>
                          <td style={{ border: "none" }}>
                            <b>
                              {footerData?.suggested_entry?.credit
                                ? footerData?.suggested_entry?.credit?.total
                                : ""}
                            </b>
                          </td>
                        </>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          {/* <div className="addInvoiceTable">
            <h6>Invoice Intent</h6>
            <br />
            <table className="apedittable">
              <thead>
                <tr>
                  <td>Sl No.</td>
                  <td>Description of supply</td>

                  <td>Quantity</td>
                  <td>Rate</td>
                  <td>Total</td>
                  <td>&nbsp;</td>
                </tr>
              </thead>
              <tbody>
                {ledgerData?.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>
                        <input
                          id="description"
                          type="text"
                          required
                          value={el?.description}
                          onChange={(e) => handleItemChange(e, index)}
                        />
                        {itemsError[index] &&
                          itemsError[index]?.description && (
                            <p className="text-danger">
                              {itemsError[index]?.description}
                            </p>
                          )}
                      </td>

                      <td>
                        <input
                          id="quantity"
                          type="text"
                          required
                          value={el?.quantity}
                          onChange={(e) => handleItemChange(e, index)}
                        />
                        {itemsError[index] && itemsError[index]?.quantity && (
                          <p className="text-danger">
                            {itemsError[index]?.quantity}
                          </p>
                        )}
                      </td>
                      <td>
                        <input
                          id="rate"
                          type="text"
                          required
                          value={el?.rate}
                          onChange={(e) => handleItemChange(e, index)}
                        />
                        {itemsError[index] && itemsError[index]?.rate && (
                          <p className="text-danger">
                            {itemsError[index]?.rate}
                          </p>
                        )}
                      </td>
                      <td>
                        <input
                          id="total"
                          type="text"
                          required
                          value={el?.total}
                          onChange={(e) => handleItemChange(e, index)}
                        />{" "}
                        {itemsError[index] && itemsError[index]?.total && (
                          <p className="text-danger">
                            {itemsError[index]?.total}
                          </p>
                        )}
                      </td>
                      <td className="tableEditBtns">
                        {index === ledgerData?.length - 1 && (
                          <button
                            className="btn btn-default btn-xs delete"
                            onClick={handleAddClick}
                          >
                            <i className="far fa-add" />
                          </button>
                        )}
                        {index !== ledgerData?.length - 1 && (
                          <button
                            className="btn btn-default btn-xs add"
                            onClick={(event) => handleRemove(event, index)}
                          >
                            <i className="fas fa-trash" />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan={8}></td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>CGST Output</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>SGST Output</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>IGST Output</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>Select Tax Ledger</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>Select Tax Ledger</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={8}></td>
                </tr>
                <tr>
                  <td colSpan={5}></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <input value="HSN/SAC" disabled />
                  </td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {id === "add_invoice" ? (
            <>
               <Link to="/invoice-list">
                <button type="reset" className="btn btn-primary cancel me-2">
                  Cancel
                </button>
              </Link>

              <button type="submit" className="btn btn-primary">
                {loading ? <Spin /> : "Save"}
              </button>
            </>
          ) : (
            <>
              <Link to="/invoice-list">
                <button type="reset" className="btn btn-primary cancel me-2">
                  Cancel
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isChanged || loading}
              >
                {loading ? <Spin /> : "Save"}
              </button>
            </>
          )}
        </div>
      </form>
    </>
  );
};

AddEditInvoiceModal.propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  addInvoiceSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  addError: PropTypes.object,
  handleDateChange: PropTypes.func.isRequired,
  datePickerRef: PropTypes.object.isRequired,
  selectRefs: PropTypes.object.isRequired,
  customer: PropTypes.array,
  handleSelectChange: PropTypes.func.isRequired,
  customerDetail: PropTypes.object.isRequired,
  // handleFileChange: PropTypes.func.isRequired,
  file: PropTypes.object,
  handleItemChange: PropTypes.func.isRequired,
  itemsError: PropTypes.array.isRequired,
  handleAddClick: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleModalClose: PropTypes.func,
  loading: PropTypes.bool,
  isChanged: PropTypes.bool,
  handleCellChange: PropTypes.func.isRequired,
  handleSelectCellChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  editable: PropTypes.object,
  setEditable: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  expenseList: PropTypes.array,
  ledgerListLoading: PropTypes.bool,
  tdsSectionListLoading: PropTypes.bool,
  footerData: PropTypes.object,
  editModal: PropTypes?.bool,
  setAddLedgerData: PropTypes.func,
  addLedgerData: PropTypes.object,
  apiError: PropTypes.object,
  setApiError: PropTypes.func,
  setIsModalVisible: PropTypes.func,
  isModalVisible: PropTypes.bool,
  postLedgerLoading: PropTypes.bool,
};

export default AddEditInvoiceModal;
