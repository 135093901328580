import { Spin } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import CustomInput from "../../_components/inputField/custominput";
import PropTypes from "prop-types";
// import DatePicker from "react-datepicker";

const AddEditVendorModal = ({
  id,
  handleSubmit,
  handleEditSubmit,
  control,
  handleChange,
  handleChangeField,
  errors,
  apiError,
  // setAddData,
  addData,
  // setApiError,
  editData,
  // handleDateChange,
  handleFileChange,
  file,
  addVendorLoading,
  isChanged,
  loading,
}) => {
  //   const addEditVendor = () => {
  //     <div className="modal-body p-4">
  //             <div className="row">
  //               <div className="col-md-12">

  //     </div>
  //                 </div>
  //                 </div>
  //   };
  return (
    <>
      <form onSubmit={handleSubmit(handleEditSubmit)}>
        <div className="">
          <div className="form-group-item">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id={id === "add_vendor" ? "add-name" : "name"}
                          type="text"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Name"
                          label="Name"
                          errorMsg={errors?.name?.message}
                          error={errors?.name}
                          apiError={apiError?.name}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="state_code"
                    id="state_code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id={
                          id === "add_vendor" ? "add-state_code" : "state_code"
                        }
                        type="text"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="State Code"
                        label="State Code"
                        errorMsg={errors?.state_code?.message}
                        error={errors?.state_code}
                        apiError={apiError?.state_code}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id={id === "add_vendor" ? "add-email" : "email"}
                        type="email"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="Email"
                        label="Email"
                        errorMsg={errors?.email?.message}
                        error={errors?.email}
                        apiError={apiError?.email}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id={id === "add_vendor" ? "add-state" : "state"}
                        type="type"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="State"
                        label="State"
                        errorMsg={errors?.state?.message}
                        error={errors?.state}
                        apiError={apiError?.state}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>
                    Address
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className={`form-control  ${
                          errors?.address ? "error-input" : ""
                        }`}
                        id={id === "add_vendor" ? "add-address" : "address"}
                        type="text"
                        placeholder="Address"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        autoComplete="false"
                      />
                    )}
                  />
                  {apiError?.address && (
                    <p className="text-danger">{apiError?.address}</p>
                  )}
                  {errors?.address?.message && (
                    <p className="text-danger">{errors?.address?.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Controller
                  name="vendor_code"
                  control={control}
                  render={({ field }) => (
                    <CustomInput
                      id={id === "add_vendor" ? "add-vendor_code" : "vendor_code"}
                      type="type"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Vendor Code"
                      label="Vendor Code"
                      apiError={apiError?.vendor_code}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="pan_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id={
                            id === "add_vendor"
                              ? "add-pan_number"
                              : "pan_number"
                          }
                          type="type"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Enter PAN number"
                          label="PAN"
                          errorMsg={errors?.pan_number?.message}
                          error={errors?.pan_number}
                          apiError={apiError?.pan_number}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>Start Date</label>
                  {id === "add_vendor" ? (
                    <DatePicker
                      className="datetimepicker form-control"
                      selected={addData?.start_date}
                      onChange={(date) => {
                        setAddData((prevState) => ({
                          ...prevState,
                          start_date: date,
                        }));
                        setApiError((prevState) => ({
                          ...prevState,
                          start_date: "",
                        }));
                      }}
                      id="start_date"
                    />
                  ) : (
                    <DatePicker
                      className="datetimepicker form-control"
                      selected={editData?.start_date}
                      onChange={(date) => {
                        handleDateChange("start_date", date);
                      }}
                      id="start_date"
                    />
                  )}
                  {apiError?.start_date && (
                    <p className="text-danger">{apiError?.start_date}</p>
                  )}
                  {errors?.start_date?.message && (
                    <p className="text-danger">{errors?.start_date?.message}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label>End Date</label>
                  {id === "add_vendor" ? (
                    <DatePicker
                      className="datetimepicker form-control"
                      selected={addData?.end_date}
                      onChange={(date) => {
                        setAddData((prevState) => ({
                          ...prevState,
                          end_date: date,
                        }));
                        setApiError((prevState) => ({
                          ...prevState,
                          end_date: "",
                        }));
                      }}
                      id="end_date"
                    />
                  ) : (
                    <DatePicker
                      className="datetimepicker form-control"
                      selected={editData?.end_date}
                      onChange={(date) => {
                        handleDateChange("end_date", date);
                      }}
                      id="end_date"
                    />
                  )}
                  {apiError?.end_date && (
                    <p className="text-danger">{apiError?.end_date}</p>
                  )}
                  {errors?.end_date?.message && (
                    <p className="text-danger">{errors?.end_date?.message}</p>
                  )}
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={id === "add_vendor" ? "add-msme" : "msme"}
                    type="type"
                    value={addData?.msme}
                    onChange={handleChangeField}
                    placeholder="MSME"
                    label="MSME"
                    apiError={apiError?.msme}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="contact_name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id={
                            id === "add_vendor"
                              ? "add-contact_name"
                              : "contact_name"
                          }
                          type="type"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Enter Contact Name"
                          label="Contact Name"
                          errorMsg={errors?.contact_name?.message}
                          error={errors?.contact_name}
                          apiError={apiError?.contact_name}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="gstin_number"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id={
                          id === "add_vendor"
                            ? "add-gstin_number"
                            : "gstin_number"
                        }
                        type="type"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="GSTIN"
                        label="GSTIN"
                        errorMsg={errors?.gstin_number?.message}
                        error={errors?.gstin_number}
                        apiError={apiError?.gstin_number}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={id === "add_vendor" ? "add-bank_name" : "bank_name"}
                    type="type"
                    value={addData?.bank_name}
                    onChange={handleChangeField}
                    placeholder="Bank Name"
                    label="Bank Name"
                    apiError={apiError?.bank_name}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id={id === "add_vendor" ? "add-phone" : "phone"}
                          type="phone"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Enter phone number"
                          label="Phone"
                          errorMsg={errors?.phone?.message}
                          error={errors?.phone}
                          apiError={apiError?.phone}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="zip_code"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <CustomInput
                        id={id === "add_vendor" ? "add-zip_code" : "zip_code"}
                        type="type"
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        placeholder="Zipcode"
                        label="Zipcode"
                        errorMsg={errors?.zip_code?.message}
                        error={errors?.zip_code}
                        apiError={apiError?.zip_code}
                        autoComplete="false"
                        required={true}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={
                      id === "add_vendor"
                        ? "add-account_number"
                        : "account_number"
                    }
                    type="type"
                    value={addData?.account_number}
                    onChange={handleChangeField}
                    placeholder="A/C No"
                    label="A/C No"
                    apiError={apiError?.account_number}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={id === "add_vendor" ? "add-ifsc_code" : "ifsc_code"}
                    type="type"
                    value={addData?.ifsc_code}
                    onChange={handleChangeField}
                    placeholder="IFSC"
                    label="IFSC"
                    apiError={apiError?.ifsc_code}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <Controller
                    name="branch_name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <CustomInput
                          id={
                            id === "add_vendor"
                              ? "add-branch_name"
                              : "branch_name"
                          }
                          type="type"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}
                          placeholder="Bank branch"
                          label="Bank branch"
                          errorMsg={errors?.branch_name?.message}
                          error={errors?.branch_name}
                          apiError={apiError?.branch_name}
                          autoComplete="false"
                          required={true}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={id === "add_vendor" ? "add-swift" : "swift"}
                    type="type"
                    value={addData?.swift}
                    onChange={handleChangeField}
                    placeholder="Swift"
                    label="Swift"
                    apiError={apiError?.swift}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={
                      id === "add_vendor"
                        ? "add-approved_amount"
                        : "approved_amount"
                    }
                    type="type"
                    value={addData?.approved_amount}
                    onChange={handleChangeField}
                    placeholder="Approved Amount"
                    label="Approved Amount"
                    apiError={apiError?.approved_amount}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <CustomInput
                    id={
                      id === "add_vendor"
                        ? "add-tds_certificate_number"
                        : "tds_certificate_number"
                    }
                    type="type"
                    value={addData?.tds_certificate_number}
                    onChange={handleChangeField}
                    placeholder="TDS Certificate Number"
                    label="TDS Certificate Number"
                    apiError={apiError?.tds_certificate_number}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="input-block mb-3">
                  <label className="form-label">TDS Certificate</label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={handleFileChange}
                    accept=".jpeg,.jpg,.png,.pdf"
                  />
                  {file && (
                    <blockquote>
                      <p className="mb-0">{file.name}</p>
                    </blockquote>
                  )}

                  <blockquote>
                    <p className="mb-0">{editData?.tds_certificate}</p>
                  </blockquote>

                  {apiError?.tds_certificate && (
                    <p className="text-danger">{apiError?.tds_certificate}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {id === "add_vendor" ? (
            <button type="submit" className="btn btn-primary">
              {addVendorLoading ? <Spin /> : "Save"}
            </button>
          ) : (
            <div className="add-customer-btns text-end">
              <Link to="/chartofaccount">
                <button type="reset" className="btn btn-primary cancel me-2">
                  Cancel
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={!isChanged}
              >
                {loading ? <Spin /> : "Save"}
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

AddEditVendorModal.propTypes = {
  id: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleEditSubmit: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  apiError: PropTypes.object,
  setAddData: PropTypes.func,
  addData: PropTypes.object,
  setApiError: PropTypes.func.isRequired,
  editData: PropTypes.object,
  handleDateChange: PropTypes.func,
  handleFileChange: PropTypes.func.isRequired,
  file: PropTypes.object,
  addVendorLoading: PropTypes.bool,
  isChanged: PropTypes.bool,
  loading: PropTypes.string,
  handleChangeField:PropTypes.func
};

export default AddEditVendorModal;
