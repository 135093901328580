import {
  AP_INVOICE_APPROVAL_FAIL,
  AP_INVOICE_APPROVAL_REQUEST,
  AP_INVOICE_APPROVAL_RESET,
  AP_INVOICE_APPROVAL_SUCCESS,
  AP_INVOICE_CREATE_FAIL,
  AP_INVOICE_CREATE_REQUEST,
  AP_INVOICE_CREATE_RESET,
  AP_INVOICE_CREATE_SUCCESS,
  AP_INVOICE_EDIT_DETAIL_FAIL,
  AP_INVOICE_EDIT_DETAIL_REQUEST,
  AP_INVOICE_EDIT_DETAIL_SUCCESS,
  AP_INVOICE_EDIT_FAIL,
  AP_INVOICE_EDIT_REQUEST,
  AP_INVOICE_EDIT_RESET,
  AP_INVOICE_EDIT_SUCCESS,
  AP_INVOICE_POST_APPROVAL_FAIL,
  AP_INVOICE_POST_APPROVAL_REQUEST,
  AP_INVOICE_POST_APPROVAL_RESET,
  AP_INVOICE_POST_APPROVAL_SUCCESS,
  GET_AP_INVOICELIST_FAIL,
  GET_AP_INVOICELIST_PAGE,
  GET_AP_INVOICELIST_REQUEST,
  GET_AP_INVOICELIST_SUCCESS,
  GET_MANAGER_LIST_FAIL,
  GET_MANAGER_LIST_REQUEST,
  GET_MANAGER_LIST_SUCCESS,
  GET_TDS_CALCULATION_FAIL,
  GET_TDS_CALCULATION_REQUEST,
  GET_TDS_CALCULATION_RESET,
  GET_TDS_CALCULATION_SUCCESS,
  GET_TDS_RATE_LIST_FAIL,
  GET_TDS_RATE_LIST_REQUEST,
  GET_TDS_RATE_LIST_SUCCESS,
  UPLOAD_AP_INVOICE_FAIL,
  UPLOAD_AP_INVOICE_REQUEST,
  UPLOAD_AP_INVOICE_RESET,
  UPLOAD_AP_INVOICE_SUCCESS,
  UPLOAD_SEND_FAIL,
  UPLOAD_SEND_REQUEST,
  UPLOAD_SEND_RESET,
  UPLOAD_SEND_SUCCESS,
  UPLOAD_SUBMIT_ZOHO_FAIL,
  UPLOAD_SUBMIT_ZOHO_REQUEST,
  UPLOAD_SUBMIT_ZOHO_RESET,
  UPLOAD_SUBMIT_ZOHO_SUCCESS,
} from "../constants/APmoduleConstant";

const initialState = {
  apInvoice: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const invoiceAPListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AP_INVOICELIST_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_AP_INVOICELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        apInvoice: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_AP_INVOICELIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_AP_INVOICELIST_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export const invoiceUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_AP_INVOICE_REQUEST:
      return { loading: true };
    case UPLOAD_AP_INVOICE_SUCCESS:
      return { loading: false, success: true };

    case UPLOAD_AP_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case UPLOAD_AP_INVOICE_RESET:
      return {};
    default:
      return state;
  }
};

export const apInvoiceApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case AP_INVOICE_APPROVAL_REQUEST:
      return { loading: true };
    case AP_INVOICE_APPROVAL_SUCCESS:
      return { loading: false, success: true };
    case AP_INVOICE_APPROVAL_FAIL:
      return { loading: false, error: action.payload };
    case AP_INVOICE_APPROVAL_RESET:
      return {};
    default:
      return state;
  }
};

export const apInvoicePostApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case AP_INVOICE_POST_APPROVAL_REQUEST:
      return { loading: true };
    case AP_INVOICE_POST_APPROVAL_SUCCESS:
      return { loading: false, success: true };
    case AP_INVOICE_POST_APPROVAL_FAIL:
      return { loading: false, error: action.payload };
    case AP_INVOICE_POST_APPROVAL_RESET:
      return {};
    default:
      return state;
  }
};

export const submitZohoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_SUBMIT_ZOHO_REQUEST:
      return { zohoApprovalLoading: true };
    case UPLOAD_SUBMIT_ZOHO_SUCCESS:
      return {
        zohoApprovalLoading: false,
        zohoApprovalSuccess: true,
      };
    case UPLOAD_SUBMIT_ZOHO_FAIL:
      return { zohoApprovalLoading: false, zohoApprovalError: action.payload };
    case UPLOAD_SUBMIT_ZOHO_RESET:
      return {};
    default:
      return state;
  }
};

export const sendApprovalReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_SEND_REQUEST:
      return { sendApprovalLoading: true };
    case UPLOAD_SEND_SUCCESS:
      return {
        sendApprovalLoading: false,
        sendApprovalSuccess: true,
      };
    case UPLOAD_SEND_FAIL:
      return { sendApprovalLoading: false, sendApprovalError: action.payload };
    case UPLOAD_SEND_RESET:
      return {};
    default:
      return state;
  }
};

export const apInvoiceEditDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case AP_INVOICE_EDIT_DETAIL_REQUEST:
      return { loading: true };
    case AP_INVOICE_EDIT_DETAIL_SUCCESS:
      return { loading: false, apInvoiceEditDetail: action.payload };
    case AP_INVOICE_EDIT_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const managerListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MANAGER_LIST_REQUEST:
      return { loading: true };
    case GET_MANAGER_LIST_SUCCESS:
      return { loading: false, managerListData: action.payload };
    case GET_MANAGER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const apInvoiceEditReducer = (state = {}, action) => {
  switch (action.type) {
    case AP_INVOICE_EDIT_REQUEST:
      return { loading: true };

    case AP_INVOICE_EDIT_SUCCESS:
      return { loading: false, success: true };

    case AP_INVOICE_EDIT_FAIL:
      return { loading: false, error: action.payload };
    case AP_INVOICE_EDIT_RESET:
      return {};
    default:
      return state;
  }
};

export const tdsRateListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TDS_RATE_LIST_REQUEST:
      return { loading: true };
    case GET_TDS_RATE_LIST_SUCCESS:
      return { loading: false, listData: action.payload };
    case GET_TDS_RATE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const tdsCalculationReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TDS_CALCULATION_REQUEST:
      return { loading: true };

    case GET_TDS_CALCULATION_SUCCESS:
      return { loading: false,tdsAmount:action.payload, success: true };

    case GET_TDS_CALCULATION_FAIL:
      return { loading: false, error: action.payload };
    case GET_TDS_CALCULATION_RESET:
      return {};
    default:
      return state;
  }
};

export const CreateAPinvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case AP_INVOICE_CREATE_REQUEST:
      return { loading: true };

    case AP_INVOICE_CREATE_SUCCESS:
      return { loading: false,tdsAmount:action.payload, success: true };

    case AP_INVOICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case AP_INVOICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
