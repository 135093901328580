/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import { Table, message } from "antd";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";

import { useSelector } from "react-redux";
import { useYodlee } from "yodlee-react-hook";
import { HTTP } from "../redux/api";
import {
  bankEditDetail,
  getBankDropdownList,
  getBankListApi,
  setPage,
} from "../redux/actions/BankModuleAction";
import { useDispatch } from "react-redux";
import {
  BANK_ADD_RESET,
  BANK_EDIT_RESET,
  BANK_UPLOAD_RESET,
} from "../redux/constants/BankConstant";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddBank from "./addBank";
import EditBank from "./editBank";
import BankStatementUpload from "./bankStatementupload";
import { accountTypeList } from "./constant";
const schema = yup.object({
  account_name: yup
    .string()
    .required("Account Name is required")
    .trim(),
  account_code: yup
    .string()
    .required("Account Code is required")
    .trim(),
  currency: yup
    .object()
    .shape({
      value: yup.string().required("Currency is required"),
    })
    .nullable()
    .required("Currency is required"),
  bank_name: yup
    .string()
    .required("Bank Name is required")
    .trim(),
});

const Bank = () => {
  const { ready, init } = useYodlee({
    containerId: "container-fastlink",
    fastLinkOptions: {
      fastLinkURL: "https://node.sandbox.yodlee.com/authenticate/restserver",
      token: {
        tokenType: "AccessToken",
        tokenValue: "foo",
      },
    },
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [bankData, setBankData] = useState({
    account_type: "Bank",
    account_name: "",
    account_code: "",
    currency: null,
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    description: "",
    is_primary: true,
  });
  const [editData, setEditData] = useState({});
  const [filterData, setFilterData] = useState({ label: "", value: "" });
  const [bankId, setBankId] = useState("");
  const [addError, setAddError] = useState({});
  const [editError, setEditError] = useState({});
  const [uploadData, setUploadData] = useState({});
  const [uploadError, setUploadError] = useState({});
  const [file, setFile] = useState(null);
  const [setsuccessLinking, setSetsuccessLinking] = useState(false);

  const location = useLocation();

  const userData = useSelector((state) => state.userSignin);
  const { userInfo, permissions } = userData;

  const bankCreate = useSelector((state) => state.addBank);
  const { success, loading, error } = bankCreate;

  const getBankList = useSelector((state) => state?.bankList);
  const {
    loading: bankListLoading,
    error: bankListError,
    page,
    perPage,
    bankList,
  } = getBankList;

  const bankListData = useSelector((state) => state?.bankListData);
  const { bankDropdownList } = bankListData;

  const bankDetail = useSelector((state) => state?.bankDetail);
  const { editDetail } = bankDetail;

  const bankUpdate = useSelector((state) => state.bankUpdate);
  const {
    success: editSuccess,
    loading: editLoading,
    error: bankEditError,
  } = bankUpdate;

  const bankUpload = useSelector((state) => state.bankUpload);
  const {
    success: uploadSuccess,
    loading: uploadLoading,
    error: uploadApiError,
  } = bankUpload;

  const modalRef = useRef();
  const selectRefs = useRef({});
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const formattedOptions = (options) =>
    options.map((option) => ({
      value: option.id,
      label: option.text,
    }));

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: BANK_ADD_RESET });
      dispatch(
        getBankListApi(
          page,
          perPage,
          filterData?.account_type?.value ? filterData?.account_type?.value : ""
        )
      );
      setBankData({
        account_type: "Bank",
        account_name: "",
        account_code: "",
        currency: null,
        account_number: "",
        bank_name: "",
        ifsc_code: "",
        description: "",
        is_primary: true,
      });
      dispatch(getBankDropdownList());
      handleModalClose("#add_bank");
      closeModal();
      messageApi.open({
        type: "success",
        content: "Bank Created Successfully",
      });
    }
  }, [success]);

  useEffect(() => {
    if (editSuccess) {
      dispatch({ type: BANK_EDIT_RESET });
      dispatch(getBankListApi(page, perPage, ""));
      handleModalClose("#edit_bank");
      closeModal();
      setBankId("");
      messageApi.open({
        type: "success",
        content: "Bank Updated Successfully",
      });
    }
  }, [editSuccess]);

  useEffect(() => {
    if (uploadSuccess) {
      dispatch({ type: BANK_UPLOAD_RESET });
      dispatch(getBankListApi(page, perPage, ""));
      handleModalClose("#upload_bank_info");
      closeModal();
      setFile(null);
      setUploadData({ password: "", bank: "" });
      messageApi.open({
        type: "success",
        content: "Bank Statement Uploaded Successfully",
      });
    }
  }, [uploadSuccess]);

  useEffect(() => {
    setAddError(error);
  }, [error]);

  useEffect(() => {
    setUploadError(uploadApiError);
  }, [uploadApiError]);

  useEffect(() => {
    setEditError(bankEditError);
  }, [bankEditError]);

  useEffect(() => {
    if (editDetail && bankId) {
      setEditData({
        account_type: editDetail?.account_type,
        account_name: editDetail?.account_name,
        account_code: editDetail?.account_code,
        currency: { value: editDetail?.currency, label: editDetail?.currency },
        account_number: editDetail?.account_number,
        bank_name: editDetail?.bank_name,
        ifsc_code: editDetail?.ifsc_code,
        description: editDetail?.description,
        is_primary: editDetail?.is_primary,
      });
    }
  }, [editDetail, bankId]);

  const onModalClose = () => {
    $("#add_bank").on("hide.bs.modal", () => {
      setBankData({
        account_type: "Bank",
        account_name: "",
        account_code: "",
        currency: null,
        account_number: "",
        bank_name: "",
        ifsc_code: "",
        description: "",
        is_primary: true,
      });
      reset();
      dispatch({ type: BANK_ADD_RESET });
    });
    $("#edit_bank").on("hide.bs.modal", () => {
      setEditData({
        account_type: "Bank",
        account_name: "",
        account_code: "",
        account_number: "",
        bank_name: "",
        ifsc_code: "",
        description: "",
        is_primary: true,
      });
      setBankId("");
      dispatch({ type: BANK_EDIT_RESET });
    });
    $("#upload_bank_info").on("hide.bs.modal", () => {
      setFile(null);
      fileInputRef.current.value = "";
      setUploadData({ password: "", bank: "" });
      setUploadError({});
      dispatch({ type: BANK_UPLOAD_RESET });
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const editId = id?.split("-")[1];
    if (editId) {
      setEditData((prevState) => ({ ...prevState, [editId]: value }));
      setEditError((prevState) => ({ ...prevState, [editId]: "" }));
    }
    setBankData((prevState) => ({ ...prevState, [id]: value }));
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleOptionChange = (event, type) => {
    if (type === "edit") {
      setEditData((prevState) => ({
        ...prevState,
        account_type: event.target.value,
      }));
      setEditError({});
    } else {
      setBankData((prevState) => ({
        ...prevState,
        account_type: event.target.value,
      }));
      setAddError({});
    }

    if (event.target.value === "CreditCard") {
      setEditData((prevState) => ({
        ...prevState,
        account_number: "",
        ifsc_code: "",
      }));
      setBankData((prevState) => ({
        ...prevState,
        account_number: "",
        ifsc_code: "",
      }));
    }
  };

  const handleSelectChange = (id) => {
    setAddError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCheckBoxChange = (e, type) => {
    const isChecked = e.target.checked;
    if (type === "edit") {
      setEditData((prevState) => ({ ...prevState, is_primary: isChecked }));
      setEditError((prevState) => ({ ...prevState, is_primary: "" }));
    } else {
      setBankData((prevState) => ({ ...prevState, is_primary: isChecked }));
      setAddError((prevState) => ({ ...prevState, is_primary: "" }));
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: bankData || editData,
  });

  useEffect(() => {
    reset(editData);
  }, [editData]);

  useEffect(() => {
    const fieldOrder = [
      "account_name",
      "account_code",
      "currency",
      "bank_name",
    ];
    const firstErrorField = fieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "currency") {
        if (bankId) {
          inputElement = selectRefs.current[`edit-${firstErrorField}`];
        } else {
          inputElement = selectRefs.current[firstErrorField];
        }
      } else {
        if (bankId) {
          inputElement = document.getElementById(`edit-${firstErrorField}`);
        } else {
          inputElement = document.getElementById(firstErrorField);
        }
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors, bankId]);

  useEffect(() => {
    const fieldOrder = [
      "account_name",
      "account_code",
      "currency",
      "account_number",
      "bank_name",
      "ifsc_code",
      "description",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "currency") {
        inputElement = selectRefs.current[`edit-${firstErrorField}`];
      } else {
        inputElement = document.getElementById(`edit-${firstErrorField}`);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  useEffect(() => {
    const fieldOrder = [
      "account_name",
      "account_code",
      "account_number",
      "bank_name",
      "ifsc_code",
      "description",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => addError && addError[field]
    );
    if (firstErrorField) {
      let inputElement;

      inputElement = document.getElementById(firstErrorField);

      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [addError]);

  const handleEditClick = (id) => {
    setBankId(id);
  };

  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      render: (img, record) => (
        <>
          <h2 className="table-avatar">
            <Link to={`/bankdetail/${record?.id}`}>{record.bank_name}</Link>
          </h2>
        </>
      ),
      sorter: (a, b) => a.bank_name.length - b.bank_name.length,
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      sorter: (a, b) => a.account_number.length - b.account_number.length,
    },
    {
      title: "Amount Name",
      dataIndex: "account_name",
      sorter: (a, b) => a.account_name.length - b.account_name.length,
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      sorter: (a, b) => a.account_type.length - b.account_type.length,
    },
    {
      title: "IFSC",
      dataIndex: "ifsc_code",
      sorter: (a, b) => a.ifsc_code.length - b.ifsc_code.length,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
          {permissions?.bankingModule?.addEditTransactions && (
            <div className="d-flex align-items-center">
              <Link
                className="btn btn-primary"
                data-bs-toggle="modal"
                to="#edit_bank"
                role="button"
                onClick={() => handleEditClick(record?.id)}
              >
                <i className="far fa-edit" />
              </Link>
              &nbsp; &nbsp;
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!location?.pathname.includes("/bankdetail")) {
      localStorage.removeItem("currentTransactionTab");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (setsuccessLinking) {
      messageApi.open({
        type: "success",
        content: "Bank linked successfully",
      });
    }
  }, [setsuccessLinking]);

  useEffect(() => {
    onModalClose();
    dispatch(getBankDropdownList());
  }, []);

  useEffect(() => {
    if (bankId) {
      dispatch(bankEditDetail(bankId));
    }
  }, [bankId]);

  useEffect(() => {
    dispatch(
      getBankListApi(
        page,
        perPage,
        filterData?.account_type?.value ? filterData?.account_type?.value : ""
      )
    );
  }, [page, perPage, filterData?.account_type]);

  const handleFilterChange = (selectedOption) => {
    dispatch(setPage(1, 10));
    setFilterData((prevState) => ({
      ...prevState,
      account_type: selectedOption,
    }));
  };

  const handleClick = async () => {
    try {
      const response = await fetch(
        "http://3.109.217.81:8000/api/users/yodlee-access-token/",
        {
          headers: {
            Authorization: `Bearer ${userInfo}`,
          },
        }
      );
      const data = await response.json();
      if (typeof window !== "undefined" && window.fastlink) {
        window.fastlink.open(
          {
            fastLinkURL: data.fastLinkURL,
            accessToken: data.access_token,
            params: {
              configName: data.configName,
            },
            onSuccess: function(data) {
              HTTP.post(
                "/users/yodlee-redirect-api/",
                { data },
                {
                  headers: {
                    Authorization: `Bearer ${userInfo}`,
                  },
                }
              )
                .then((response) => {
                  setSetsuccessLinking(true);
                  alert("Success");
                })
                .catch((err) => {
                  alert(err);
                });
            },
            onError: function(data) {
              alert("Something Error");
            },
            onClose: function(data) {},
            onEvent: function(data) {},
          },
          "container-fastlink"
        );
      } else {
        alert("Something Error");
      }
    } catch (error) {
    }
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header  */}
            <div id="container-fastlink">
              <div className="page-header">
                <div className="content-page-header">
                  <div className="col"><h5>Banking</h5></div>

                  <div className="list-btn banking">
                    <ul className="filter-list">
                      <li>
                        <Select
                          value={filterData?.account_type}
                          onChange={(selectedOption) =>
                            handleFilterChange(selectedOption)
                          }
                          options={formattedOptions(accountTypeList)}
                          placeholder={"Account Type"}
                        />
                      </li>
                      {permissions?.bankingModule?.addEditTransactions && (
                        <>
                          <li
                          >
                            <a  className="btn btn-primary" onClick={handleClick}>
                            <i className="fas fa-exchange-alt me-2"></i>Link bank</a>
                          </li>
                          <li>
                            <Link
                              className="btn btn-white"
                              data-bs-toggle="modal"
                              to="#add_bank"
                              role="button"
                            >
                              <i
                                className="fa fa-plus-circle me-2"
                                aria-hidden="true"
                              />
                              Add Manually
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="btn btn-white"
                              data-bs-toggle="modal"
                              to="#upload_bank_info"
                              role="button"
                            >
                              <i
                                className="fa fa-file-import me-2"
                                aria-hidden="true"
                              />
                              Import Statement
                            </Link>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Page Header  */}
              {/* Table  */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body payments">
                      <div className="table-responsive table-hover">
                        <Table
                          loading={bankListLoading}
                          pagination={{
                            total: bankList?.count,
                            current: page,
                            pageSize: perPage,
                            onChange: handleTableChange,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,

                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          columns={columns}
                          dataSource={bankList?.results}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table  */}
            </div>
          </div>
        </div>

        {/* Add Bank */}
      <AddBank
        bankData={bankData}
        modalRef={modalRef}
        show={show}
        handleSubmit={handleSubmit}
        control={control}
        handleChange={handleChange}
        errors={errors}
        addError={addError}
        selectRefs={selectRefs}
        handleSelectChange={handleSelectChange}
        handleModalClose={handleModalClose}
        handleCheckBoxChange={handleCheckBoxChange}
        handleOptionChange={handleOptionChange}
        loading={loading}
      />

      {/* Edit Bank */}
      <EditBank
        editData={editData}
        bankId={bankId}
        modalRef={modalRef}
        show={show}
        handleSubmit={handleSubmit}
        handleOptionChange={handleOptionChange}
        handleChange={handleChange}
        errors={errors}
        editError={editError}
        control={control}
        selectRefs={selectRefs}
        handleSelectChange={handleSelectChange}
        handleCheckBoxChange={handleCheckBoxChange}
        handleModalClose={handleModalClose}
        editLoading={editLoading}
      />
      {/* Upload Bank Statement */}
      <BankStatementUpload
        modalRef={modalRef}
        show={show}
        uploadData={uploadData}
        bankDropdownList={bankDropdownList}
        setUploadData={setUploadData}
        uploadError={uploadError}
        selectRefs={selectRefs}
        setUploadError={setUploadError}
        fileInputRef={fileInputRef}
        setFile={setFile}
        file={file}
        handleModalClose={handleModalClose}
        uploadLoading={uploadLoading}
      />

      </div>
    </>
  );
};
export default Bank;
