import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import {
  teamMemberCreateReducer,
  teamMemberDeleteReducer,
  teamMemberListReducer,
  teamMemberUpdateReducer,
  userActivityListReducer,
  userCompanyInfoReducer,
  userOTPReducer,
  userProfileReducer,
  userProfileUpdateReducer,
  userRegisterReducer,
  userSigninReducer,
} from "./reducers/userReducer";
import {
  invoiceAddReducer,
  invoiceEditDetailReducer,
  invoiceEditReducer,
  invoiceListReducer,
} from "./reducers/invoiceReducer";
import {
  existingVendorAddReducer,
  vendorAddReducer,
  vendorEditDetailReducer,
  vendorEditReducer,
  vendorListReducer,
  vendorSearchReducer,
} from "./reducers/VendorReducer";
import {
  recurringInvoiceAddReducer,
  recurringInvoiceEditDetailReducer,
  recurringInvoiceEditReducer,
  recurringInvoiceListReducer,
} from "./reducers/recurringReducer";
import {
  companyListReducer,
  customerListReducer,
  expenseManagerListReducer,
  rolesListReducer,
  tdsSectionListReducer,
  userListReducer,
} from "./reducers/commonApiReducer";
import {
  customerAddReducer,
  customerEditDetailReducer,
  customerEditReducer,
  customerModuleListReducer,
} from "./reducers/customerModuleReducer";
import {
  apInvoiceApprovalReducer,
  apInvoiceEditDetailReducer,
  apInvoiceEditReducer,
  apInvoicePostApprovalReducer,
  CreateAPinvoiceReducer,
  invoiceAPListReducer,
  invoiceUploadReducer,
  managerListReducer,
  sendApprovalReducer,
  submitZohoReducer,
  tdsCalculationReducer,
  tdsRateListReducer,
} from "./reducers/APmoduleReducer";
import {
  bankAddReducer,
  bankEditDetailReducer,
  bankEditReducer,
  bankListApiReducer,
  bankListReducer,
  bankStatementViewReducer,
  bankUploadReducer,
  tranactionViewReducer,
} from "./reducers/bankReducer";
import { ledgerModuleListReducer, postLedgerModuleListReducer } from "./reducers/ledgerModuleReducer";
import { reportDataProcessingListReducer } from "./reducers/reportsModuleReducer";
import {
  SubscriptionHistoryListReducer,
  SubscriptionPlanListReducer,
} from "../membership/membershipReducer";
import { checkZohoOrgIdReducer, zohoAuthReducer, zohoCrmCreateReducer } from "../settings/CrmandErpConnect/CrmandErpReducer";
import errorReducer from "./reducers/errorReducer";

const initialState = {
  userSignin: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
    refresh: localStorage.getItem("refresh")
      ? JSON.parse(localStorage.getItem("refresh"))
      : null,
    userName: localStorage.getItem("userName")
      ? JSON.parse(localStorage.getItem("userName"))
      : null,
    userId: localStorage.getItem("userId")
      ? JSON.parse(localStorage.getItem("userId"))
      : null,
    role: localStorage.getItem("role")
      ? JSON.parse(localStorage.getItem("role"))
      : null,
    currentSideMenu: localStorage.getItem("currentSideMenu")
      ? localStorage.getItem("currentSideMenu")
      : "",
    permissions: localStorage.getItem("permissions")
      ? JSON.parse(localStorage.getItem("permissions"))
      : null,
  },
};

const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userOTP: userOTPReducer,
  userCompanyInfo: userCompanyInfoReducer,
  invoiceList: invoiceListReducer,
  dataProcessingReport: reportDataProcessingListReducer,
  addvendor: vendorAddReducer,
  vendorList: vendorListReducer,
  customerList: customerListReducer,
  companyList: companyListReducer,
  addRecurringInvoice: recurringInvoiceAddReducer,
  addInvoice: invoiceAddReducer,
  customerModuleList: customerModuleListReducer,
  editInvoiceDetail: invoiceEditDetailReducer,
  editInvoice: invoiceEditReducer,
  recurringInvoiceList: recurringInvoiceListReducer,
  editRecurringDetailInvoice: recurringInvoiceEditDetailReducer,
  editRecurringInvoice: recurringInvoiceEditReducer,
  apInvoiceList: invoiceAPListReducer,
  invoiceUpload: invoiceUploadReducer,
  apInvoiceEditDetail: apInvoiceEditDetailReducer,
  managerList: managerListReducer,
  submitZoho: submitZohoReducer,
  sendApproval: sendApprovalReducer,
  apInvoiceEdit: apInvoiceEditReducer,
  apInvoiceApproval: apInvoiceApprovalReducer,
  apInvoicePostApproval: apInvoicePostApprovalReducer,
  customerEditDetail: customerEditDetailReducer,
  customerEdit: customerEditReducer,
  vendorEditDetail: vendorEditDetailReducer,
  vendorEdit: vendorEditReducer,
  createCustomer: customerAddReducer,
  vendorListSearch: vendorSearchReducer,
  existingVendorCreate: existingVendorAddReducer,
  addBank: bankAddReducer,
  bankList: bankListReducer,
  bankDetail: bankEditDetailReducer,
  bankUpdate: bankEditReducer,
  bankListData: bankListApiReducer,
  bankUpload: bankUploadReducer,
  transactionDetail: tranactionViewReducer,
  bankStatement: bankStatementViewReducer,
  ledgerList: ledgerModuleListReducer,
  userList: userListReducer,
  tdsRateList: tdsRateListReducer,
  tdsSectionList: tdsSectionListReducer,
  userActivityList: userActivityListReducer,
  getTeamMemberList: teamMemberListReducer,
  teamMemberCreation: teamMemberCreateReducer,
  teamMemberUpdation: teamMemberUpdateReducer,
  teamMemberDeletion: teamMemberDeleteReducer,
  getRolesList: rolesListReducer,
  getManagerList: expenseManagerListReducer,
  getSubscriptionHistoryList: SubscriptionHistoryListReducer,
  getSubscriptionPlanList: SubscriptionPlanListReducer,
  getuserProfile: userProfileReducer,
  userProfileUpdation: userProfileUpdateReducer,
  tdsCalculation: tdsCalculationReducer, 
  postLedgerList :postLedgerModuleListReducer,
  createAPinvoice:CreateAPinvoiceReducer, 
  error:errorReducer,
  addZohoCrmCreate: zohoCrmCreateReducer,
  toCheckZohoOrgId:checkZohoOrgIdReducer,
  zohoAuthRedirection:zohoAuthReducer,

});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
