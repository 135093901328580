import React from "react";
import { useDispatch } from "react-redux";
import { addVendor } from "../../redux/actions/VendorAction";
import CreateModal from "../../_components/modalbox/createmodal";
import PropTypes from 'prop-types';
import AddEditVendorModal from "./AddEditVendor";
import { formatDateYYMMDD } from "../../sales/invoices/constant";

const CreateVendorModal = ({
  addData,
  modalRef,
  show,
  handleSubmit,
  control,
  setApiError,
  apiError,
  errors,
  setAddData,
  setFile,
  file,
  addVendorLoading,
}) => {
  const dispatch = useDispatch();

  const handleAddSubmit = (data) => {
    const form_data = new FormData();
    if (file) {
      form_data.append("tds_certificate", file[0]);
    }
    const postData = {
      name: data?.name ? data?.name : "",
      vendor_code: addData?.vendor_code ? addData?.vendor_code : "",
      address: data?.address ? data?.address : "",
      zip_code: data?.zip_code ? data?.zip_code : "",
      pan_number: data?.pan_number ? data?.pan_number : "",

      approved_amount: addData?.approved_amount ? addData?.approved_amount : "",
      tds_certificate_number: addData?.tds_certificate_number
        ? addData?.tds_certificate_number
        : "",
      gstin_number: data?.gstin_number ? data?.gstin_number : "",
      state: data?.state ? data?.state : "",
      state_code: data?.state_code ? data?.state_code : "",
      phone: data?.phone ? data?.phone : "",
      contact_name: data?.contact_name ? data?.contact_name : "",
      email: data?.email ? data?.email : "",
      msme: addData?.msme ? addData?.msme : "",
      bank_name: addData?.bank_name ? addData?.bank_name : "",
      branch_name: data?.branch_name ? data?.branch_name : "",
      account_number: addData?.account_number ? addData?.account_number : "",
      ifsc_code: addData?.ifsc_code ? addData?.ifsc_code : "",
      swift: addData?.swift ? addData?.swift : "",
    };
    if (addData?.start_date) {
      postData.start_date = formatDateYYMMDD(addData.start_date);
    }
    if (addData?.end_date) {
      postData.end_date = formatDateYYMMDD(addData.end_date);
    }
    // Append postData properties to form_data
    for (const key in postData) {
      // eslint-disable-next-line react/prop-types
      // if (postData.hasOwnProperty(key)) {
        form_data.append(key, postData[key]);
      // }
    }
    // Dispatch the addVendor action with the form_data
    dispatch(addVendor(form_data));
  };

  const handleChange = (e) => {
    const { id } = e.target;
    const editId = id?.split("-")[1];
    setApiError((prevState) => ({ ...prevState, [editId]: "" }));
  };

  const handleChangeField = (e) => {
    const { id,value } = e.target;
    const editId = id?.split("-")[1];
    setAddData((prevState)=>({...prevState,[editId]:value}))
    setApiError((prevState) => ({ ...prevState, [editId]: "" }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files);
    setApiError((prevState) => ({ ...prevState, file: "" }));
  };

  const addVendormodal = (
    <CreateModal
      id="add_vendor"
      modalRef={modalRef}
      show={show}
      title="Add Vendor"
      modalbody={
        <>
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-md-12">
              <AddEditVendorModal
                  id={"add_vendor"}
                  handleSubmit={handleSubmit}
                  handleEditSubmit={handleAddSubmit}
                  control={control}
                  handleChange={handleChange}
                  errors={errors}
                  apiError={apiError}
                  setAddData={setAddData}
                  addData={addData}
                  setApiError={setApiError}
                  handleFileChange={handleFileChange}
                  file={file}
                  addVendorLoading={addVendorLoading}
                  handleChangeField={handleChangeField}
                />
              
              </div>
            </div>
          </div>
        </>
      }
    />
  );
  return <>{addVendormodal}</>
};

CreateVendorModal.propTypes = {
  addData : PropTypes.object.isRequired,
  modalRef : PropTypes.object.isRequired,
  show : PropTypes.bool.isRequired,
  handleSubmit : PropTypes.func.isRequired,
  control : PropTypes.object.isRequired,
  setApiError : PropTypes.func.isRequired,
  apiError : PropTypes.object,
  errors : PropTypes.object.isRequired,
  setAddData : PropTypes.func.isRequired,
  setFile : PropTypes.func.isRequired,
  file : PropTypes.object,
  addVendorLoading : PropTypes.bool,
};

export default CreateVendorModal;
