import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { updateSideMenu } from "../redux/actions/UserAction";
import { useDispatch } from "react-redux";

const ar_submenu = ["/invoice-list", "/recurring-invoices"];
const reports_submenu = ["/dataprocessing", "/userlogs"];
const settings_submenu = [
  "/user-management",
  "/membership-plans",
  "/chartofaccount",
  "/crm-erp",
];

const Sidebar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userSignin);
  const { permissions, currentSideMenu } = userData;
  const toggleSidebar = (value) => {
    dispatch(updateSideMenu(value));
    localStorage.setItem("currentSideMenu", value);
  };

  const colorCondition = (state, submenu) => {
    if (
      currentSideMenu == state ||
      (currentSideMenu == state && submenu.includes(pathname)) ||
      (currentSideMenu == state &&
        ["arModule-edit-invoice", "recurring-edit-invoice"].includes(
          pathname.split("/").at(-2)
        ))
    ) {
      return "#7638FF ";
    } else if (
      submenu.includes(pathname) ||
      (["arModule-edit-invoice", "recurring-edit-invoice"].includes(
        pathname.split("/").at(-2)
      ) &&
        state === "arModule")
    ) {
      return "#7638FF ";
    }
  };

  useEffect(() => {
    if (
      ar_submenu?.includes(pathname) ||
      ["arModule-edit-invoice", "recurring-edit-invoice"].includes(
        pathname.split("/").at(-2)
      )
    ) {
      dispatch(updateSideMenu("arModule"));
    } else if (reports_submenu?.includes(pathname)) {
      dispatch(updateSideMenu("reportsModule"));
    } else if (
      !ar_submenu?.includes(pathname) &&
      !reports_submenu?.includes(pathname)
    ) {
      dispatch(updateSideMenu(""));
    }
  }, [pathname]);

  useEffect(() => {
    function handleMouseOver(e) {
      e.stopPropagation();
      if (
        document.body.classList.contains("mini-sidebar") &&
        document.querySelector("#toggle_btn").offsetParent !== null
      ) {
        var targ = e.target.closest(".sidebar");
        if (targ) {
          document.body.classList.add("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "block"));
        } else {
          document.body.classList.remove("expand-menu");
          document
            .querySelectorAll(".subdrop + ul")
            .forEach((ul) => (ul.style.display = "none"));
        }
        return false;
      }
    }
    document.addEventListener("mouseover", handleMouseOver);
    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul className="sidebar-vertical">
                {/* Main */}
                <li className="menu-title">
                  <span>Main</span>
                </li>
                <li className="submenu">
                  <Link
                    to="/index"
                    key={1}
                    className={pathname === "/index" ? "active" : ""}
                  >
                    <i className="fe fe-home" /> <span> Dashboard</span>{" "}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/apmodule"
                    className={
                      pathname === "/apmodule" ||
                      ["edit-invoice"].includes(pathname.split("/").at(-2))
                        ? "active"
                        : ""
                    }
                  >
                    <i className="fe fe-users" /> <span>AP module</span>
                  </Link>
                </li>{" "}
                <li className="submenu">
                  <a
                    className={currentSideMenu == "arModule" ? "subdrop active" : ""}
                    onClick={() =>
                      toggleSidebar(
                        currentSideMenu == "arModule" ? "" : "arModule"
                      )
                    }
                  >
                   
                      <i className="fe fe-file" /> 
                       <span
                      style={{
                        color: colorCondition("arModule", ar_submenu),
                      }}
                    >AR module
                    </span>
                    <span className="menu-arrow" />
                  </a>
                  {currentSideMenu === "arModule" && (
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link
                          className={
                            (currentSideMenu === "arModule" &&
                              pathname === "/invoice-list") ||
                            (currentSideMenu === "arModule" &&
                              ["arModule-edit-invoice"].includes(
                                pathname.split("/").at(-2)
                              ))
                              ? "active"
                              : ""
                          }
                          to={`/invoice-list`}
                        >
                          Invoice
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          to="/recurring-invoices"
                          className={
                            (pathname === "/recurring-invoices" &&
                              pathname === "/recurring-invoices") ||
                            (currentSideMenu === "arModule" &&
                              ["recurring-edit-invoice"].includes(
                                pathname.split("/").at(-2)
                              ))
                              ? "active"
                              : ""
                          }
                        >
                          Recurring Invoices
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li>
                  <Link
                    to="/banking"
                    className={
                      pathname === "/banking" ||
                      ["bankdetail"].includes(pathname.split("/").at(-2))
                        ? "active"
                        : ""
                    }
                  >
                    <i className="fe fe-credit-card" /> <span>Banking</span>
                  </Link>
                </li>
                {permissions?.reports && (
                  <>
                    <li className="submenu">
                      <a
                        className={
                          currentSideMenu == "reportsModule" ? "subdrop active" : ""
                        }
                        onClick={() =>
                          toggleSidebar(
                            currentSideMenu == "reportsModule"
                              ? ""
                              : "reportsModule"
                          )
                        }
                      >
                        
                          <i className="fe fe-file" /> <span
                          style={{
                            color: colorCondition(
                              "reportsModule",
                              reports_submenu
                            ),
                          }}
                        >Reports</span>
                       
                        <span className="menu-arrow" />
                      </a>
                      {currentSideMenu === "reportsModule" && (
                        <ul style={{ display: "block" }}>
                          <li>
                            <Link
                              to="/dataprocessing"
                              className={
                                pathname === "/dataprocessing" ? "active" : ""
                              }
                            >
                              Data Processing Logs
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/userlogs"
                              className={
                                pathname === "/userlogs" ? "active" : ""
                              }
                            >
                              User Logs
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  </>
                )}{" "}
                {/* User Management */} {/* /User Management */}
                {/* Membership) */} {/* /Membership) */}
                {/* Content (CMS) */} {/* /Content (CMS) */} {/* /Support */}
                {/* Pages */} {/* /Pages */}
                {/* UI Interface */} {/* /UI Interface */}
                {/* Settings */}
                {permissions?.settings && (
                  <>
                    <li className="menu-title">
                      <span>Settings</span>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname === "/settings" ||
                          settings_submenu?.includes(pathname)
                            ? "active"
                            : ""
                        }
                        to="/settings"
                      >
                        <i className="fe fe-settings" /> <span>Settings</span>
                      </Link>
                    </li>
                  </>
                )}{" "}
                {/* Extras */} {/* Extras */}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
export default Sidebar;
