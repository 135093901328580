/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { message, Spin, Select } from "antd";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Controller, useForm } from "react-hook-form";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import Select from "react-select";
import { formatDateYYMMDD } from "./constant";
import CustomInput from "../../_components/inputField/custominput";
import { getLedgerModuleList } from "../../redux/actions/LedgerModuleAction";
import { AP_INVOICE_CREATE_RESET } from "../../redux/constants/APmoduleConstant";
import {
  CreateAPinvoice,
  getTdsRateList,
  tdsCalculationApi,
} from "../../redux/actions/APmoduleAction";
import { postLedgerModuleList } from "../../redux/actions/LedgerModuleAction";
import Header from "../../layouts/Header";
import {
  getCompanyList,
  getTdsSectionList,
} from "../../redux/actions/commonApiAction";
import Sidebar from "../../layouts/Sidebar";
import { getVendorSearchList } from "../../redux/actions/VendorAction";
import CustomSelect from "../../customers/customSelect";
import AddLedgerModal from "../../customers/addLedgerModal";
import { POST_LEDGERLIST_RESET } from "../../redux/constants/ledgerListModuleConstant";
import useUpdateFooterData from "../../customers/updateFooterData";
import useCalculateTotals from "../../helpers/calculation";

const schema = yup.object({
  vendor_id: yup
  .number()
  .required("Vendor ID is required")
  .typeError("Vendor ID must be a number"), 
  invoice_number: yup
    .string()
    .required("Invoice number is required")
    .trim(),
  nature_of_supply: yup
    .string()
    .required("Nature of supply is required")
    .trim(),
  invoice_date: yup
    .date()
    .required("Invoice date is required")
    .nullable()
    .required("Invoice date is required"),
});

const addInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const apModuleInvoiceADD = useSelector((state) => state.createAPinvoice);
  const ledgerList = useSelector((state) => state?.ledgerList);
  const { loading: ledgerListLoading, data: expenseList } = ledgerList;

  const companyList = useSelector((state) => state.companyList);
  const { company } = companyList;

  const tdsSectionList = useSelector((state) => state?.tdsSectionList);
  const { loading: tdsSectionListLoading, tdsSectionData } = tdsSectionList;

  const { success, loading, error } = apModuleInvoiceADD;

  const tdsCalculation = useSelector((state) => state.tdsCalculation);
  const {
    tdsAmount,
    loading: tdsCalculationLoading,
    error: tdsCalculationError,
  } = tdsCalculation;

  const postLedgerList = useSelector((state) => state?.postLedgerList);
  const {
    loading: postLedgerLoading,
    error: postLedgerError,
    success: postLedgerSuccess,
  } = postLedgerList;

  const selectRef = useRef(null);
  const vendorSelectRef = useRef(null);

  useEffect(() => {
    if (postLedgerError) {
      setApiError(postLedgerError);
    }
  }, [postLedgerError]);

  const onModalOpen = () => {
    $("#add_ledger").on("shown.bs.modal", () => {
      setIsModalVisible(true);
      setAddLedgerData({ title: "", type: "", description: "" });
      setApiError({});
    });
  };

  const onModalClose = () => {
    $("#add_ledger").on("hide.bs.modal", () => {
      setIsModalVisible(false);
      setAddLedgerData({ title: "", type: "", description: "" });
    });
  };

  useEffect(() => {
    onModalOpen();
    onModalClose();
  }, []);

  useEffect(() => {
    if (postLedgerSuccess) {
      dispatch({ type: POST_LEDGERLIST_RESET });
      dispatch(getLedgerModuleList("", "", true, ""));
      handleModalClose("#add_ledger");
      setAddLedgerData({});
      setApiError({});
      setIsModalVisible(false);
      messageApi.open({
        type: "success",
        content: "Ledger Created Successfully",
      });
    }
  }, [postLedgerSuccess]);

  const [menu, setMenu] = useState();
  const [addData, setAddData] = useState({ vendor_id: null });
  const [editError, setEditError] = useState({});
  const [itemId, setItemId] = useState("");
  const [page, setPage] = useState({ page: 1, perPage: 20 });
  const [data, setData] = useState([
    {
      posting_ledger: null,
      description: "",
      quantity: "",
      rate: "",
      gst_rate: "",
      gst_amount: "",
      amount: "",
      total: "",
      taxes: [],
      tds_ledger: null,
      tds_amount: "",
    },
  ]);
  const [footerData, setFooterData] = useState({});
  const [editable, setEditable] = useState({});
  const [calculationData, setCalculationData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [addLedgerData, setAddLedgerData] = useState({
    title: "",
    type: "",
    description: "",
  });
  const [vendorList, setVendorList] = useState([]);
  const [apiError, setApiError] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [itemsError, setItemsError] = useState([
    {
      ledger: "",
      description: "",
      quantity: "",
      rate: "",
      amount: "",
      gst_rate: "",
      gst_amount: "",
      total: "",
    },
  ]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
    setIsModalVisible(false);
    setAddLedgerData({
      title: "",
      type: "",
      description: "",
    });
    setApiError({});
  };

  const [isChanged, setIsChanged] = useState(false);

  const datePickerRef = useRef(null);
  const selectRefs = useRef({});

  const totals = useCalculateTotals(data);

  useUpdateFooterData(footerData, setFooterData, totals, [], company, addData);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleCellChange = (rowId, field, value, index) => {
    setCalculationData((prevState) => ({
      ...prevState,
      indexId: index,
      key: field,
    }));
    const updatedData = [...data];
    updatedData[index][field] = value;
    setData(updatedData);
    if (
      updatedData[index] &&
      updatedData[index]["rate"] &&
      updatedData[index]["quantity"]
    ) {
      const updatedError = [...itemsError];
      delete updatedError[index]["total"];
      delete updatedError[index][field];
      setItemsError(updatedError);
    }
    const updatedError = [...itemsError];
    if(updatedError[index]){
      delete updatedError[index][field];
      setItemsError(updatedError);
    }
    setIsChanged(true);
  };

  const handleSelectCellChange = (selectedOption, id, rowKey, index, row) => {
    const updatedData = [...data];
    updatedData[index][id] = selectedOption;

    if (id === "tds_ledger") {
      const item = {
        posting_ledger: row?.posting_ledger,
        tds_ledger: row?.tds_ledger?.value,
        description: row?.description,
        product_code: row?.product_code,
        quantity: row?.quantity,
        amount: row?.gst_amount ? parseFloat(row?.gst_amount) : "",
        discount: row?.discount,
        rate: row?.gst_rate,
        total: row?.total ? parseFloat(row?.total) : "",
      };
      setData(updatedData);
      setItemId(index);
    console.log(row,'row')

      console.log('called')
      const data = {
        tds_section_id: selectedOption,
        item: item,
      };
      // console.log(data,'dataaaas')
      // console.log(selectedOption,'dataaaasd')


      dispatch(tdsCalculationApi(data));
      setIsChanged(true);
    }

    setData(updatedData);

    setIsChanged(true);
  };

  useEffect(() => {
    if (
      calculationData?.indexId !== "" &&
      calculationData?.key !== "gst_amount" &&
      calculationData?.key !== "amount" &&
      calculationData?.key !== "total"
    ) {
      const updatedData = [...data];
      if (updatedData[calculationData?.indexId]) {
        const rate =
          parseFloat(updatedData[calculationData?.indexId]["rate"]) || 0;
        const quantity =
          parseFloat(updatedData[calculationData?.indexId]["quantity"]) || 0;
        const gstRate =
          parseFloat(updatedData[calculationData?.indexId]["gst_rate"]) || 0;
        const amount = rate * quantity;

        updatedData[calculationData?.indexId]["amount"] = amount.toFixed(2);

        const gstAmount = (amount * gstRate) / 100;
        updatedData[calculationData?.indexId]["gst_amount"] = gstAmount.toFixed(
          2
        );

        const total = amount + gstAmount;
        updatedData[calculationData?.indexId]["total"] = total.toFixed(2);
      }
      setData(updatedData);
    }
  }, [calculationData]);

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  const handleAddClick = (event) => {
    event.preventDefault();
    setData([
      ...data,
      {
        posting_ledger: null,
        description: "",
        quantity: "",
        rate: "",
        amount: "",
        gst_rate: "",
        gst_amount: "",
        tds_ledger: null,
        tds_amount: "",
      },
    ]);
    setAddLedgerData({ title: "", type: "", description: "" });
  };

  const handleRemove = (event, tabIndex) => {
    event.preventDefault();
    const filterData = data?.filter((el, index) => index !== tabIndex);
    setIsChanged(true);
    setData(filterData);
  };

  const renderCell = (rowId, field, value, index, row) => {
    if (editable.rowId === rowId && editable.field === field) {
      if (field === "posting_ledger") {
        return (
          <>
            <CustomSelect
              id={"posting_ledger"}
              value={value}
              onChange={(selectedOption) => {
                handleSelectCellChange(
                  selectedOption,
                  "posting_ledger",
                  rowId,
                  index,
                  row
                );
              }}
              options={expenseList?.length ? expenseList : []}
              addLedgerData={addLedgerData}
              setAddLedgerData={setAddLedgerData}
              handleSubmit={handleSubmit}
              handleEditSubmit={handleAddSubmit}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              control={control}
              errors={errors}
              handleModalClose={handleModalClose}
              setApiError={setApiError}
              apiError={apiError}
            />
          </>
        );
      } else if (field === "tds_ledger") {
        return (
          <Select
            value={value}
            options={
              tdsSectionData?.results?.length
                ? tdsSectionData?.results?.map((option) => ({
                    value: option.id,
                    label: option.section_name,
                  }))
                : []
            }
            isDisabled={!row?.description}
            onChange={(selectedOption) => {
              handleSelectCellChange(
                selectedOption,
                "tds_ledger",
                rowId,
                index,
                row
              );
            }}
          />
        );
      } else if (field === "tds_amount") {
        return (
          <input
            type={"text"}
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index)
            }
          />
        );
      } else {
        return (
          <input
            style={{ cursor: "pointer" }}
            type={field !== "quantity" ? "text" : "number"}
            disabled={
              field === "amount" || field === "gst_amount" || field === "total"
            }
            required
            value={value}
            onChange={(e) =>
              handleCellChange(rowId, field, e.target.value, index)
            }
            onBlur={() => setEditable({})}
            autoFocus
          />
        );
      }
    }
    if (field === "posting_ledger") {
      return (
        <CustomSelect
          id={"posting_ledger"}
          value={value}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "posting_ledger",
              rowId,
              index,
              row
            );
          }}
          required
          options={expenseList?.length ? expenseList : []}
          addLedgerData={addLedgerData}
          setAddLedgerData={setAddLedgerData}
          handleSubmit={handleSubmit}
          handleEditSubmit={handleAddSubmit}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          control={control}
          errors={errors}
          handleModalClose={handleModalClose}
          setApiError={setApiError}
          apiError={apiError}
        />
      );
    } else if (field === "tds_ledger") {
      return (
        <Select
          value={value}
          options={tdsSectionData?.results?.map((option) => ({
            value: option.id,
            label: `${option.section_id} - ${option.section_name}`,
          }))}
          isDisabled={!row?.description}
          onChange={(selectedOption) => {
            handleSelectCellChange(
              selectedOption,
              "tds_ledger",
              rowId,
              index,
              row
            );
          }}
        />
      );
    } else if (field === "tds_amount") {
      return (
        <input
          type={"text"}
          value={value}
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index)
          }
        />
      );
    } else {
      return (
        <input
          type={field !== "quantity" ? "text" : "number"}
          value={value}
          disabled={
            field === "amount" || field === "gst_amount" || field === "total"
          }
          required
          onChange={(e) =>
            handleCellChange(rowId, field, e.target.value, index)
          }
          // onDoubleClick={() => handleDoubleClick(rowId, field)}
        />
      );
    }
  };

  const handleDoubleClick = (rowId, field) => {
    setEditable({ rowId, field });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setIsChanged(true);
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleLedgerChange = (e) => {
    const { id, value } = e.target;
    setAddLedgerData((prevState) => ({ ...prevState, [id]: value }));
    setApiError((prevState) => ({ ...prevState, [id]: "" }));
  };

  const handleCommonChange = (e) => {
    const { id, value } = e.target;
    setAddData((prevState) => ({ ...prevState, [id]: value }));
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  useEffect(() => {
    dispatch(getTdsRateList());
  }, []);

  // useEffect(() => {
  //   setEditError(error);
  // }, [error]);

  useEffect(() => {
    console.log("erroradd", error);
    if (error) {
      setItemsError(
        error?.items
          ? error?.items
          : [
              {
                ledger: "",
                description: "",
                quantity: "",
                rate: "",
                amount: "",
                gst_rate: "",
                gst_amount: "",
                total: "",
              },
            ]
      );
    } else {
      setEditError(error?.error ? error?.error : {});
    }
  }, [error]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: addData,
  });

  useEffect(() => {
    const fieldOrder = [
      "vendor_id",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
    ];
    const firstErrorField = fieldOrder.find((field) => errors && errors[field]);
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "vendor_id") {
        inputElement = selectRefs.current[firstErrorField];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const fieldOrder = [
      "vendor_id",
      "vendor_code",
      "vendor_gstin",
      "invoice_number",
      "amount",
      "total",
      "invoice_date",
      "nature_of_supply",
      "detail",
    ];
    const firstErrorField = fieldOrder.find(
      (field) => editError && editError[field]
    );
    if (firstErrorField) {
      let inputElement;
      if (firstErrorField === "detail") {
        inputElement = selectRefs.current["tds_rate"];
      } else if (firstErrorField === "vendor_id") {
        inputElement = selectRefs.current["vendor_id"];
      } else {
        inputElement = document.getElementById(firstErrorField);
      }
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [editError]);

  const handleAddSubmit = (datas) => {
    console.log(data,'data')
    const ledger = data?.map((ele) => ({
      ...ele,
      posting_ledger: ele?.posting_ledger,
      tds_ledger: ele?.tds_ledger,
      tds_amount: ele?.tds_amount ? parseFloat(ele?.tds_amount).toFixed(2) : 0,
    }));
    if (isModalVisible) {
      const postData = {
        title: addLedgerData?.title,
        type: addLedgerData?.type,
        description: addLedgerData?.description,
      };
      dispatch(postLedgerModuleList(postData));
    } else {
      const postData = {
        vendor_id: datas?.vendor_id,
        vendor_code: datas?.vendor_code,
        vendor_gstin: datas?.vendor_gstin,
        invoice_number: datas?.invoice_number ? datas?.invoice_number : null,
        invoice_date: datas?.invoice_date
          ? formatDateYYMMDD(datas?.invoice_date)
          : null,
        expense_ledger: datas?.expense_ledger?.value,
        nature_of_supply: datas?.nature_of_supply
          ? datas?.nature_of_supply
          : "",
        items: ledger,
        // tds_rate: addData?.tds_rate ? addData?.tds_rate?.value : "",
      };
      dispatch(CreateAPinvoice(postData));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: AP_INVOICE_CREATE_RESET });
      setItemId("");
      setData([
        {
          posting_ledger: null,
          description: "",
          quantity: "",
          rate: "",
          gst_rate: "",
          gst_amount: "",
          amount: "",
          total: "",
          taxes: [],
          tds_ledger: null,
          tds_amount: "",
        },
      ]);
      setAddData({
        vendor_id: null,
        vendor_gstin: "",
        invoice_number: "",
        nature_of_supply: "",
        invoice_date: null,
      });
      setItemsError([
        {
          ledger: "",
          description: "",
          quantity: "",
          rate: "",
          amount: "",
          gst_rate: "",
          gst_amount: "",
          total: "",
          tax_rate: "",
          product_code: "",
        },
      ]);
      messageApi.open({
        type: "success",
        content: "Invoice Created Successfully",
      });
      history.push("/apmodule");
    }
  }, [success]);

  useEffect(() => {
    if (tdsAmount) {
      const updatedData = [...data];
      if (updatedData[itemId]) {
        updatedData[itemId]["tds_amount"] = tdsAmount?.tds_amount
          ? parseFloat(tdsAmount?.tds_amount)?.toFixed(2)
          : 0;
        setData(updatedData);
      }
    }
  }, [itemId, tdsAmount]);

  const vendorListSearch = useSelector((state) => state.vendorListSearch);
  const { vendorSearchList, loading: vendorListLoading } = vendorListSearch;

  useEffect(() => {
    dispatch(getVendorSearchList("", page?.page, page?.perPage));
  }, [page?.page, page?.perPage]);

  useEffect(() => {
    if (addData?.vendor_id) {
      const filteredVendor = vendorSearchList?.results?.find(
        (el) => el?.id === addData?.vendor_id
      );
console.log("filteredVendor",filteredVendor,vendorSearchList)
      setAddData((prevState) => ({
        ...prevState,
        vendor_code: filteredVendor?.vendor_code
          ? filteredVendor?.vendor_code
          : "",
        vendor_gstin: filteredVendor?.gstin_number
          ? filteredVendor?.gstin_number
          : "",
      }));
    }
  }, [vendorSearchList, addData?.vendor_id]);

  useEffect(() => {
    if (vendorSearchList?.results) {
      const flatennedVendorList = [
        ...vendorList,
        vendorSearchList?.results,
      ]?.flat();
      console.log(flatennedVendorList, "flatennedVendorList");

      setVendorList(flatennedVendorList);
    }
  }, [vendorSearchList]);
  console.log(vendorList,vendorSearchList,addData?.vendor_id, "vendorList");

  useEffect(() => {
    dispatch(getLedgerModuleList("", "", true, ""));
    dispatch(getTdsSectionList());
  }, [dispatch]);

  const handleDateChange = (id) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
  };

  const handlePopupScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (
      scrollHeight - scrollTop <= clientHeight + 10 &&
      !vendorListLoading &&
      vendorSearchList?.results?.length
    ) {
      // fetchVendors(page.current);
      console.log(e.target, "checkkjkjkj");
      setPage((prevState) => ({
        ...prevState,
        page: prevState?.page + 1,
        perPage: prevState?.perPage,
      }));
    }
  };

  const handleSelectChange = (id, selectedValue) => {
    setEditError((prevState) => ({ ...prevState, [id]: "" }));
    setIsChanged(true);
    console.log("selectedValue", selectedValue);
    setAddData((prevState) => ({
      ...prevState,
      [id]: selectedValue, // Here you're setting the vendor ID
    }));
  };

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        {contextHolder}
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="card mb-0">
              <div className="card-body">
                {/* Page Header */}
                <div className="page-header">
                  <div className="content-page-header">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/apmodule">
                          <a>AP module</a>
                        </Link>
                      </li>
                      <li className="breadcrumb-item active">Add Invoice</li>
                    </ul>
                  </div>
                </div>
                {/* /Page Header */}
                <div className="row">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit(handleAddSubmit)}>
                      <div className="">
                        <div className="">
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>
                                  Vendor Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="vendor_id"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <Select
                                        id="vendor_id"
                                        {...field}
                                        ref={vendorSelectRef}
                                        placeholder="Select a vendor"
                                        style={{ width: "100%" }}
                                        onPopupScroll={handlePopupScroll}
                                        value={field.value} // Ensure the value prop is set to field.value
                                        onChange={(value) => {
                                          field.onChange(value); // Call the onChange from field to update form state
                                          handleSelectChange("vendor_id", value); // Your custom handler if needed
                                        }}
                                        dropdownRender={(menu) => (
                                          <>
                                            {menu}
                                            {vendorListLoading && (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  padding: 8,
                                                }}
                                              >
                                                <Spin />
                                              </div>
                                            )}
                                          </>
                                        )}
                                      >
                                        {vendorList
                                          ?.map((option) => ({
                                            value: option.id,
                                            label: option.name,
                                          }))
                                          .map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </Option>
                                          ))}
                                      </Select>
                                      {errors?.vendor_id?.message && (
                                        <p className="text-danger">
                                          {errors?.vendor_id?.message}
                                        </p>
                                      )}
                                      {editError?.vendor_id && (
                                        <p className="text-danger">
                                          {editError?.vendor_id}
                                        </p>
                                      )}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <CustomInput
                                  id="vendor_code"
                                  type="text"
                                  value={addData?.vendor_code}
                                  onChange={handleCommonChange}
                                  placeholder="Vendor Code"
                                  label="Vendor Code"
                                  disabled={true}
                                  apiError={editError?.vendor_code}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <CustomInput
                                  id="vendor_gstin"
                                  type="text"
                                  value={addData?.vendor_gstin}
                                  onChange={handleCommonChange}
                                  placeholder="Vendor GSTIN"
                                  label="Vendor GSTIN"
                                  disabled={true}
                                  apiError={editError?.vendor_gstin}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <Controller
                                  name="invoice_number"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <>
                                      <CustomInput
                                        id="invoice_number"
                                        type="text"
                                        value={value}
                                        onChange={(e) => {
                                          handleChange(e);
                                          onChange(e);
                                        }}
                                        placeholder="Invoice Number"
                                        label="Invoice Number"
                                        errorMsg={
                                          errors?.invoice_number?.message
                                        }
                                        error={errors?.invoice_number}
                                        apiError={editError?.invoice_number}
                                        autoComplete="false"
                                        required={true}
                                      />
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <label>
                                  Invoice Date
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="cal-icon cal-icon-info">
                                  <Controller
                                    name="invoice_date"
                                    control={control}
                                    render={({ field }) => (
                                      <div className="cal-icon cal-icon-info">
                                        <DatePicker
                                          {...field}
                                          className="datetimepicker form-control"
                                          selected={field?.value}
                                          onChange={(date) =>
                                            handleDateChange(
                                              "invoice_date",
                                              date,
                                              field?.onChange(date)
                                            )
                                          }
                                          id="invoice_date"
                                          ref={datePickerRef}
                                        />
                                      </div>
                                    )}
                                  />
                                  {errors?.invoice_date?.message && (
                                    <p className="text-danger">
                                      {errors?.invoice_date?.message}
                                    </p>
                                  )}
                                  {editError?.invoice_date && (
                                    <p className="text-danger">
                                      {editError?.invoice_date}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <div className="input-block mb-3">
                                <Controller
                                  name="nature_of_supply"
                                  control={control}
                                  render={({ field: { value, onChange } }) => (
                                    <CustomInput
                                      id="nature_of_supply"
                                      type="text"
                                      value={value}
                                      onChange={(e) => {
                                        handleChange(e);
                                        onChange(e);
                                      }}
                                      placeholder="Nature of Supply"
                                      label="Nature of Supply"
                                      errorMsg={
                                        errors?.nature_of_supply?.message
                                      }
                                      error={errors?.nature_of_supply}
                                      apiError={editError?.nature_of_supply}
                                      autoComplete="false"
                                      required={true}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="complex-invoice-table">
                        <div
                          className="ag-theme-alpine"
                          style={{ width: "100%" }}
                        >
                          <h6>Invoice Intent</h6>
                          <div
                            className="tableHscroll"
                            style={{
                              width: "100%",
                              minHeight: "300px",
                              overflowY: "visible",
                            }}
                          >
                            <table className="apedittable">
                              <thead>
                                <tr>
                                  <td align="center">Sl No.</td>
                                  <td>Ledger Name</td>
                                  <td>Description</td>
                                  <td>Unit</td>
                                  <td>Unit Price</td>
                                  <td>Base Amount</td>
                                  <td>GST Rate</td>
                                  <td>GST Amount</td>
                                  <td>Total</td>
                                  <td>Tds Ledger</td>
                                  <td>Tds Amount</td>
                                  <td>&nbsp;</td>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.map((row, index) => (
                                  <tr key={index}>
                                    <td align="center"> {index + 1}</td>
                                    <td className="customtableSelect">
                                      {renderCell(
                                        row.id,
                                        "posting_ledger",
                                        row?.posting_ledger,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "description",
                                        row.description,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "quantity",
                                        row.quantity,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "rate",
                                        row.rate,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "amount",
                                        row.amount,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "gst_rate",
                                        row.gst_rate,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "gst_amount",
                                        row.gst_amount,
                                        index
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "total",
                                        row.total,
                                        index
                                      )}
                                    </td>
                                    <td className="customtableSelect">
                                      {renderCell(
                                        row.id,
                                        "tds_ledger",
                                        row.tds_ledger,
                                        index,
                                        row
                                      )}
                                    </td>
                                    <td>
                                      {renderCell(
                                        row.id,
                                        "tds_amount",
                                        row.tds_amount,
                                        index
                                      )}
                                    </td>
                                    <td className="tableEditBtns">
                                      <button
                                        className="btn btn-default btn-xs delete"
                                        onClick={(event) =>
                                          handleRemove(event, index)
                                        }
                                      >
                                        <i className="fas fa-trash" />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                                
                                <tr>
                                  <td colSpan={12}><div className="stickyAddBtn">
                                  <button
                                    className="btn btn-greys btn-xs delete"
                                    onClick={handleAddClick}
                                  >
                                    <i className="far fa-add" />
                                  </button>
                                </div></td>
                                </tr>
                                <tr>
                                  <td colSpan={12}></td>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={9}></td>
                                    <td align="left">
                                      <strong>Base Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_base_amount
                                        ? footerData?.total_base_amount
                                        : 0}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                {footerData?.gst_section &&
                                  Object.keys(footerData?.gst_section)?.map(
                                    (el, index) => {
                                      return (
                                        <tr key={index} className="rowStickty">
                                          <>
                                            <td colSpan={9}></td>
                                            <td align="left">
                                              <strong>{el}</strong>
                                            </td>
                                            <td align="right">
                                              {footerData?.gst_section
                                                ? footerData["gst_section"][el]
                                                : ""}
                                            </td>
                                            <td>&nbsp;</td>
                                          </>
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={9}></td>
                                    <td align="left">
                                      <strong>Total Tax</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_tax
                                        ? footerData?.total_tax
                                        : 0}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={9}></td>
                                    <td align="left">
                                      <strong>Total Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_amount}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={9}></td>
                                    <td align="left">
                                      <strong>Tds Total Amount</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.total_tds_amount}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                                <tr className="rowStickty">
                                  <>
                                    <td colSpan={9}></td>
                                    <td align="left">
                                      <strong>Amount Payable</strong>
                                    </td>
                                    <td align="right">
                                      {footerData?.amount_payable}
                                    </td>
                                    <td>&nbsp;</td>
                                  </>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <AddLedgerModal
                            addLedgerData={addLedgerData}
                            apiError={apiError}
                            handleChange={handleLedgerChange}
                            handleModalClose={handleModalClose}
                            handleEditSubmit={handleAddSubmit}
                            loading={postLedgerLoading}
                          />
                        </div>
                      </div>
                      {itemsError?.length && error?.items && (
            <>
              {itemsError?.map((el, index) => {
                return (
                  <>
                    {Object?.keys(el)?.length ? (
                      <p className="text-danger">
                        {`row ${index + 1}`} :{" "}
                        {Object?.keys(el)?.map(
                          (keys) => `${keys} : ${el[keys]}`
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </>
          )}
                      <div class="row">
                        <div class="col">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isChanged || loading}
                          >
                            {loading ? <Spin /> : "Save"}
                          </button>
                          &nbsp;
                          <button
                            type="reset"
                            class="btn btn-primary cancel me-2"
                            onClick={() => history.push("/apmodule")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default addInvoice;
