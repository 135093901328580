/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import "../../_components/antd.css";
import { Table, message } from "antd";
import PropTypes from "prop-types";
import {
  onShowSizeChange,
  itemRender,
} from "../../_components/paginationfunction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCustomerModuleList,
  setPage,
} from "../../redux/actions/CustomerModuleAction";
import { Link } from "react-router-dom";
import { CUSTOMER_ADD_RESET } from "../../redux/constants/CustomerModuleCnstant";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { customerFieldOrder } from ".";
import CustomerAdd from "./customerAdd";

const schema = yup.object({
  name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  contact_name: yup
    .string()
    .required("Please fill this field")
    .trim(),
  location: yup
    .string()
    .required("Please fill this field")
    .trim(),
  email: yup
    .string()
    .required("Please fill this field")
    .trim(),
  gstin_number: yup
    .string()
    .required("Please fill this field")
    .trim(),
  pan_number: yup
    .string()
    .required("Please fill this field")
    .trim(),
  state: yup
    .string()
    .required("Please fill this field")
    .trim(),
  phone: yup
    .string()
    .required("Please fill this field")
    .trim(),
  address: yup
    .string()
    .required("Please fill this field")
    .trim(),
  zip_code: yup
    .string()
    .required("Please fill this field")
    .trim(),
});

const CustomerList = ({ activeTab }) => {
  const [addCustomer, setAddCustomer] = useState({});
  const [show, setShow] = useState(false);

  const customerModuleList = useSelector((state) => state?.customerModuleList);
  const { loading, error, page, perPage, customerList } = customerModuleList;
  const createCustomer = useSelector((state) => state?.createCustomer);
  const [apiError, setApiError] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const {
    loading: createCustomerLoading,
    error: createCustomerError,
    success,
  } = createCustomer;

  const modalRef = useRef(null);
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const columns = [
    {
      title: "ID",
      dataIndex: "index",
      render: (text, record, index) =>
        perPage * (page - 1) + index + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: (a, b) => a.address.length - b.address.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.length - b.phone.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Pan Number",
      dataIndex: "pan_number",
      sorter: (a, b) => a.pan_number.length - b.pan_number.length,
    },
    {
      title: "Action",
      dataIndex: "Action",
      render: (text, record) => (
        <>
            <Link
              to={`/customeredit/${record?.id}`}
              className="btn btn-primary me-2"
            >
              <i className="far fa-edit" />
            </Link>
            <Link  className="btn btn-greys" to={"#"}>
            <i className="far fa-send" />
            </Link>
        </>
      ),
    },
  ];
  useEffect(() => {
    setApiError(createCustomerError);
  }, [createCustomerError]);

  const handleModalClose = (id) => {
    $(id).modal("hide");
    $(".modal-backdrop").remove();
  };

  const closeModal = () => {
    setShow(false); // This will hide the modal
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: CUSTOMER_ADD_RESET });
      dispatch(getCustomerModuleList(page, perPage));
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      handleModalClose("#add_customer");
      closeModal();
      messageApi.open({
        type: "success",
        content: "customer created successfully",
      });
    }
  }, [success]);

  const inputValues = {
    name: "",
    contact_name: "",
    email: "",
    gstin_number: "",
    pan_number: "",
    state: "",
    phone: "",
    address: "",
    zip_code: "",
  };

  const {
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: inputValues,
  });

  useEffect(() => {
    const firstErrorField = customerFieldOrder.find((field) => errors[field]);
    if (firstErrorField) {
      const inputElement = document.getElementById(firstErrorField);
      if (inputElement) {
        inputElement.focus();
      }
    }
  }, [errors]);

  useEffect(() => {
    const apiErrorField = customerFieldOrder.find(
      (field) => apiError && apiError[field]
    );
    if (apiErrorField) {
      const apiInputElement = document.getElementById(apiErrorField);
      if (apiInputElement) {
        apiInputElement.focus();
        setIsDisable(false);
      }
    }
  }, [apiError]);

  const onModalClose = () => {
    $("#add_customer").on("hide.bs.modal", () => {
      setApiError({});
      setAddCustomer({
        pan_number: "",
        gstin_number: "",
        name: "",
        zoho_contact_id: null,
        customer_code: "",
        address: "",
        zip_code: "",
        state: "",
        phone: "",
        contact_name: "",
        email: "",
        zoho_address_id: null,
        location: "",
      });
      reset();
    });
  };

  useEffect(() => {
    onModalClose();
  }, []);

  const handleTableChange = (page, perPage) => {
    dispatch(setPage(page, perPage));
  };

  useEffect(() => {
    if (activeTab?.includes("#customerlist")) {
      dispatch(getCustomerModuleList(page, perPage));
    }
  }, [dispatch, page, perPage, activeTab]);

  return (
    <>
      {contextHolder}
      <div className="row">
        <div className="col-sm-12">
          <Link
            className="btn btn-primary addBtn"
            data-bs-toggle="modal"
            to="#add_customer"
            role="button"
          >
            <i className="fa fa-plus-circle me-2" aria-hidden="true" />
            Create Customer
          </Link>
          <div className=" card">
            <div className="card-body">
              {error && <p style={{ color: "red" }}>{error}</p>}
              <div className="table-responsive">
                <Table
                  loading={loading}
                  pagination={{
                    total: customerList?.count,
                    current: page,
                    pageSize: perPage,
                    onChange: handleTableChange,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  columns={columns}
                  dataSource={customerList?.results}
                  rowKey={(record) => record.Id}
                  className="chartofAccTables"
                />
              </div>
            </div>
          </div>
        </div>
        <CustomerAdd
          addCustomer={addCustomer}
          setIsDisable={setIsDisable}
          modalRef={modalRef}
          show={show}
          handleSubmit={handleSubmit}
          control={control}
          setApiError={setApiError}
          apiError={apiError}
          setAddCustomer={setAddCustomer}
          errors={errors}
          isDisable={isDisable}
          createCustomerLoading={createCustomerLoading}
        />
      </div>
    </>
  );
};
// Define prop types
CustomerList.propTypes = {
  activeTab: PropTypes.string,
};
export default CustomerList;
