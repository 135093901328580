import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { Image } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const ImagePreview = ({ src, width, height }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isPDF = src && src.includes(".pdf");

  const handlePreview = () => {
    window.open(src, "_blank");
  };

  return (
    <div
      className="image-container"
      style={{ position: "relative", width, height }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isPDF ? (
        <>
          <iframe
            id="pdf-frame"
            src={src}
            width={width}
            height={height}
            style={{ border: "none" }}
            title="pdf-preview"
          />
          {isHovered && (
            <div
              className="pdf-actions"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 1,
              }}
            >
              <Button
                className="preview-button"
                icon={
                  <EyeOutlined
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  />
                }
                onClick={handlePreview}
                style={{ color: "white" }}
              />
            </div>
          )}
        </>
      ) : (
        <Image
          src={src}
          width={width}
          height={height}
          alt="preview"
          style={{ objectFit: "contain" }}
        />
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ImagePreview;
