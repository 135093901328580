export const customerFieldOrder = [
  "name",
  "contact_name",
  "location",
  "customer_code",
  "email",
  "gstin_number",
  "pan_number",
  "state",
  "phone",
  "address",
  "zip_code",
];

export const vendorFieldOrder = [
  "name",
  "state_code",
  "email",
  "state",
  "address",
  "vendor_code",
  "pan_number",
  "msme",
  "contact_name",
  "gstin_number",
  "bank_name",
  "phone",
  "zip_code",
  "account_number",
  "ifsc_code",
  "branch_name",
  "swift",
  "approved_amount",
  "tds_certificate_number",
];

export const commonVendorFieldOrder = [
  "name",
  "email",
  "state_code",
  "state",
  "vendor_code",
  "pan_number",
  "msme",
  "contact_name",
  "address",
  "gstin_number",
  "bank_name",
  "phone",
  "branch_name",
  "zip_code",
  "account_number",
  "ifsc_code",
  "swift",
  "approved_amount",
  "tds_certificate_number",
];
