import { GET_LEDGERLIST_FAIL, GET_LEDGERLIST_REQUSET, GET_LEDGERLIST_SUCCESS, LEDGERLIST_INVOICE_RESET, LEDGERLISTSET_PAGE, POST_LEDGERLIST_REQUSET, POST_LEDGERLIST_SUCCESS, POST_LEDGERLIST_FAIL, POST_LEDGERLIST_RESET } from "../constants/ledgerListModuleConstant";


const initialState = {
  data: [],
  loading: false,
  error: null,
  page: 1,
  perPage: 10,
};

export const ledgerModuleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEDGERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case GET_LEDGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        page: action.payload.page,
        perPage: action.payload.perPage,
      };
    case GET_LEDGERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case LEDGERLISTSET_PAGE:
      return { ...state, page: action.payload };
    case LEDGERLIST_INVOICE_RESET:
      return {}
    default:
      return state;
  }
};

export const postLedgerModuleListReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_LEDGERLIST_REQUSET:
      return { ...state, loading: true, error: null };
    case POST_LEDGERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success:true,
        data: action.payload.data,
      };
    case POST_LEDGERLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case POST_LEDGERLIST_RESET:
      return {}
    default:
      return state;
  }
};