import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, Route, Redirect } from "react-router-dom"; // Import useHistory

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component,defaultPermissions, moduleName, ...rest }) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, permissions } = userSignin;
  const history = useHistory(); // Use useHistory hook

  // Function to handle redirection if user is not signed in
  const redirectToSignIn = () => {
    history.push("/"); // Use history.push for redirection
  };

  const moduleNamePermission = useMemo(() => {
    return (
      (permissions[moduleName] && permissions[moduleName] === true) ||
      (defaultPermissions && defaultPermissions === true)
    );
  }, [moduleName,defaultPermissions]);

  return (
    <>
    <Route
      {...rest}
      render={
        (props) =>
          userInfo ? (
            moduleName ||  defaultPermissions? (
              moduleNamePermission ? (
                <Component {...props} />
              ) : (
                <Redirect to={"/index"} />
              )
            ) : null
          ) : (
            redirectToSignIn()
          ) // Call redirectToSignIn function here
      }
    />
    </>
  );
}

export default PrivateRoute;
