// import axios from "axios";
import {
  RECURRINGINVOICE_CREATE_REQUEST,
  RECURRINGINVOICE_CREATE_SUCCESS,
  RECURRINGINVOICE_CREATE_FAIL,
  RECURRINGINVOICE_LIST_REQUEST,
  RECURRINGINVOICE_LIST_SUCCESS,
  RECURRINGINVOICE_LIST_FAIL,
  RECURRINGINVOICE_EDIT_DETAIL_REQUEST,
  RECURRINGINVOICE_EDIT_DETAIL_SUCCESS,
  RECURRINGINVOICE_EDIT_DETAIL_FAIL,
  RECURRINGINVOICE_EDIT_REQUEST,
  RECURRINGINVOICE_EDIT_SUCCESS,
  RECURRINGINVOICE_EDIT_FAIL,
  RECURRINGINVOICE_GET_INVOICELIST_PAGE,
} from "../constants/RecurringInvoiceConstant";
// import { HTTP } from "../api";
import axios from "axios";

export const recurringInvoiceCreate = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_CREATE_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.post("https://api.tideiq.com/ar/recurring-invoices/create/", payload, {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    });
    dispatch({ type: RECURRINGINVOICE_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_CREATE_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};

export const setPage = (page, perPage) => ({
  type: RECURRINGINVOICE_GET_INVOICELIST_PAGE,
  payload: page,
  perPage,
});

export const getRecurringInvoiceList = (
  page = 1,
  perPage = 10,
  customer__name,
  invoice_number,
  start_date,
  end_date,
  interval,
  day,
  nature_of_supply
) => async (dispatch, getState) => {
  dispatch({ type: RECURRINGINVOICE_LIST_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `https://api.tideiq.com/ar/recurring-invoices/?page=${page}&per_page=${perPage}&customer__name=${customer__name}&invoice_number=${invoice_number}&start_date=${start_date}&end_date=${end_date}&interval=${interval}&day=${day}&nature_of_supply=${nature_of_supply}`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({
      type: RECURRINGINVOICE_LIST_SUCCESS,
      payload: { data, page, perPage },
    });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const recurringEditInvoiceDetail = (id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_EDIT_DETAIL_REQUEST });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.get(
      `https://api.tideiq.com/ar/recurring-invoices/${id}/`,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    dispatch({ type: RECURRINGINVOICE_EDIT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_EDIT_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateRecurringInvoice = (payload, id) => async (
  dispatch,
  getState
) => {
  dispatch({ type: RECURRINGINVOICE_EDIT_REQUEST, payload: payload });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await axios.patch(
      `https://api.tideiq.com/ar/recurring-invoices/${id}/update/`,payload,
      {
        headers: {
          Authorization: `Bearer ${userInfo}`,
        },
      }
    );
    // await HTTP.put(
    //   `/ar/recurring-invoices/${id}/`,
    //   payload,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${userInfo}`,
    //     },
    //   }
    // );
    dispatch({ type: RECURRINGINVOICE_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RECURRINGINVOICE_EDIT_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data
          : error.message,
    });
  }
};
